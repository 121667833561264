import { makeAutoObservable } from 'mobx'
import { IPayloadPost, IReqPost, IResDataPost } from './PostInterface'
import CONSTANCE from '../../services/Constance'
import { getListPostAPI, postPostAPI, putPostAPI } from './PostAPI'
import FUNCTION from '../../services/Functions'
import { Notification } from '../../commons/notification/Notification'
import history from '../../services/history'
import { ADMIN_ROUTER } from '../../router/AdminRouter'

class PostStore {
  loading = {
    getList: false,
    submit: false,
  }
  listPost: IResDataPost[] = []
  total: number = 0
  payload: IPayloadPost = {
    page: CONSTANCE.DEFAULT_PAGE,
    limit: CONSTANCE.LIMIT,
  }

  constructor() {
    makeAutoObservable(this)
  }

  async getListPost() {
    try {
      this.loading.getList = true
      const res = await getListPostAPI({ ...this.payload, search: this.payload.search?.trim() })
      if (res.body.status) {
        this.listPost = res.body.data
        this.total = res.body.paging.totalItemCount
      }
    } catch (e) {
      console.error(e)
    } finally {
      this.loading.getList = false
    }
  }

  isPostPending(status: number): boolean {
    if (FUNCTION.GetUserInfo().role === CONSTANCE.ACCOUNT.OFFICER_WARD) {
    }
    return false
  }

  async createPost(reqData: IReqPost) {
    try {
      const res = await postPostAPI(reqData)
      if (res.body.status) {
        Notification.SweetAlert('SUCCESS', 'Thêm mới thành công  tin đăng')
        setTimeout(() => {
          history.push(ADMIN_ROUTER.POST.path)
        }, 500)
      }
    } catch (e) {
      console.error(e)
    } finally {
    }
  }

  async updatePost(id: number, reqData: IReqPost) {
    try {
      const res = await putPostAPI(id, reqData)
      if (res.body.status) {
        Notification.PushNotification('SUCCESS', 'Cập nhật thành công  tin đăng')
        setTimeout(() => {
          history.push(ADMIN_ROUTER.POST_DETAIL.path + `?index=${id}`)
        }, 500)
        return res.body.status
      }
    } catch (e) {
      console.error(e)
    }
  }
}

const store = new PostStore()

export default store
