import { makeAutoObservable } from 'mobx'
import { Baservices, IApiResponse } from '../../services/Basevices'
import CONSTANCE from '../../services/Constance'
import { IResBody, IResPaging } from '../../services/Interfaces'
import { ADMIN_ROUTER } from '../../router/AdminRouter'

const NOTIFICATION_TYPE = {
  DONATE: 1, // ủng hộ
  POST: 2, // bài đăng
  NEWS_BANNER: 3, // tin tức
}

class NotificationHeaderStore {
  loading: boolean = false
  listNotification: IResDataNotificationHeader[] = []
  total: number = 0

  page: number = CONSTANCE.DEFAULT_PAGE

  count: number = 0

  constructor() {
    makeAutoObservable(this)
  }

  async getListNotification() {
    try {
      this.loading = true
      const res = await getListNotificationAPI(this.page)
      if (res.body.status) {
        this.listNotification = res.body.data
        this.total = res.body.paging.totalItemCount
      }
    } catch (e) {
      console.error(e)
    } finally {
      this.loading = false
    }
  }

  async readNotification(id: number, item: IResDataNotificationHeader) {
    try {
      this.loading = true
      const res = await readNotificationAPI(id)
      if (res.body.status) {
        this.getListNotification()
        this.getCountNotification()
        if (item.type === NOTIFICATION_TYPE.POST) {
          window.location.href = ADMIN_ROUTER.POST_DETAIL.path + `?index=${item.notification_id}`
        }
        if (item.type === NOTIFICATION_TYPE.DONATE) {
          window.location.href = ADMIN_ROUTER.REQUEST_SUPPORT.path
        }
        if (item.type === NOTIFICATION_TYPE.NEWS_BANNER) {
          window.location.href = ADMIN_ROUTER.NEWS_ADD_UPDATE.path + `?index=${item.notification_id}`
        }
      }
    } catch (e) {
      console.error(e)
    } finally {
      this.loading = false
    }
  }

  async getCountNotification() {
    try {
      const response = await getCountNotificationAPI()
      if (response.body.status) {
        this.count = response.body.data.notification_not_seen
      }
    } catch (e) {
      console.log(e)
    }
  }
}

const store = new NotificationHeaderStore()

export default store

// CALL API

export interface IResDataNotificationHeader {
  id: number
  user_id: number
  title: string
  NotificationPushes: any[]
  content: string
  type: number
  notification_id: number
  status: number
  create_at: string
}

export interface IResNotificationHeader extends IResBody {
  data: IResDataNotificationHeader[]
  paging: IResPaging
}

export interface IResCountNotification extends IResBody {
  data: {
    notification_not_seen: number
  }
}

const getListNotificationAPI = (page: number): Promise<IApiResponse<IResNotificationHeader>> => {
  return Baservices.getMethod(`/notification/list?page=${page}&limit=${CONSTANCE.LIMIT}`)
}

const readNotificationAPI = (id: number): Promise<IApiResponse<IResBody>> => {
  return Baservices.getMethod(`/notification/changs-status/${id}`)
}

const getCountNotificationAPI = (): Promise<IApiResponse<IResCountNotification>> => {
  return Baservices.getMethod(`/notification/not-seen`)
}
