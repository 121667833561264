import { makeAutoObservable } from 'mobx'
import { IPayloadAccount, IReqAccount, IReqAccountInfo, IResDataAccount } from './AccountInterface'
import CONSTANCE from '../../services/Constance'
import { deleteAccountAPI, getAccountInfo, getListAccountAPI, postAccountAPI, putAccountAPI } from './AccountAPI'
import { Notification } from '../../commons/notification/Notification'

class AccountStore {
  loading = {
    getList: false,
    submit: false,
  }
  payload: IPayloadAccount = {
    page: CONSTANCE.DEFAULT_PAGE,
    limit: CONSTANCE.LIMIT,
  }
  listAccount: IResDataAccount[] = []
  user: IReqAccountInfo = {
    name: '',
    role: 0,
    DFProvince: undefined,
    DFDistrict: undefined,
  }

  total: number = 0

  constructor() {
    makeAutoObservable(this)
  }

  async getListAccount() {
    try {
      this.loading.getList = true
      const res = await getListAccountAPI({ ...this.payload, search: this.payload.search?.trim() })
      if (res.body.status) {
        this.listAccount = res.body.data
        this.total = res.body.paging.totalItemCount
      }
    } catch (e) {
      console.error(e)
    } finally {
      this.loading.getList = false
    }
  }
  async getUserInfo() {
    try {
      const res: any = await getAccountInfo()
      this.user = res.body.data
      console.log('store.user1', res.body.data)
    } catch (error) {
      console.log(error)
    }
  }
  async createAccount(reqData: IReqAccount): Promise<boolean> {
    try {
      this.loading.submit = true
      const res = await postAccountAPI(reqData)
      if (res.body.status) {
        Notification.PushNotification('SUCCESS', 'Thêm mới tài khoản thành công')
        setTimeout(() => {
          this.getListAccount()
        }, 500)
        return new Promise((resolve) => resolve(true))
      } else {
        return new Promise((resolve) => resolve(false))
      }
    } catch (e) {
      console.error(e)
      return new Promise((resolve) => resolve(false))
    } finally {
      this.loading.submit = false
    }
  }

  async updateAccount(id: number, reqData: IReqAccount) {
    try {
      this.loading.submit = true
      const res = await putAccountAPI(id, reqData)
      if (res.body.status) {
        Notification.PushNotification('SUCCESS', 'Cập nhật tài khoản thành công')
        setTimeout(() => {
          this.getListAccount()
        }, 500)
        return new Promise((resolve) => resolve(true))
      } else {
        return new Promise((resolve) => resolve(false))
      }
    } catch (e) {
      console.error(e)
      return new Promise((resolve) => resolve(false))
    } finally {
      this.loading.submit = false
    }
  }

  async deleteAccount(id: number) {
    try {
      const res = await deleteAccountAPI(id)
      if (res.body.status) {
        Notification.PushNotification('SUCCESS', 'Xóa tài khoản thành công')
        setTimeout(() => {
          this.getListAccount()
        }, 500)
      }
    } catch (e) {
      console.error(e)
    }
  }
}

const store = new AccountStore()

export default store
