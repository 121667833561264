import React, { useEffect, useState } from 'react'
import { Divider, Form, Input, Modal } from 'antd'
import ModalHoc from '../../../commons/HOC/ModalHOC'
import LayoutForm from '../../../commons/layout-form/LayoutForm'
import { IReqPostRequestSupport } from '../RequestSupportInterfaces'
import SelectCategoryComponent from '../../select/SelectCategoryComponent'
import { useForm } from 'antd/es/form/Form'
import store from '../RequestSupportStore'
import { SelectPostApprove } from '../../select/SelectPost'
import ListDonatorStore from '../../post/page/ListDonatorStore'
import FUNCTION from '../../../services/Functions'
import CONSTANCE from '../../../services/Constance'

interface IProps {
  openModal?: (fn: Function) => any
  closeModal?: () => any
}

interface IForm {
  name: string
  form_support: number[]
  note: string
  content: string
  title: string
  receiver: string
  phone: string
  email: string
  donate_request_id: number
}

const AddRequestSupport: React.FC<IProps> = ({ openModal, closeModal }) => {
  const [visible, setVisible] = useState<boolean>(false)
  const [form] = useForm()
  const [isDisablePost, setIsDisablePost] = useState<boolean>(false)
  const onOpenModal = (idPost?: number, nameReceiver?: string) => {
    setVisible(true)
    if (idPost && nameReceiver) {
      form.setFieldsValue({
        donate_request_id: idPost,
        receiver: nameReceiver,
      })
      setIsDisablePost(true)
    }
  }

  const onCloseModal = () => {
    setVisible(false)
    closeModal && closeModal()
    form.resetFields()
  }

  useEffect(() => {
    openModal && openModal(onOpenModal)
  }, [])

  const onFinish = async (values: IForm) => {
    const reqData: IReqPostRequestSupport = {
      form_support: values.form_support,
      note: values.note,
      content: values.content,
      title: values.title,
      name: values.name,
      donate_request_id: values.donate_request_id,
      phone: values.phone,
      email: values.email,
    }

    const result = await store.postRequestSupport(reqData)

    if (result) {
      store.payload.page = 1
      store.getListRequestSupport()
      ListDonatorStore.getListDonator(FUNCTION.GetId())
      onCloseModal()
    }
  }

  return (
    <ModalHoc>
      <Modal
        title={'Thêm yêu cầu từ thiện'}
        visible={visible}
        width={800}
        onCancel={onCloseModal}
        onOk={(e) => form.submit()}
        confirmLoading={store.loading.submit}
      >
        <Form layout={'vertical'} form={form} onFinish={onFinish}>
          <LayoutForm labelGroup={'Thông tin người ủng hộ'}>
            <Form.Item
              label={'Họ tên người ủng hộ'}
              name={'name'}
              rules={[{ required: true, whitespace: true, message: 'Vui lòng nhập họ tên người ủng hộ' }]}
            >
              <Input placeholder={'Nhập tên người ủng hộ ủng hộ'} />
            </Form.Item>

            <Form.Item
              label={'Số điện thoại người ủng hộ'}
              name={'phone'}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Vui lòng nhập số điện thoại người ủng hộ',
                },
                { pattern: CONSTANCE.REG.PHONE, message: 'Số điện thoại không đúng định dạng.' },
              ]}
            >
              <Input placeholder={'Nhập số điện thoại người ủng hộ'} />
            </Form.Item>

            <Form.Item
              label={'Email'}
              name={'email'}
              rules={[
                { required: false, whitespace: true, message: 'Vui lòng nhập email' },
                {
                  type: 'email',
                  message: 'Email không đúng định dạng',
                },
              ]}
            >
              <Input placeholder={'Nhập Email người ủng hộ'} />
            </Form.Item>

            <SelectPostApprove
              onChange={(value, data) => {
                form.setFieldsValue({ receiver: data.name })
              }}
              placeholder={'Vui lòng chọn bài đăng'}
              name={'donate_request_id'}
              label={'Bài đăng'}
              disable={isDisablePost}
              rules={[{ required: true, message: 'Vui lòng chọn bài đăng' }]}
            />

            <Form.Item
              label={'Họ tên người nhận hỗ trợ'}
              name={'receiver'}
              rules={[{ required: true, message: 'Vui lòng nhập họ tên người nhận hỗ trợ' }]}
            >
              <Input placeholder={'Nhập họ tên người nhận hỗ trợ'} disabled={true} />
            </Form.Item>

            <Form.Item
              label={'Lời nhắn'}
              name={'note'}
              rules={[{ required: true, whitespace: true, message: 'Vui lòng nhập lời nhắn' }]}
            >
              <Input placeholder={'Nhập lời nhắn'} />
            </Form.Item>

            <Form.Item label={'Tiêu đề'} name={'title'} rules={[{ required: true, message: 'Vui lòng nhập tiêu đề' }]}>
              {/* <Input.TextArea rows={5} placeholder={'Nhập tiêu đề'} /> */}
              <Input placeholder={'Nhập tiêu đề'} />
            </Form.Item>
          </LayoutForm>

          <Divider />

          <LayoutForm labelGroup={'Thông tin ủng hộ'}>
            <SelectCategoryComponent
              placeholder={'Chọn nhu cầu hỗ trợ'}
              type={'SUPPORT_NEEDS'}
              label={'Hình thức ủng hộ'}
              mode={'multiple'}
              name={'form_support'}
              rules={[{ required: true, message: 'Vui lòng chọn hình thức ủng hộ' }]}
            />
          </LayoutForm>
        </Form>
      </Modal>
    </ModalHoc>
  )
}

export default AddRequestSupport
