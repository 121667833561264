import React, { useEffect, useRef, useState } from 'react'
import { Button, Checkbox, Divider, Form, Input, PageHeader, Spin } from 'antd'
import SelectAppComponent from '../../select/SelectAppComponent'
import CONSTANCE from '../../../services/Constance'
import UploadComponent, { IFile } from '../../../commons/upload-file/UploadComponent'
import { useForm } from 'antd/es/form/Form'
import { IReqNew } from '../NewsInterfaces'
import NewStore from '../NewStore'
import history from '../../../services/history'
import FUNCTION from '../../../services/Functions'
import { observer } from 'mobx-react'

interface IForm extends Omit<IReqNew, 'media_url' | 'push_notify'> {
  media_url: IFile[]
  push_notify: boolean
}

const AddUpdateNews = observer(() => {
  const [form] = useForm()
  const id: number = FUNCTION.GetId()
  const [content, setContent] = useState<string>()
  const [isDisableCheckBox, setIsDisableCheckBox] = useState<boolean>(id ? false : true)
  const loading: any = useRef(false)

  const onFinish = (values: IForm) => {
    const reqData: IReqNew = {
      ...values,
      push_notify: values.post_status ? 1 : 0,
      media_url: values.media_url[0].response?.data.filename as string,
    }
    if (id) {
      NewStore.putNews(id, reqData)
    } else {
      if (loading.current === false) {
        loading.current = true
        NewStore.postNews(reqData)
      }
    }
  }

  useEffect(() => {
    const getDetail = async () => {
      await NewStore.getDetailNews(id)
      if (NewStore.detailNew) {
        const dataForm: IForm = {
          type: NewStore.detailNew.type,
          push_notify: NewStore.detailNew.push_notify ? true : false,
          post_status: NewStore.detailNew.post_status,
          title: NewStore.detailNew.title,
          content: NewStore.detailNew.content,
          media_url: [
            {
              url: NewStore.detailNew.media_url,
              name: NewStore.detailNew.media_url_path,
              status: 'done',
              uid: NewStore.detailNew.media_url_path,
              response: {
                data: {
                  url: NewStore.detailNew.media_url,
                  filename: NewStore.detailNew.media_url_path,
                },
              },
            },
          ],
        }
        setContent(NewStore.detailNew.content)
        if (NewStore.detailNew.push_notify) {
          setIsDisableCheckBox(true)
        }
        form.setFieldsValue(dataForm)
      }
    }
    if (id) {
      getDetail()
    }
  }, [])

  return (
    <Spin spinning={NewStore.loading.getDetail}>
      <PageHeader title={'Thêm tin tức'} />

      <div className={'box'}>
        <div className={'container md-auto px-20 flex justify-center'}>
          <Form layout={'vertical'} form={form} className={'form-size'} onFinish={onFinish}>
            <div className={'form-size'}>
              <SelectAppComponent
                data={CONSTANCE.NEWS.TYPE_BANNER}
                name={'type'}
                label={'Danh mục'}
                placeholder={'Chọn danh mục'}
                rules={[{ required: true, message: 'Vui lòng chọn danh mục' }]}
              />

              <div className={'mt-3'} />

              <SelectAppComponent
                data={CONSTANCE.NEWS.STATUS_BANNER}
                name={'post_status'}
                label={'Trạng thái'}
                placeholder={'Chọn trạng thái'}
                rules={[{ required: true, message: 'Vui lòng chọn trạng thái' }]}
                onChange={(data) => {
                  if ((data as number) === CONSTANCE.NEWS.STATUS_BANNER.DRAFT.value) {
                    setIsDisableCheckBox(true)
                  } else {
                    setIsDisableCheckBox(false)
                  }
                }}
              />

              <div className={'mt-3'} />

              <Form.Item name={'push_notify'} valuePropName={'checked'}>
                <Checkbox disabled={isDisableCheckBox}>
                  <span className={'text-rose-700'}>Gửi thông báo tới người dùng</span>
                </Checkbox>
              </Form.Item>
            </div>

            <Divider />

            <Form.Item
              label={'Tiêu đề bài viết'}
              name={'title'}
              rules={[{ required: true, whitespace: true, message: 'Vui lòng nhập tiêu đề bài viết' }]}
            >
              <Input placeholder={'Nhập tiêu đề bài viết'} />
            </Form.Item>

            <div className={'mt-3'} />

            <UploadComponent
              label={'Chọn ảnh nổi bật'}
              name={'media_url'}
              limit={1}
              defaultData={
                id
                  ? [
                      {
                        url: NewStore.detailNew?.media_url,
                        name: NewStore.detailNew?.media_url_path as string,
                        status: 'done',
                        uid: NewStore.detailNew?.media_url_path,
                        response: {
                          data: {
                            url: NewStore.detailNew?.media_url as string,
                            filename: NewStore.detailNew?.media_url_path as string,
                          },
                        },
                      },
                    ]
                  : undefined
              }
              rules={[{ required: true, message: 'Vui lòng chọn ảnh' }]}
            />

            <div className={'mt-3'} />

            {/*<SunEditorComponent*/}
            {/*  form={form}*/}
            {/*  label={'Nội dung'}*/}
            {/*  name={'content'}*/}
            {/*  content={content}*/}
            {/*  rules={[{ required: true, message: 'Vui lòng nhập nội dung' }]}*/}
            {/*/>*/}

            <Form.Item
              name={'content'}
              label={'Nội dung'}
              rules={[{ required: true, message: 'Vui lòng nhập nội dung' }]}
            >
              <Input.TextArea rows={8} placeholder={'Nội dung'} />
            </Form.Item>

            <div className={'flex justify-end my-5'}>
              <Button type={'primary'} className={'mr-3'} danger onClick={(event) => history.goBack()}>
                Hủy
              </Button>
              <Button type={'primary'} htmlType={'submit'} loading={NewStore.loading.submitForm}>
                Lưu
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </Spin>
  )
})

export default AddUpdateNews
