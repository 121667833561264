import React, { useEffect, useRef, useState } from 'react'
import { Affix, Button, Card, Col, DatePicker, Divider, Form, Input, PageHeader, Row, Space, Spin } from 'antd'
import { SelectGender } from '../../select/SelectGender'
import SelectGroupComponent from '../../select/SelectGroupComponent'
import {
  SelectBankComponent,
  SelectDistrictComponent,
  SelectProvincesComponent,
  SelectWardComponent,
} from '../../select/select-address/SelectAddressComponent'
import { FormLabelLeftStyle } from '../../../styles/FormStyles'
import ICON from '../../../commons/icon/Icon'
import CONSTANCE from '../../../services/Constance'
import UploadComponent, { IFile } from '../../../commons/upload-file/UploadComponent'
import moment from 'moment'
import { useForm } from 'antd/es/form/Form'
import history from '../../../services/history'
import FUNCTION from '../../../services/Functions'
import store from './PostDetailStore'
import { Moment as MomentService } from '../../../services/Moment'
import { IReqPost } from '../PostInterface'
import postStore from '../PostStore'
import SelectCategoryComponent from '../../select/SelectCategoryComponent'
import RenderVideoComponent from '../../../commons/render-video/RenderVideoComponent'
import { observer } from 'mobx-react'

interface IForm {
  name: string
  phone: string
  gender: number
  year_of_birth: moment.Moment
  group_id: number
  charity: number[]
  support_needs: number[]
  province_id: number
  district_id: number
  ward_id: number
  address: string
  image: IFile[]
  video: IFile[]
  title: string
  content: string
  bank_id: number | undefined
  branch_name: string
  account_name: string
  account_number: string
  end_date?: moment.Moment
}

const AddUpdatePost = observer(() => {
  const [provinceId, setProvincesId] = useState<number | undefined>()
  const [districtId, setDistrictId] = useState<number | undefined>(undefined)
  const [groupId, setGroupId] = useState<number | undefined>(undefined)
  // const [disable, SetDisable] = useState<boolean>(false)
  const id = FUNCTION.GetId()
  const [urlVideo, setUrlVideo] = useState<string>()

  const [isAddBank, setIsAddBank] = useState<boolean>(false)
  const setFileVideoRef = useRef<Function>()
  const [loading, setLoading] = useState(false)

  const [form] = useForm()

  const onFinish = (values: IForm | any) => {
    const charity = values.charity.map((value: any) => {
      return { category_id: value, type: CONSTANCE.CATEGORY.CHARITY }
    })

    const supportNeeds = values.support_needs.map((value: any) => {
      return { category_id: value, type: CONSTANCE.CATEGORY.SUPPORT_NEEDS }
    })

    const reqCategory = [...supportNeeds, ...charity]

    const image = values.image.map((value: any) => {
      if (value.response) {
        return {
          media_url: value.response.data.filename,
          type: CONSTANCE.MEDIA.IMAGES,
        }
      } else {
        return {
          media_url: value.name,
          type: CONSTANCE.MEDIA.IMAGES,
        }
      }
    })

    const video = values.video
      ? values.video.map((value: any) => {
          if (value.response) {
            return {
              media_url: value.response.data.filename,
              type: CONSTANCE.MEDIA.VIDEO,
            }
          } else {
            return {
              media_url: value.name,
              type: CONSTANCE.MEDIA.VIDEO,
            }
          }
        })
      : []

    const reqData: IReqPost = {
      title: values.title,
      content: values.content,
      address: values.address,
      name: values.name,
      phone: values.phone,
      gender: values.gender,
      year_of_birth: moment(values.year_of_birth).format('YYYY'),
      province_id: values.province_id,
      district_id: values.district_id,
      ward_id: values.ward_id,
      address_google: '',
      lat: 0,
      long: 0,
      group_id: values.group_id,
      category: reqCategory as any,
      array_image: [...image, ...video],
      bank_id: values.bank_id,
      branch_name: values.branch_name,
      account_name: values.account_name,
      account_number: String(values.account_number),
      type: 1,
      end_date: values.end_date,
    }
    console.log('reqData', reqData)

    if (id) {
      if (!loading) {
        setLoading(false)
        postStore.updatePost(FUNCTION.GetId(), reqData)
        form.resetFields()
        setLoading(false)
      }
      if (store.detailPost?.is_update !== CONSTANCE.POST.REQUEST_EDIT.NOT_REQUEST) {
        // SetDisable(true)
        store.putChangeStatus({
          status: CONSTANCE.POST.POST_STATUS.DISTRICT_CONFIRM,
          post_ids: [FUNCTION.GetId()],
          reason: '',
        })
      }
    } else {
      if (!loading) {
        setLoading(false)
        postStore.createPost(reqData)
        form.resetFields()
        setLoading(false)
      }
    }
  }

  const getDetail = async () => {
    if (FUNCTION.GetId()) {
      await store.getDetailPost(FUNCTION.GetId())
      if (store.detailPost) {
        const dataForm: IForm = {
          name: store.detailPost.name,
          phone: store.detailPost.phone,
          gender: store.detailPost.gender,
          year_of_birth: moment(store.detailPost.year_of_birth, 'YYYY'),
          group_id: store.detailPost.group_id,
          charity: store.getCategory(store.detailPost.DonateCategoryDetails).charity,
          support_needs: store.getCategory(store.detailPost.DonateCategoryDetails).supportNeeds,
          province_id: store.detailPost.DFProvince.id,
          district_id: store.detailPost.DFDistrict.id,
          ward_id: store.detailPost.DFWard.id,
          address: store.detailPost.address,
          image: store.getFile(store.detailPost.DonateRequestMedia).image,
          video: store.getFile(store.detailPost.DonateRequestMedia).video,
          title: store.detailPost.title,
          content: store.detailPost.content,
          bank_id:
            (store.detailPost.BankInfos &&
              store.detailPost.BankInfos.length > 0 &&
              store.detailPost.BankInfos[0].bank_id) ||
            undefined,
          branch_name:
            (store.detailPost.BankInfos &&
              store.detailPost.BankInfos.length > 0 &&
              store.detailPost.BankInfos[0].branch_name) ||
            '',
          account_name:
            (store.detailPost.BankInfos &&
              store.detailPost.BankInfos.length > 0 &&
              store.detailPost.BankInfos[0].account_name) ||
            '',
          account_number:
            (store.detailPost.BankInfos &&
              store.detailPost.BankInfos.length > 0 &&
              store.detailPost.BankInfos[0].account_number) ||
            '',
          end_date: store.detailPost.end_date ? moment(store.detailPost.end_date) : moment(Date.now()),
        }

        setProvincesId(store.detailPost.DFProvince.id)
        setDistrictId(store.detailPost.DFDistrict.id)
        if (store.getFile(store.detailPost.DonateRequestMedia).video.length > 0) {
          setUrlVideo(store.getFile(store.detailPost.DonateRequestMedia).video[0].url)
        }
        setGroupId(store.detailPost.group_id)
        if (store.detailPost.BankInfos.length > 0) {
          setIsAddBank(true)
        }
        form.setFieldsValue(dataForm)
      }
    }
  }

  useEffect(() => {
    getDetail()
    // console.log('defaultCreateForm')

    if (!FUNCTION.GetId()) {
      const defaultCreateForm = {
        province_id: 10,
      }
      form.setFieldsValue(defaultCreateForm)
      setProvincesId(10)
    }
  }, [])

  return (
    <Spin spinning={store.loading.getDetail}>
      <Affix offsetTop={CONSTANCE.OFFSET_TOP}>
        <PageHeader
          style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }}
          title={FUNCTION.GetId() ? 'Sửa tin đăng' : 'Thêm tin đăng'}
          extra={
            <Space>
              <Button
                type={'primary'}
                danger
                icon={<span className={'mr-1'}>{ICON.BUTTON.CANCEL}</span>}
                onClick={(event) => history.goBack()}
              >
                Hủy
              </Button>
              <Button
                onClick={(event) => form.submit()}
                type={'primary'}
                icon={<span className={'mr-1'}>{ICON.BUTTON.ACCEPT}</span>}
              >
                Lưu
              </Button>
            </Space>
          }
        />
      </Affix>

      <div className={'mx-2.5 my-2.5 bg-white py-5'}>
        <FormLabelLeftStyle
          form={form}
          onFinish={onFinish}
          scrollToFirstError={CONSTANCE.SCROLL_FORM as any}
          layout={'vertical'}
        >
          <Row justify={'center'}>
            <Col lg={16} md={20} sm={20}>
              <Card
                title={
                  <Row className={'ml-4'}>
                    <Col lg={4} md={4}></Col>
                    <Col lg={16} md={16}>
                      Thông tin người nhận từ thiện
                    </Col>
                  </Row>
                }
                bordered={false}
              >
                <Row justify={'center'}>
                  <Col lg={16} md={16}>
                    <Form.Item
                      label={'Tên người nhận'}
                      name={'name'}
                      // hasFeedback={true}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: 'Vui lòng nhập tên người nhận',
                        },
                        { type: 'string', max: 50, message: 'Tên người nhận không quá 255 ký tự' },
                      ]}
                    >
                      <Input placeholder={'Nhập tên người nhận'} allowClear={true} />
                    </Form.Item>
                    <Form.Item
                      label={'Số điện thoại'}
                      name={'phone'}
                      rules={[{ required: true, whitespace: true, message: 'Vui lòng nhập số điện thoại' }]}
                    >
                      <Input placeholder={'Nhập số điện thoại'} />
                    </Form.Item>
                    <SelectGender name={'gender'} label={'Giới tính'} placeholder={'Chọn giới tính'} />

                    <Form.Item label={'Năm sinh'} name={'year_of_birth'}>
                      <DatePicker
                        placeholder={'Chọn năm sinh'}
                        className={'w-full'}
                        picker={'year'}
                        disabledDate={MomentService.disableDate}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Divider />

                <Row justify={'center'}>
                  <Col lg={16} md={16}>
                    <SelectGroupComponent
                      label={'Phân nhóm'}
                      placeholder={'Nhập phân nhóm'}
                      name={'group_id'}
                      rules={[{ required: true, message: 'Vui lòng chọn phân nhóm' }]}
                      // onChange={(values) => {
                      //   setGroupId(values as number)
                      //   form.setFieldsValue({
                      //     charity: undefined,
                      //     support_needs: undefined,
                      //   })
                      // }}
                    />
                    <SelectCategoryComponent
                      label={'Đối tượng'}
                      type={'CHARITY'}
                      placeholder={'Chọn đối tượng'}
                      name={'charity'}
                      rules={[{ required: true, message: 'Vui lòng chọn đối tượng' }]}
                      // groupId={groupId}
                      // disabled={groupId ? false : true}
                      mode={'multiple'}
                    />
                    <SelectCategoryComponent
                      label={'Nhu cầu'}
                      type={'SUPPORT_NEEDS'}
                      placeholder={'Chọn nhu cầu'}
                      name={'support_needs'}
                      rules={[{ required: true, message: 'Vui lòng chọn nhu cầu' }]}
                      // groupId={groupId}
                      // disabled={groupId ? false : true}
                      mode={'multiple'}
                    />
                  </Col>
                </Row>
                <Divider />

                <Row justify={'center'}>
                  <Col lg={16} md={16}>
                    <SelectProvincesComponent
                      label={'Tỉnh/ Thành phố'}
                      placeholder={'Chọn Tỉnh/ Thành phố'}
                      name={'province_id'}
                      rules={[{ required: true, message: 'Vui lòng chọn tỉnh/ thành phố' }]}
                      onChange={(values) => {
                        console.log('values', values)
                        setProvincesId(values)
                        form.setFieldsValue({ district_id: undefined, ward_id: undefined })
                      }}
                      // disabled={true}
                    />
                    <SelectDistrictComponent
                      provinceId={provinceId}
                      label={'Huyện/ Quận'}
                      placeholder={'Chọn Huyện/ Quận'}
                      name={'district_id'}
                      rules={[{ required: true, message: 'Vui lòng chọn huyện/ quận' }]}
                      onChange={(values) => {
                        setDistrictId(values)
                        form.setFieldsValue({ ward_id: undefined })
                      }}
                      // disabled={provinceId ? false : true}
                    />
                    <SelectWardComponent
                      districtId={districtId}
                      label={'Xã/ Phường'}
                      placeholder={'Chọn Xã/ Phường'}
                      name={'ward_id'}
                      rules={[{ required: true, message: 'Vui lòng chọn xã/ phường' }]}
                      // disabled={provinceId && districtId ? false : true}
                    />
                    <Form.Item
                      label={'Địa chỉ chi tiết'}
                      name={'address'}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: 'Vui lòng nhập địa chỉ chi tiết',
                        },
                        { type: 'string', max: 555, message: 'Địa chỉ chi tiết không quá 555 ký tự' },
                      ]}
                    >
                      <Input placeholder={'Nhập địa chỉ chi tiết'} allowClear={true} />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider />

                <Row justify={'center'}>
                  <Col lg={16} md={16}>
                    <UploadComponent
                      label={'Ảnh'}
                      name={'image'}
                      limit={10}
                      rules={[{ required: true, message: 'Vui lòng chọn ảnh' }]}
                    />

                    {/*{!urlVideo && (*/}
                    {/*  <UploadComponent*/}
                    {/*    style={{ display: urlVideo ? 'none' : 'block' }}*/}
                    {/*    label={'Video'}*/}
                    {/*    name={'video'}*/}
                    {/*    limit={1}*/}
                    {/*    accept={'.mp4'}*/}
                    {/*    path={CONSTANCE.PATH_UPLOAD.VIDEO}*/}
                    {/*    nameUpload={CONSTANCE.NAME_UPLOAD.VIDEO}*/}
                    {/*    placeholder={'Tải video'}*/}
                    {/*    logger={(data) => {*/}
                    {/*      if (data.length > 0 && data[0].response) {*/}
                    {/*        setUrlVideo(data[0].response.data.url)*/}
                    {/*        form.setFieldsValue({ video: data })*/}
                    {/*        console.log(form.getFieldsValue(), data)*/}
                    {/*      } else {*/}
                    {/*        setUrlVideo(undefined)*/}
                    {/*      }*/}
                    {/*    }}*/}
                    {/*  />*/}
                    {/*)}*/}

                    <UploadComponent
                      style={{ display: urlVideo ? 'none' : 'block' }}
                      label={'Video'}
                      name={'video'}
                      limit={1}
                      accept={'.mp4'}
                      path={CONSTANCE.PATH_UPLOAD.VIDEO}
                      nameUpload={CONSTANCE.NAME_UPLOAD.VIDEO}
                      placeholder={'Tải video'}
                      setFileListProps={(fn) => (setFileVideoRef.current = fn)}
                      logger={(data) => {
                        if (data.length > 0 && data[0].response) {
                          setUrlVideo(data[0].response.data.url)
                        } else {
                          setUrlVideo(undefined)
                        }
                      }}
                    />

                    {urlVideo && (
                      <RenderVideoComponent
                        src={urlVideo}
                        onClose={() => {
                          setUrlVideo(undefined)
                          form.setFieldsValue({ video: undefined })
                          setFileVideoRef.current && setFileVideoRef.current([])
                        }}
                      />
                    )}

                    <Form.Item
                      label={'Tiêu đề'}
                      name={'title'}
                      rules={[
                        { required: true, whitespace: true, message: 'Vui lòng nhập tiêu đề' },
                        {
                          type: 'string',
                          max: 555,
                          message: 'Tiêu đề không quá 555 ký tự',
                        },
                      ]}
                    >
                      <Input placeholder={'Nhập tiêu đề'} allowClear />
                    </Form.Item>
                    <Form.Item
                      label={'Mô tả'}
                      name={'content'}
                      rules={[{ required: true, whitespace: true, message: 'Vui lòng nhập mô tả' }]}
                    >
                      <Input.TextArea rows={5} placeholder={'Nhập mô tả'} />
                    </Form.Item>

                    {((FUNCTION.GetUserInfo().role === CONSTANCE.ACCOUNT.ADMIN ||
                      FUNCTION.GetUserInfo().role === CONSTANCE.ACCOUNT.OFFICER_PROVINCE) &&
                      !id) ||
                    ((FUNCTION.GetUserInfo().role === CONSTANCE.ACCOUNT.ADMIN ||
                      FUNCTION.GetUserInfo().role === CONSTANCE.ACCOUNT.OFFICER_PROVINCE) &&
                      id &&
                      (store.detailPost?.status === CONSTANCE.POST.POST_STATUS.DISTRICT_CONFIRM ||
                        store.detailPost?.status === CONSTANCE.POST.POST_STATUS.DONE)) ? (
                      <Form.Item
                        label={'Ngày hết hạn tin đăng'}
                        name={'end_date'}
                        rules={[{ required: true, message: 'Vui lòng chọn ngày hết hạn tin đăng' }]}
                      >
                        <DatePicker
                          // defaultValue={}
                          style={{ width: '100%' }}
                          // disabled={FUNCTION.GetUserInfo().role === CONSTANCE.ACCOUNT.OFFICER_DISTRICT ? true : false}
                          disabledDate={(current: any) => current && current < moment().startOf('day')}
                        />
                      </Form.Item>
                    ) : null}
                  </Col>
                </Row>
              </Card>

              <Card
                title={
                  <Row className={'px-10'} justify={'space-around'}>
                    <Col>Thông tin chuyển khoản</Col>
                    <Col>
                      {isAddBank ? (
                        <Button
                          danger
                          type={'primary'}
                          onClick={(event) => setIsAddBank(false)}
                          icon={
                            <span className={'mr-2'}>
                              <i className="fas fa-horizontal-rule" />
                            </span>
                          }
                        >
                          Xóa thông tin chuyển khoản
                        </Button>
                      ) : (
                        <Button
                          type={'primary'}
                          onClick={(event) => setIsAddBank(true)}
                          icon={<span className={'mr-2'}>{ICON.BUTTON.ADD}</span>}
                        >
                          Thêm thông tin chuyển khoản
                        </Button>
                      )}
                    </Col>
                  </Row>
                }
                bordered={false}
              >
                {isAddBank && (
                  <Row justify={'center'}>
                    <Col lg={16} md={16}>
                      <SelectBankComponent
                        label={'Ngân hàng'}
                        placeholder={'Chọn ngân hàng'}
                        name={'bank_id'}
                        rules={[{ required: true, message: 'Vui lòng chọn ngân hàng' }]}
                      />
                      <Form.Item
                        label={'Chi nhánh'}
                        name={'branch_name'}
                        rules={[{ required: true, whitespace: true, message: 'Vui lòng nhập chi nhánh' }]}
                      >
                        <Input placeholder={'Nhập chi nhánh'} allowClear={true} />
                      </Form.Item>
                      <Form.Item
                        label={'Tên chủ tài khoản'}
                        name={'account_name'}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: 'Vui lòng nhập tên tài khoản',
                          },
                          { type: 'string', max: 255, message: 'Tên tài khoản không quá 255 ký tự' },
                        ]}
                      >
                        <Input placeholder={'Nhập tên tài khoản'} allowClear={true} />
                      </Form.Item>
                      <Form.Item
                        label={'Số tài khoản'}
                        name={'account_number'}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: 'Vui lòng nhập số tài khoản',
                          },
                          { type: 'string', max: 30, message: 'Số tài khoản không quá 30 ký tự' },
                        ]}
                      >
                        <Input placeholder={'Nhập số tài khoản'} allowClear={true} />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
              </Card>
            </Col>
          </Row>
        </FormLabelLeftStyle>
      </div>
    </Spin>
  )
})

export default AddUpdatePost
