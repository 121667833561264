import React, { useEffect } from 'react'
import { Affix, Col, DatePicker, Input, PageHeader, Popconfirm, Row, Space, Table } from 'antd'
import RangePickerHoc from '../../commons/HOC/RangePickerHOC'
import TableHoc from '../../commons/HOC/TableHOC'
import { IColumn } from '../../services/Interfaces'
import { Moment } from '../../services/Moment'
import ICON from '../../commons/icon/Icon'
import { observer } from 'mobx-react'
import store from './CustomerStore'
import CONSTANCE from '../../services/Constance'
import FUNCTION from '../../services/Functions'
import history from '../../services/history'
import { ADMIN_ROUTER } from '../../router/AdminRouter'
import { RenderSwitch } from '../select/SelectStatusComponent'
import PopconfirmHoc from '../../commons/HOC/PopconfirmHOC'

const columns: IColumn[] = [
  {
    title: 'STT',
    key: 'STT',
    dataIndex: 'STT',
    width: 80,
    align: 'center',
    render: (STT: number, row, index) => <div>{STT}</div>,
  },
  {
    title: 'Họ tên',
    key: 'name',
    dataIndex: 'name',
    render: (name: string, row, index) => (
      <div
        className={'cursor-pointer hover:text-blue-700'}
        onClick={() => history.push(ADMIN_ROUTER.CUSTOMER_DETAIL.path + `?index=${row.id}`)}
      >
        {name}
      </div>
    ),
  },
  {
    title: 'Điện thoại',
    key: 'phone',
    dataIndex: 'phone',
    render: (phone: string, row, index) => <div>{phone}</div>,
  },
  {
    title: 'Địa chỉ',
    key: 'address',
    dataIndex: 'address',
    render: (address: string, row, index) => <div>{address || '___'}</div>,
  },
  {
    title: 'Email',
    key: 'email',
    dataIndex: 'email',
    render: (email: string, row, index) => <div>{email}</div>,
  },
  {
    title: 'Ngày tham gia',
    key: 'create_at',
    dataIndex: 'create_at',
    render: (create_at: string, row, index) => <div>{Moment.getDate(create_at)}</div>,
  },
  {
    title: '',
    key: 'id',
    dataIndex: 'id',
    align: 'center',
    width: 80,
    render: (id: number, row, index) => (
      <div>
        <Space>
          {FUNCTION.GetUserInfo().role === CONSTANCE.ACCOUNT.ADMIN && (
            <PopconfirmHoc>
              <Popconfirm
                title={'Bạn có chắc muốn thay đổi trạng thái hoạt động khách hàng này?'}
                onConfirm={() => store.changeStatusActive(row.id)}
              >
                {RenderSwitch(row.is_active)}
              </Popconfirm>
            </PopconfirmHoc>
          )}

          <div
            className={'cursor-pointer icon-detail'}
            onClick={() => history.push(ADMIN_ROUTER.CUSTOMER_DETAIL.path + `?index=${id}`)}
          >
            {ICON.DETAIL}
          </div>
        </Space>
      </div>
    ),
  },
]

const Customer = observer(() => {
  useEffect(() => {
    store.loading.getList = true
    const idTimeout = setTimeout(() => {
      store.getListCustomer()
    }, 500)
    return () => clearTimeout(idTimeout)
  }, [
    store.payload.page,
    store.payload.search,
    store.payload.status,
    store.payload.startDate,
    store.payload.endDate,
    store.payload.province_id,
    store.payload.address,
  ])

  useEffect(() => {
    return () => {
      store.payload = { page: CONSTANCE.DEFAULT_PAGE, limit: CONSTANCE.LIMIT }
    }
  }, [])

  return (
    <div>
      <PageHeader title={'Quản lý người dùng'} />

      <Affix offsetTop={CONSTANCE.OFFSET_TOP}>
        <div className={'box'}>
          <Row gutter={[16, 4]}>
            <Col md={6} lg={6} sm={24}>
              <Input
                placeholder={'Nhập tên hoặc số điện thoại'}
                allowClear={true}
                onChange={(event) => {
                  store.payload.search = event.target.value
                  store.payload.page = CONSTANCE.DEFAULT_PAGE
                }}
              />
            </Col>
            <Col md={6} lg={6} sm={24}>
              <Input
                placeholder={'Nhập địa chỉ'}
                allowClear={true}
                onChange={(event) => {
                  store.payload.address = event.target.value
                  store.payload.page = CONSTANCE.DEFAULT_PAGE
                }}
              />
            </Col>
            <Col md={6} lg={6} sm={24}>
              <RangePickerHoc
                onChange={(stringDate) => {
                  store.payload.startDate = stringDate[0]
                  store.payload.endDate = stringDate[1]
                  store.payload.page = CONSTANCE.DEFAULT_PAGE
                }}
              >
                <DatePicker.RangePicker />
              </RangePickerHoc>
            </Col>
            <Col md={6} lg={6} sm={24}>
              <div className={'flex justify-end mt-1.5'}>Kết quả lọc: {store.total}</div>
            </Col>
          </Row>
        </div>
      </Affix>

      <div className={'box'}>
        <TableHoc>
          <Table
            columns={columns}
            loading={store.loading.getList}
            dataSource={store.listCustomer.map((value, index) => {
              return { ...value, STT: FUNCTION.GetIndexTable(store.payload.page, index) }
            })}
            pagination={{
              onChange: (page) => (store.payload.page = page),
              total: store.total,
              current: store.payload.page,
            }}
          />
        </TableHoc>
      </div>
    </div>
  )
})

export default React.memo(Customer)
