import React, { useEffect } from 'react'
import SelectStatusComponent, { RenderStatus } from '../../select/SelectStatusComponent'
import TableHoc from '../../../commons/HOC/TableHOC'
import { DatePicker, Select, Table, Tag } from 'antd'
import { IColumn } from '../../../services/Interfaces'
import { observer } from 'mobx-react'
import { Moment } from '../../../services/Moment'
import ListPostStore from './ListPostStore'
import FUNCTION from '../../../services/Functions'
import RangePickerHoc from '../../../commons/HOC/RangePickerHOC'
import CONSTANCE from '../../../services/Constance'
import { RenderPostStatus, SelectPostStatus } from '../../select/SelectPost'

const columns: IColumn[] = [
  {
    title: 'STT',
    key: 'STT',
    dataIndex: 'STT',
    align: 'center',
    render: (STT: number, row, index) => <div>{STT}</div>,
  },
  {
    title: 'Tiêu đề',
    key: 'title',
    dataIndex: 'title',
    render: (title: string, row, index) => <div>{title}</div>,
  },
  {
    title: 'Người đăng',
    key: 'name',
    dataIndex: 'name',
    render: (name: string, row, index) => <div>{name}</div>,
  },
  {
    title: 'Địa chỉ',
    key: 'address',
    dataIndex: 'address',
    render: (address: string, row, index) => <div>{address}</div>,
  },
  {
    title: 'Số người ủng hộ',
    key: 'countDonate',
    dataIndex: 'countDonate',
    render: (countDonate: number, row, index) => <div>{countDonate}</div>,
  },
  {
    title: 'Yêu cầu chờ duyệt',
    key: 'countPending',
    dataIndex: 'countPending',
    render: (countPending: number, row, index) => <div>{countPending}</div>,
  },
  {
    title: 'TT Hoạt động',
    key: 'is_active',
    dataIndex: 'is_active',
    render: (is_active: number, row, index) => <div>{RenderStatus(is_active)}</div>,
  },
  {
    title: 'Huyện xác nhận ',
    key: 'district_post',
    dataIndex: 'district_post',
    render: (district_post: number, row, index) => (
      <div>{district_post ? <Tag color={'green'}>Đã phê duyệt</Tag> : <Tag color={'orange'}>Chưa phê duyệt</Tag>}</div>
    ),
  },
  {
    title: 'TT Phê duyệt',
    key: 'status',
    dataIndex: 'status',
    render: (status: number, row, index) => <div>{RenderPostStatus(status)}</div>,
  },
  {
    title: 'Ngày tạo',
    key: 'create_at',
    dataIndex: 'create_at',
    render: (create_at: string, row, index) => <div>{Moment.getDate(create_at)}</div>,
  },
]

const ListPost = observer(() => {
  useEffect(() => {
    ListPostStore.setParams()
    ListPostStore.getList()
  }, [
    ListPostStore.payload.page,
    ListPostStore.payload.district_post,
    ListPostStore.payload.endDate,
    ListPostStore.payload.startDate,
    ListPostStore.payload.customer_id,
    ListPostStore.payload.is_active,
    ListPostStore.payload.status,
  ])

  useEffect(() => {
    return () => {
      ListPostStore.payload = {
        page: CONSTANCE.DEFAULT_PAGE,
        limit: CONSTANCE.LIMIT,
        customer_id: FUNCTION.GetId(),
      }
    }
  }, [])

  return (
    <div className={'container'}>
      <div className={'grid grid-cols-4 mb-5 gap-4'}>
        <SelectStatusComponent
          onChange={(value) => {
            ListPostStore.payload.is_active = value
            ListPostStore.payload.page = CONSTANCE.DEFAULT_PAGE
          }}
        />
        <Select
          placeholder={'Huyện phê duyệt'}
          allowClear={true}
          onChange={(value) => {
            ListPostStore.payload.district_post = value
            ListPostStore.payload.page = CONSTANCE.DEFAULT_PAGE
          }}
        >
          <Select.Option value={1}>Đã phê duyệt</Select.Option>
          <Select.Option value={0}>Chưa phê duyệt</Select.Option>
        </Select>
        {/*<Select*/}
        {/*  placeholder={'Trạng thái phê duyệt'}*/}
        {/*  allowClear={true}*/}
        {/*  onChange={(value) => {*/}
        {/*    ListPostStore.payload.status = value*/}
        {/*    ListPostStore.payload.page = CONSTANCE.DEFAULT_PAGE*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <Select.Option value={1}>Đã phê duyệt</Select.Option>*/}
        {/*  <Select.Option value={0}>Chưa phê duyệt</Select.Option>*/}
        {/*</Select>*/}
        <SelectPostStatus
          placeholder={'Trạng thái phê duyệt'}
          onChange={(values) => {
            ListPostStore.payload.status = values
            ListPostStore.payload.page = CONSTANCE.DEFAULT_PAGE
          }}
        />
        <RangePickerHoc
          onChange={(stringDate) => {
            ListPostStore.payload.startDate = stringDate[0]
            ListPostStore.payload.startDate = stringDate[1]
            ListPostStore.payload.page = CONSTANCE.DEFAULT_PAGE
          }}
        >
          <DatePicker.RangePicker />
        </RangePickerHoc>
      </div>

      <div className={'mb-2'}>Kết quả lọc: {ListPostStore.total}</div>

      <TableHoc>
        <Table
          columns={columns}
          loading={ListPostStore.loading}
          dataSource={ListPostStore.listPost.map((post, index) => {
            return {
              STT: FUNCTION.GetIndexTable(ListPostStore.payload.page, index),
              key: post.id,
              ...post,
            }
          })}
          pagination={{
            onChange: (page) => (ListPostStore.payload.page = page),
            total: ListPostStore.total,
            current: ListPostStore.payload.page,
          }}
        />
      </TableHoc>
    </div>
  )
})

export default ListPost
