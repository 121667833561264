import { logoutAPI } from './LoginAPI'

export const logout = async () => {
  try {
    const res = await logoutAPI()
    if (res.body.status) {
      localStorage.removeItem(process.env.REACT_APP_TOKEN_NAME as string)
      window.location.href = '/login'
    }
  } catch (e) {
    console.error(e)
    window.location.href = '/login'
  }
}
