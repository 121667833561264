import { Baservices, IApiResponse } from '../../services/Basevices'
import {
  IPayloadRequestSupport,
  IReqChangeStatus,
  IReqPostRequestSupport,
  IReqRequestSupport,
  IResDetailRequestSupport,
  IResRequestSupportInterface,
} from './RequestSupportInterfaces'
import { IResBody } from '../../services/Interfaces'

export const getListRequestSupportAPI = (
  payload: IPayloadRequestSupport
): Promise<IApiResponse<IResRequestSupportInterface>> => {
  return Baservices.getMethod(`/app/donate/list`, payload)
}

export const putChangeStatusRequestSupportAPI = (reqData: IReqChangeStatus): Promise<IApiResponse<IResBody>> => {
  return Baservices.putMethod(`/web/donate/{id}/change-status`, reqData)
}

export const putRequestSupportAPI = (id: number, reqData: IReqRequestSupport): Promise<IApiResponse<IResBody>> => {
  return Baservices.putMethod(`/web/donate/${id}/update`, reqData)
}

export const postRequestSupportAPI = (reqData: IReqPostRequestSupport): Promise<IApiResponse<IResBody>> => {
  return Baservices.postMethod(`/web/donate/create-donate`, reqData)
}

export const getDetailRequestSupportAPI = (id: number): Promise<IApiResponse<IResDetailRequestSupport>> => {
  return Baservices.getMethod(`/web/donate/${id}`)
}

export const putSendRequestEditAPI = (id: number): Promise<IApiResponse<IResBody>> => {
  return Baservices.putMethod(`/web/donate/{id}/change-request`, { donate_ids: [id], status: 1 })
}
