import React, { useEffect, useState } from 'react'
import ModalHoc from '../../../commons/HOC/ModalHOC'
import { Form, Input, Modal } from 'antd'
import { IReqCategory, IResDataCategory } from './CategoryInterfaces'
import storeCharity from './CategoryStoreCharity'
import { useForm } from 'antd/es/form/Form'
import SelectGroupComponent from '../../select/SelectGroupComponent'
import CONSTANCE from '../../../services/Constance'
import storeSupportNeed from './CategoryStoreSupportNeed'

interface IForm extends IReqCategory {
  id?: number
}

const AddUpdateCategory = (props: { type: 'ADD' | 'UPDATE'; postType: 1 | 2; openModal: (fn: Function) => any }) => {
  const [visible, setVisible] = useState<boolean>(false)
  const [form] = useForm()

  const onOpenModal = (row?: IResDataCategory) => {
    setVisible(true)
    if (row) {
      const dataForm: IForm = {
        id: row.id,
        type: row?.type,
        description: row?.description,
        name: row?.name,
        group_id: row?.Group?.id,
      }

      form.setFieldsValue(dataForm)
    }
  }

  const onCloseModal = () => {
    setVisible(false)
    form.resetFields()
  }

  const onFinish = async (values: IForm) => {
    const reqData: IReqCategory = {
      name: values.name,
      type: props?.postType,
      description: values.description ? values.description : '',
      group_id: values.group_id ? values.group_id : 0,
    }
    if (props.type === 'ADD') {
      if (props.postType === CONSTANCE.CATEGORY.CHARITY) {
        const result = await storeCharity.postCategory(reqData)
        if (result) {
          onCloseModal()
        }
      } else {
        const result = await storeSupportNeed.postCategory(reqData)
        if (result) {
          onCloseModal()
        }
      }
    } else {
      delete reqData.type
      if (props.postType === CONSTANCE.CATEGORY.CHARITY) {
        const result = await storeCharity.putCategory(form.getFieldValue('id'), reqData)
        if (result) {
          onCloseModal()
        }
      } else {
        const result = await storeSupportNeed.putCategory(form.getFieldValue('id'), reqData)
        if (result) {
          onCloseModal()
        }
      }
    }
  }

  useEffect(() => {
    props.openModal(onOpenModal)
  }, [])

  return (
    <div>
      <ModalHoc>
        <Modal
          title={props.type === 'ADD' ? 'Thêm mới danh mục' : 'Sửa danh mục'}
          visible={visible}
          onCancel={onCloseModal}
          confirmLoading={
            props.postType === CONSTANCE.CATEGORY.CHARITY
              ? storeCharity.loading.submit
              : storeSupportNeed.loading.submit
          }
          onOk={(e) => form.submit()}
        >
          <Form layout={'vertical'} form={form} onFinish={onFinish}>
            <Form.Item
              label={'Tên danh mục'}
              name={'name'}
              rules={[
                { required: true, whitespace: true, message: 'Vui lòng nhập tên danh mục' },
                { pattern: CONSTANCE.REG.NAME, message: 'Tên danh mục không đúng định dạng' },
                {
                  type: 'string',
                  max: 255,
                  message: 'Tên danh mục không quá 255 ký tự',
                },
              ]}
            >
              <Input placeholder={'Nhập tên danh mục'} />
            </Form.Item>
            <SelectGroupComponent
              label={'Phân nhóm'}
              name={'group_id'}
              placeholder={'Chọn phân nhóm'}
              // rules={[{ required: true, message: 'Vui lòng chọn phân nhóm' }]}
            />
            <Form.Item
              label={'Mô tả'}
              name={'description'}
              // rules={[
              //   { required: true, whitespace: true, message: 'Vui lòng nhập mô tả' },
              //   {
              //     type: 'string',
              //     max: 255,
              //     message: 'Mô tả không quá 255 ký tự',
              //   },
              // ]}
            >
              <Input.TextArea rows={5} placeholder={'Nhập mô tả'} />
            </Form.Item>
          </Form>
        </Modal>
      </ModalHoc>
    </div>
  )
}

export default AddUpdateCategory
