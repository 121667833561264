import React, { useEffect, useState } from 'react'
import CONSTANCE from '../../../services/Constance'
import { Notification } from '../../../commons/notification/Notification'
import { Form, Input, Modal } from 'antd'
import ModalHoc from '../../../commons/HOC/ModalHOC'
import { useForm } from 'antd/es/form/Form'
import store from '../page/PostDetailStore'
import FUNCTION from '../../../services/Functions'
import AppStore from '../../app-store/AppStore'

const FormReject = (props: { modalReject: (fn: Function) => any }) => {
  const [visible, setVisible] = useState<boolean>(false)
  const [formReject] = useForm()

  const onRejectPost = async (values: { reason: string }) => {
    const result = await store.putChangeStatus({
      status: CONSTANCE.POST.POST_STATUS.DENY,
      reason: values.reason,
      post_ids: [FUNCTION.GetId()],
    })
    if (result) {
      setVisible(false)
      Notification.PushNotification('SUCCESS', 'Từ chối tin đăng thành công')
      AppStore.getCount()
    }
  }

  const onOpenModal = () => {
    setVisible(true)
  }

  useEffect(() => {
    props.modalReject(onOpenModal)
  }, [])

  return (
    <ModalHoc>
      <Modal
        title={'Lý do từ chối tin đăng'}
        visible={visible}
        onCancel={(e) => setVisible(false)}
        onOk={(e) => {
          formReject.submit()
        }}
      >
        <Form onFinish={onRejectPost} form={formReject}>
          <Form.Item name={'reason'} rules={[{ required: true, message: 'Vui lòng nhập lý do từ chối ' }]}>
            <Input placeholder={'Nhập lý từ chối tin đăng'} />
          </Form.Item>
        </Form>
      </Modal>
    </ModalHoc>
  )
}

export default FormReject
