import React from 'react'
import { Button, Form, Select, Tag } from 'antd'
import CONSTANCE from '../../services/Constance'
import ICON from '../../commons/icon/Icon'

const SelectStatusComponent = (props: {
  onChange: (value: number | undefined) => any
  name?: string
  isRequired?: boolean
  label?: string
}) => {
  return (
    <Form.Item
      label={props.label}
      name={props.name}
      rules={[{ required: props.isRequired, message: 'Vui lòng chọn trạng thái hoạt động' }]}
    >
      <Select onChange={(value) => props.onChange(value)} allowClear={true} placeholder={'Trạng thái hoạt động'}>
        <Select.Option value={CONSTANCE.STATUS.ACTIVE}>Đang hoạt động</Select.Option>
        <Select.Option value={CONSTANCE.STATUS.INACTIVE}>Ngưng hoạt động</Select.Option>
      </Select>
    </Form.Item>
  )
}

export const RenderStatus = (status: number) => {
  if (status === CONSTANCE.STATUS.ACTIVE) return <Tag color={'green'}>Đang hoạt động</Tag>
  if (status === CONSTANCE.STATUS.INACTIVE) return <Tag color={'gray'}>Ngưng hoạt động</Tag>
}

export const RenderSwitch = (status: number) => {
  if (status === CONSTANCE.STATUS.ACTIVE) return <span className={'icon-switch-on'}>{ICON.SWITCH.TOGGLE_ON}</span>
  if (status === CONSTANCE.STATUS.INACTIVE) return <span className={'icon-switch-off'}>{ICON.SWITCH.TOGGLE_OFF}</span>
}

export const ButtonStatus = (props: { status: number }) => {
  return (
    <div>
      {props.status === CONSTANCE.STATUS.ACTIVE ? (
        <Button type={'primary'}>Ngưng hoạt động</Button>
      ) : (
        <Button type={'primary'} danger>
          Hoạt động
        </Button>
      )}
    </div>
  )
}

export default SelectStatusComponent
