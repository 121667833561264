import React, { useEffect, useRef, useState } from 'react'
import ModalHoc from '../../../commons/HOC/ModalHOC'
import { Button, Divider, Empty, Image, Modal, Popconfirm, Row, Space, Spin, Tag } from 'antd'
import { IResDataRequestSupport } from '../RequestSupportInterfaces'
import store from '../../post/page/PostDetailStore'
import RenderVideoComponent from '../../../commons/render-video/RenderVideoComponent'
import { RenderStatusApproveRequestSupport } from '../../select/SelectStatusRequestSupport'
import UpdateRequestSupport from './UpdateRequestSupport'
import ICON from '../../../commons/icon/Icon'
import CONSTANCE from '../../../services/Constance'
import FUNCTION from '../../../services/Functions'
import PopconfirmHoc from '../../../commons/HOC/PopconfirmHOC'
import RequestStore from '../RequestSupportStore'
import { Notification } from '../../../commons/notification/Notification'
import { observer } from 'mobx-react'
import DetailRequestSupportStore from './DetailSupportStore'
import history from '../../../services/history'
import { ADMIN_ROUTER } from '../../../router/AdminRouter'
import { Moment } from '../../../services/Moment'
import AppStore from '../../app-store/AppStore'
import FormReject from '../../../commons/form/FormReject'

interface IProps {
  openModal?: (fn: Function) => any
}

const RequestSupportDetail: React.FC<IProps> = observer(({ openModal }) => {
  const [visible, setVisible] = useState<boolean>(false)
  const detail = DetailRequestSupportStore.detailRequestSupport

  const modalUpdateRef = useRef<Function>()
  const modalRejectRef = useRef<Function>()

  const onOpenModal = async (record?: IResDataRequestSupport) => {
    setVisible(true)
    await DetailRequestSupportStore.getDetailPostRequest()
  }

  const onCloseModal = () => {
    setVisible(false)
  }

  useEffect(() => {
    openModal && openModal(onOpenModal)
  }, [])

  const onReject = async (values: { reason: string }) => {
    const result = await RequestStore.changeStatus({
      donate_ids: [detail?.id as number],
      reason: values.reason,
      status: CONSTANCE.REQUEST_SUPPORT.STATUS_APPROVE.DENY,
    })
    if (result) {
      Notification.PushNotification('SUCCESS', 'Từ chối yêu cầu thành công')
      onCloseModal()
    }
  }

  const RenderFooter = () => {
    const role = FUNCTION.GetUserInfo().role

    const RenderBtnApprove = () => {
      if (
        detail?.status !== CONSTANCE.REQUEST_SUPPORT.STATUS_APPROVE.DENY &&
        detail?.status !== CONSTANCE.REQUEST_SUPPORT.STATUS_APPROVE.COMPLETE &&
        detail?.status !== CONSTANCE.REQUEST_SUPPORT.STATUS_APPROVE.DONE &&
        detail?.is_update === CONSTANCE.POST.REQUEST_EDIT.NOT_REQUEST &&
        (role === CONSTANCE.ACCOUNT.ADMIN || role === CONSTANCE.ACCOUNT.OFFICER_PROVINCE)
      ) {
        return (
          <PopconfirmHoc>
            <Popconfirm
              title={'Bạn có chắc muôn đồng ý yêu cầu từ thiện này không?'}
              onConfirm={async (e) => {
                const result = await RequestStore.changeStatus({
                  donate_ids: [detail?.id],
                  reason: '',
                  status: CONSTANCE.REQUEST_SUPPORT.STATUS_APPROVE.DONE,
                })
                if (result) {
                  Notification.SweetAlert('SUCCESS', 'Thành công.')
                  onCloseModal()
                  AppStore.getCount()
                }
              }}
            >
              <Button icon={<span className={'mr-2'}>{ICON.PHONE}</span>} type={'primary'}>
                Đã liên hệ
              </Button>
            </Popconfirm>
          </PopconfirmHoc>
        )
      }
    }

    // const RenderBtnRequestChange = () => {
    //   if (
    //     (role === CONSTANCE.ACCOUNT.ADMIN || role === CONSTANCE.ACCOUNT.OFFICER_PROVINCE) &&
    //     detail?.status === CONSTANCE.REQUEST_SUPPORT.STATUS_APPROVE.DISTRICT_APPROVE &&
    //     detail.is_update !== CONSTANCE.POST.REQUEST_EDIT.PROVINCE_REQUEST_UPDATE
    //   )
    //     return (
    //       <PopconfirmHoc>
    //         <Popconfirm
    //           title={'Bạn có muốn gửi yêu cầu chỉnh sửa?'}
    //           onConfirm={async (e) => {
    //             DetailSupportStore.sendRequestEdit(detail?.id)
    //             await DetailRequestSupportStore.getDetailPostRequest()
    //           }}
    //         >
    //           <Button type={'primary'}>Yêu cầu chỉnh sửa lại</Button>
    //         </Popconfirm>
    //       </PopconfirmHoc>
    //     )
    //
    //   if (
    //     role === CONSTANCE.ACCOUNT.OFFICER_DISTRICT &&
    //     ((detail?.is_update !== CONSTANCE.POST.REQUEST_EDIT.NOT_REQUEST &&
    //       detail?.is_update !== CONSTANCE.POST.REQUEST_EDIT.DISTRICT_REQUEST_UPDATE) ||
    //       detail?.status === CONSTANCE.REQUEST_SUPPORT.STATUS_APPROVE.USER_DONATE)
    //   ) {
    //     return (
    //       <PopconfirmHoc>
    //         <Popconfirm
    //           title={'Bạn có muốn gửi yêu cầu chỉnh sửa?'}
    //           onConfirm={async (e) => {
    //             DetailSupportStore.sendRequestEdit(detail?.id as number)
    //             await DetailRequestSupportStore.getDetailPostRequest()
    //           }}
    //         >
    //           <Button type={'primary'}>Yêu cầu chỉnh sửa lại</Button>
    //         </Popconfirm>
    //       </PopconfirmHoc>
    //     )
    //   }
    // }

    const RenderBtnReject = () => {
      if (
        (role === CONSTANCE.ACCOUNT.ADMIN || role === CONSTANCE.ACCOUNT.OFFICER_PROVINCE) &&
        detail?.status !== CONSTANCE.REQUEST_SUPPORT.STATUS_APPROVE.COMPLETE &&
        detail?.status !== CONSTANCE.REQUEST_SUPPORT.STATUS_APPROVE.DENY &&
        detail?.status !== CONSTANCE.REQUEST_SUPPORT.STATUS_APPROVE.DONE
      )
        return (
          <Button
            icon={<span className={'mr-2'}>{ICON.BUTTON.DELETE}</span>}
            type={'primary'}
            danger
            onClick={(event) => modalRejectRef.current && modalRejectRef.current()}
          >
            Xóa
          </Button>
        )

      if (
        role === CONSTANCE.ACCOUNT.OFFICER_DISTRICT &&
        detail?.status === CONSTANCE.REQUEST_SUPPORT.STATUS_APPROVE.USER_DONATE &&
        detail?.status !== CONSTANCE.REQUEST_SUPPORT.STATUS_APPROVE.DONE
      )
        return (
          <Button
            icon={<span className={'mr-2'}>{ICON.BUTTON.DELETE}</span>}
            type={'primary'}
            danger
            onClick={(event) => modalRejectRef.current && modalRejectRef.current()}
          >
            Xóa
          </Button>
        )
    }

    const RenderBtnRequestApprove = () => {
      if (
        role === CONSTANCE.ACCOUNT.OFFICER_DISTRICT &&
        ((detail?.is_update !== CONSTANCE.POST.REQUEST_EDIT.NOT_REQUEST &&
          detail?.is_update !== CONSTANCE.POST.REQUEST_EDIT.DISTRICT_REQUEST_UPDATE) ||
          detail?.status === CONSTANCE.REQUEST_SUPPORT.STATUS_APPROVE.USER_DONATE)
      )
        return (
          <PopconfirmHoc>
            <Popconfirm
              title={'Bạn có chắc muốn gửi yêu cầu phê duyệt?'}
              onConfirm={async (e) => {
                const result = await RequestStore.changeStatus({
                  donate_ids: [detail?.id as number],
                  reason: '',
                  status: CONSTANCE.REQUEST_SUPPORT.STATUS_APPROVE.DISTRICT_APPROVE,
                })
                if (result) {
                  Notification.PushNotification('SUCCESS', 'Gửi yêu cầu phê duyệt thành công')
                  onCloseModal()
                }
              }}
            >
              <Button icon={<span className={'mr-2'}>{ICON.PHONE}</span>} type={'primary'}>
                Đã liên hệ
              </Button>
            </Popconfirm>
          </PopconfirmHoc>
        )
    }

    const RenderButEdit = () => {
      if (
        (FUNCTION.GetUserInfo().role === CONSTANCE.ACCOUNT.ADMIN ||
          FUNCTION.GetUserInfo().role === CONSTANCE.ACCOUNT.OFFICER_PROVINCE) &&
        detail?.status !== CONSTANCE.REQUEST_SUPPORT.STATUS_APPROVE.DENY &&
        detail?.status !== CONSTANCE.REQUEST_SUPPORT.STATUS_APPROVE.DONE &&
        detail?.status !== CONSTANCE.REQUEST_SUPPORT.STATUS_APPROVE.COMPLETE
      ) {
        return (
          <Button
            type={'primary'}
            onClick={(event) => {
              modalUpdateRef.current && modalUpdateRef.current(detail)
              setVisible(false)
            }}
            icon={<span className={'mr-1'}>{ICON.BUTTON.EDIT}</span>}
          >
            Chỉnh sửa
          </Button>
        )
      }

      if (
        FUNCTION.GetUserInfo().role === CONSTANCE.ACCOUNT.OFFICER_DISTRICT &&
        detail?.status === CONSTANCE.REQUEST_SUPPORT.STATUS_APPROVE.USER_DONATE
      ) {
        return (
          <Button
            type={'primary'}
            onClick={(event) => {
              modalUpdateRef.current && modalUpdateRef.current(detail)
              setVisible(false)
            }}
            icon={<span className={'mr-1'}>{ICON.BUTTON.EDIT}</span>}
          >
            Chỉnh sửa
          </Button>
        )
      }

      if (
        detail?.status === CONSTANCE.REQUEST_SUPPORT.STATUS_APPROVE.DONE ||
        detail?.status === CONSTANCE.REQUEST_SUPPORT.STATUS_APPROVE.COMPLETE
      ) {
        return (
          <Button
            type={'primary'}
            onClick={(event) => {
              modalUpdateRef.current && modalUpdateRef.current(detail)
              setVisible(false)
            }}
            icon={<span className={'mr-1'}>{ICON.BUTTON.EDIT}</span>}
          >
            {store.getDataMedia(detail ? detail.DonateImages : []).image.length > 0
              ? 'Chỉnh sửa'
              : 'Cập nhật thông tin hình ảnh'}
          </Button>
        )
      }
    }

    return (
      <Space>
        {RenderBtnReject()}
        {RenderBtnApprove()}
        {/*{RenderBtnRequestChange()}*/}
        {RenderBtnRequestApprove()}
        {RenderButEdit()}
      </Space>
    )
  }

  return (
    <Spin spinning={DetailRequestSupportStore.loading}>
      <ModalHoc>
        <Modal visible={visible} title={'Chi tiết ủng hộ'} width={700} onCancel={onCloseModal} footer={false}>
          <div className={'text-base mb-2 text-rose-600'}>Thông tin người ủng hộ</div>
          <div>
            <div className={'flex justify-between mb-2'}>
              <div className={'text-sm text-stone-500'}>Họ tên người ủng hộ</div>
              <div className={'font-bold'}>{detail?.name}</div>
            </div>
            <div className={'flex justify-between mb-2'}>
              <div className={'text-sm text-stone-500'}>Họ tên người nhận ủng hộ</div>
              <div className={'font-bold'}>{detail?.DonateRequest.name}</div>
            </div>
            <div className={'flex justify-between mb-2'}>
              <div className={'text-sm text-stone-500'}>Thời gian thực hiện</div>
              <div className={'font-bold'}>{Moment.getDate(detail?.create_at)}</div>
            </div>

            <div className={'flex justify-between mb-2'}>
              <div className={'text-sm text-stone-500'}>Hình thức ủng hộ</div>
              <div className={'font-bold'}>
                {detail &&
                  detail.form_support.map((value) => {
                    return <Tag color={'purple'}>{value.name}</Tag>
                  })}
              </div>
            </div>
            {/*<div className={'flex justify-between mb-2'}>*/}
            {/*  <div className={'text-sm text-stone-500'}>Trạng thái thực hiện</div>*/}
            {/*  <div className={'font-bold'}>{RenderStatusRequestSupport(detail?.implement_status as number)}</div>*/}
            {/*</div>*/}
            <div className={'flex justify-between mb-2'}>
              <div className={'text-sm text-stone-500'}>Trạng thái phê duyệt</div>
              <div className={'font-bold'}>{RenderStatusApproveRequestSupport(detail?.status as number)}</div>
            </div>

            <Divider />

            <div className={'text-base mb-2 text-rose-600'}>Thông tin bài đăng</div>
            <div className={'flex justify-between mb-2'}>
              <div className={'text-sm text-stone-500 w-40'}>Bài đăng</div>
              <div
                className={'font-bold cursor-pointer text-blue-700 hover:underline-offset-2 hover:underline'}
                onClick={(event) => history.push(ADMIN_ROUTER.POST_DETAIL.path + `?index=${detail?.DonateRequest.id}`)}
              >
                {detail?.DonateRequest.title || '___'}
                <span className={'ml-2'}>{ICON.DETAIL}</span>
              </div>
            </div>

            <div className={'flex justify-between mb-2'}>
              {detail?.district_post ? <div className={'text-sm text-rose-700'}>Huyện đã liên hệ</div> : null}
            </div>
            <div className={'flex justify-between mb-2'}>
              {detail?.is_update ? <div className={'text-sm text-rose-700'}>Yêu cầu chỉnh sửa</div> : null}
            </div>
          </div>

          {/*<Divider />*/}

          {detail?.status === CONSTANCE.REQUEST_SUPPORT.STATUS_APPROVE.COMPLETE ||
          detail?.status === CONSTANCE.REQUEST_SUPPORT.STATUS_APPROVE.DONE ? (
            <div>
              <div>
                <div className={'text-base mb-2 text-rose-600'}>Thông tin từ thiện</div>

                {detail?.note ? (
                  <div className={'flex justify-between mb-2'}>
                    <div className={'text-sm text-stone-500'}>Tiêu đề</div>
                    <div className={'font-bold'}>{detail?.title || '___'}</div>
                  </div>
                ) : null}

                {detail?.content ? (
                  <div className={'flex w-full'}>
                    <div className={'mr-4'}>Nội dung</div>
                    <div>{detail?.content || '___'}</div>
                  </div>
                ) : null}
              </div>

              {store.getDataMedia(detail ? detail.DonateImages : []).image.length > 0 && (
                <div>
                  <Divider />
                  <div className={'text-base text-rose-600 mb-2'}>Hình ảnh thực tế</div>
                  <div>
                    {detail &&
                      detail.DonateImages.map((value) => {
                        if (value.type === CONSTANCE.MEDIA.IMAGES) {
                          return (
                            <Image
                              src={value.url}
                              style={{ width: 120, height: 120, marginLeft: 5, objectFit: 'cover' }}
                            />
                          )
                        }
                      })}
                    {store.getDataMedia(detail ? detail.DonateImages : []).image.length === 0 && <Empty />}
                  </div>
                </div>
              )}

              {store.getDataMedia(detail ? detail.DonateImages : []).video.length > 0 && (
                <div>
                  <Divider />
                  <div className={'text-base text-rose-600 mb-2'}>Video thực tế</div>
                  <div>
                    {detail?.DonateImages.map((value) => {
                      if (value.type === CONSTANCE.MEDIA.VIDEO) {
                        return <RenderVideoComponent src={value.url} />
                      }
                    })}
                    {store.getDataMedia(detail ? detail.DonateImages : []).video.length === 0 && <Empty />}
                  </div>
                </div>
              )}
            </div>
          ) : null}

          <Divider />

          <Row justify={'center'} className={'mt-5'}>
            {RenderFooter()}
          </Row>
        </Modal>
      </ModalHoc>
      <UpdateRequestSupport
        openModal={(fn) => (modalUpdateRef.current = fn)}
        closeModal={() => {
          onOpenModal(detail)
        }}
      />
      <FormReject title={'Xóa ủng hộ'} fn={onReject} fnOpenModal={(fn) => (modalRejectRef.current = fn)} />
    </Spin>
  )
})

export default RequestSupportDetail
