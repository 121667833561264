import { makeAutoObservable } from 'mobx'
import { IReqChangeStatusPost, IResDataDetailPost } from '../PostInterface'
import {
  deletePostAPI,
  getDetailPostAPI,
  putChangeStatusActive,
  putChangeStatusPostAPI,
  putSendRequestEdit,
} from '../PostAPI'
import { IFile } from '../../../commons/upload-file/UploadComponent'
import CONSTANCE from '../../../services/Constance'
import FUNCTION from '../../../services/Functions'
import { Notification } from '../../../commons/notification/Notification'
import history from '../../../services/history'
import { ADMIN_ROUTER } from '../../../router/AdminRouter'

class PostDetailStore {
  loading = {
    getDetail: false,
  }
  detailPost: IResDataDetailPost | undefined = undefined

  constructor() {
    makeAutoObservable(this)
  }

  async putChangeStatus(reqData: IReqChangeStatusPost): Promise<boolean> {
    try {
      const res = await putChangeStatusPostAPI(reqData)
      if (res.body.status) {
        this.getDetailPost(FUNCTION.GetId())

        return FUNCTION.GetPromise(true)
      }
      return FUNCTION.GetPromise(false)
    } catch (e) {
      console.error(e)
      return FUNCTION.GetPromise(false)
    }
  }

  async getDetailPost(id: number) {
    try {
      this.loading.getDetail = true
      const res = await getDetailPostAPI(id)
      if (res.body.status) {
        this.detailPost = res.body.data
      }
    } catch (e) {
      console.error(e)
    } finally {
      this.loading.getDetail = false
    }
  }

  getFile(data: { id: number; type: number; media_url: string; media_path: string }[]): {
    image: IFile[]
    video: IFile[]
  } {
    let image: IFile[] = []
    let video: IFile[] = []

    data.forEach((value) => {
      if (value.type === CONSTANCE.MEDIA.IMAGES) {
        image.push({
          name: value.media_path,
          url: value.media_url,
          uid: value.media_path,
          status: 'done',
          response: {
            data: {
              filename: value.media_path,
              url: value.media_url,
            },
          },
        })
      } else {
        video.push({
          name: value.media_path,
          url: value.media_url,
          uid: value.media_path,
          status: 'done',
          response: {
            data: {
              filename: value.media_path,
              url: value.media_url,
            },
          },
        })
      }
    })

    return {
      image: image,
      video: video,
    }
  }

  getCategory(
    data: {
      category_id: number
      category_name: string
      id: number
      type: number
    }[]
  ): {
    charity: number[]
    supportNeeds: number[]
  } {
    let charity: number[] = []
    let supportNeeds: number[] = []

    data.forEach((value) => {
      if (value.type === CONSTANCE.CATEGORY.CHARITY) {
        charity.push(value.category_id)
      }
      if (value.type === CONSTANCE.CATEGORY.SUPPORT_NEEDS) {
        supportNeeds.push(value.category_id)
      }
    })

    return {
      charity: charity,
      supportNeeds: supportNeeds,
    }
  }

  getDataMedia(data: any[]): { image: string[]; video: string[] } {
    const image: string[] = []
    const video: string[] = []

    data &&
      data.map((value) => {
        if (value.type === CONSTANCE.MEDIA.IMAGES) {
          image.push(value.media_url)
        } else {
          video.push(value.media_url)
        }
      })
    return {
      image: image,
      video: video,
    }
  }

  async changeStatusActivePost(id: number) {
    try {
      const res = await putChangeStatusActive(id)
      if (res.body.status) {
        Notification.PushNotification('SUCCESS', 'Thay đổi trạng thái thành công')
        setTimeout(() => {
          this.getDetailPost(id)
        }, 500)
      }
    } catch (e) {
      console.error(e)
    }
  }

  async deletePost(id: number): Promise<boolean> {
    try {
      const res = await deletePostAPI(id)
      if (res.body.status) {
        Notification.PushNotification('SUCCESS', 'Xóa bài đăng thành công')
        history.push(ADMIN_ROUTER.POST.path)
        return FUNCTION.GetPromise(true)
      } else {
        return FUNCTION.GetPromise(false)
      }
    } catch (e) {
      console.error(e)
      return FUNCTION.GetPromise(false)
    }
  }

  async sendRequestEdit(id: number) {
    try {
      const res = await putSendRequestEdit(id)
      if (res.body.status) {
        Notification.PushNotification('SUCCESS', 'Gửi yêu cầu chỉnh sửa thành công')
        this.getDetailPost(id)
      }
    } catch (e) {
      console.error(e)
    }
  }
}

const store = new PostDetailStore()

export default store
