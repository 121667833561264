import styled from 'styled-components'
import { Layout } from 'antd'
import { COLOR } from './VariablesStyle'

export const HeaderStyles = styled(Layout.Header)`
  background: white;
  border-bottom: 1.2px solid ${COLOR.BORDER};
  height: 2.8rem;
  padding: 0 50px 0 0;

  position: fixed;
  z-index: 25;
  top: 0;
  left: 0;
  right: 0;
`
export const SiderStyles = styled(Layout.Sider)`
  background: ${COLOR.WHITE};
  border-right: 1.2px solid ${COLOR.BORDER};

  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;

  z-index: 25;
  border-bottom: 0;

  .ant-layout-sider-trigger {
    background: ${COLOR.WHITE};
    border: 1px solid ${COLOR.BORDER};

    color: ${COLOR.TEXT_DARK};
  }
  /* @media screen and (max-width: 600px) {
    display: none;
  } */
`

export const ContentStyles = styled(Layout.Content)`
  min-height: calc(100vh - 2.8rem);
  margin-top: 2.8rem;
  margin-left: 180px;
`

export const UserInfoStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  margin-top: 2.8rem;
  border-bottom: 1px solid ${COLOR.BORDER};

  padding: 14px 0;
  overflow: hidden;

  .user-name {
    margin-top: 4px;
  }
`

export const StyleContentPopover = styled.div`
  position: fixed;
  top: 2.5rem;
  right: 30px;
  background-color: white;
  box-shadow: 0 13px 27px -5px rgb(50 50 93 / 25%), 0 8px 16px -8px rgb(0 0 0 / 30%);
  border-radius: 4.5px;

  padding: 4px;
  cursor: pointer;

  .item {
    font-family: OpenSans-Semibold, sans-serif;
    padding: 6px;

    &:hover {
      background-color: #f7f7f7;
    }
  }
`
