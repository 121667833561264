import { makeAutoObservable } from 'mobx'
import { Baservices, IApiResponse } from '../../services/Basevices'
import { IResBody } from '../../services/Interfaces'

class DashboardStore {
  loading: boolean = false
  data = {
    user: 0,
    donate_request: 0,
    donate: 0,
  }

  constructor() {
    makeAutoObservable(this)
  }

  async getData() {
    try {
      this.loading = true
      const response = await getDataDashboardAPI()
      if (response.body.status) {
        this.data = response.body.data
      }
    } catch (e) {
      console.error(e)
    } finally {
      this.loading = false
    }
  }
}

const store = new DashboardStore()

export default store

// CALL API

interface IResDashboard extends IResBody {
  data: {
    user: number
    donate_request: number
    donate: number
  }
}

const getDataDashboardAPI = (): Promise<IApiResponse<IResDashboard>> => {
  return Baservices.getMethod(`/web/home`)
}
