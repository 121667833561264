import { Baservices, IApiResponse } from '../../services/Basevices'
import { IReqLogin, IResLogin } from './Interfaces'
import { IResBody } from '../../services/Interfaces'

export const loginAPI = async (reqData: IReqLogin): Promise<IApiResponse<IResLogin>> => {
  return Baservices.putMethod(`/authen/login`, reqData)
}

export const logoutAPI = async (): Promise<IApiResponse<IResBody>> => {
  return Baservices.putMethod(`/web/users/logout`)
}
