import React, { useEffect } from 'react'
import { Form, FormItemProps, Select } from 'antd'
import store from '../config/group/GroupStore'
import { observer } from 'mobx-react'

interface IProps extends FormItemProps {
  onChange?: (values?: number | number[]) => any
  placeholder?: string
  disabled?: boolean
  mode?: 'tags' | 'multiple'
}

const SelectGroupComponent: React.FC<IProps> = observer(({ onChange, mode, placeholder, disabled, ...rest }) => {
  useEffect(() => {
    if (store.listGroupFull.length === 0) {
      store.getListGroupFull()
    }
  }, [])

  return (
    <Form.Item {...rest}>
      <Select
        placeholder={placeholder}
        disabled={disabled}
        loading={store.loading.getList}
        showSearch
        allowClear={true}
        mode={mode}
        optionFilterProp={'children'}
        onChange={(value) => onChange && onChange(value)}
      >
        {store.listGroupFull.map((value) => {
          return (
            <Select.Option key={value.id} value={value.id}>
              {value.name}
            </Select.Option>
          )
        })}
      </Select>
    </Form.Item>
  )
})

export default SelectGroupComponent
