import { makeAutoObservable } from 'mobx'
import { IResBody, IResPaging } from '../../../services/Interfaces'
import { Baservices, IApiResponse } from '../../../services/Basevices'
import CONSTANCE from '../../../services/Constance'
import FUNCTION from '../../../services/Functions'

class ListDonateStore {
  loading: boolean = false
  payload: IPayload = {
    page: CONSTANCE.DEFAULT_PAGE,
    limit: CONSTANCE.LIMIT,
    customer_id: FUNCTION.GetId(),
  }
  listDonate: IResDataDonate[] = []
  total: number = 0

  constructor() {
    makeAutoObservable(this)
  }
  async setParams() {
    this.payload = {
      page: CONSTANCE.DEFAULT_PAGE,
      limit: CONSTANCE.LIMIT,
      customer_id: FUNCTION.GetId(),
    }
  }

  async getList() {
    try {
      this.loading = true
      const response = await getListAPI({ ...this.payload, search: this.payload.search?.trim() })

      if (response.body.status) {
        this.listDonate = response.body.data
        this.total = response.body.paging.totalItemCount
      }
    } catch (e) {
      console.error(e)
    } finally {
      this.loading = false
    }
  }
}

const store = new ListDonateStore()

export default store

// CALL API

// INTERFACE
interface IPayload {
  page: number
  limit: number
  status?: number
  startDate?: string
  endDate?: string
  is_active?: number
  customer_id?: number
  search?: string
}

interface IResDataDonate {
  id: number
  user_id: number
  phone: string
  name: string
  email: string
  donate_request_id: number
  implement_status: number
  form_support: {
    id: number
    name: string
    Group: {
      id: number
      name: string
      status: number
      version: number
      create_at: string
      create_by: string
      delete_at: string
      delete_by: string
      is_active: number
      update_at: string
      update_by: string
      description: string
      manage_push: number
    }
    status: number
    description: string
    manage_push: number
  }[]
  content: string
  district_post: number
  note: string
  status: number
  is_active: number
  create_at: string
  update_at: string
  DonateRequest: {
    id: number
    name: string
    phone: string
  }
}

interface IResListDonate extends IResBody {
  data: IResDataDonate[]
  paging: IResPaging
}

// END INTERFACE

const getListAPI = (payload: IPayload): Promise<IApiResponse<IResListDonate>> => {
  return Baservices.getMethod(`/web/users/listDonateById`, payload)
}
