import React, { useEffect } from 'react'
import { PageHeader, Spin } from 'antd'
import ICON from '../../commons/icon/Icon'
import { observer } from 'mobx-react'
import DashboardStore from './DashboardStore'
import history from '../../services/history'
import { ADMIN_ROUTER } from '../../router/AdminRouter'

const Dashboard = observer(() => {
  const Item: React.FC<{ className?: string; icon?: React.ReactNode; title?: string; count?: number }> = ({
    className,
    icon,
    title,
    count,
  }) => {
    return (
      <div className={'flex justify-center'}>
        <div
          className={`w-80 ${className} rounded p-5 flex justify-between`}
          style={{
            height: 120,
          }}
        >
          <span className={'text-white text-3xl mr-5'}>{icon}</span>
          <span className={'text-white text-xl'}>
            <span>{title}</span>
            <div className={'mt-4'} style={{ textAlign: 'end' }}>
              {count}
            </div>
          </span>
        </div>
      </div>
    )
  }

  useEffect(() => {
    DashboardStore.getData()
  }, [])

  return (
    <Spin spinning={DashboardStore.loading}>
      <PageHeader title={'Tổng Quan'} />
      <div className={'box'}>
        <div className={'container grid lg:grid-cols-3 md:grid-cols-1 gap-8'}>
          <div onClick={(event) => history.push(ADMIN_ROUTER.CUSTOMER.path)}>
            <Item
              className={'bg-violet-400'}
              icon={ICON.MENU_ICON.CUSTOMER}
              title={'Khách hàng'}
              count={DashboardStore.data.user}
            />
          </div>

          <div onClick={(event) => history.push(ADMIN_ROUTER.POST.path)}>
            <Item
              className={'bg-pink-400'}
              icon={ICON.MAIL}
              title={'Tin đăng'}
              count={DashboardStore.data.donate_request}
            />
          </div>

          <div onClick={(event) => history.push(ADMIN_ROUTER.REQUEST_SUPPORT.path)}>
            <Item
              className={'bg-blue-400'}
              icon={ICON.MENU_ICON.ACCOUNT}
              title={'Từ thiện'}
              count={DashboardStore.data.donate}
            />
          </div>
        </div>
      </div>

      {/*<div className={'bg-blue-400 w-128 h-60'}>hi</div>*/}
    </Spin>
  )
})

export default Dashboard
