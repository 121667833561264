import React from 'react'
import { Form, FormItemProps, Select, Tag } from 'antd'

export interface IProps extends FormItemProps {
  data?: { [key: string]: any }
  placeholder?: string
  disabled?: boolean
  onChange?: (data?: number | number[] | string | string[]) => any
}

const SelectAppComponent: React.FC<IProps> = ({ placeholder, disabled, onChange, data, ...rest }) => {
  const RenderOptionItem = (data: any): React.ReactNode[] => {
    let result = []
    for (const property in data) {
      result.push(
        <Select.Option key={property} value={data[property].value}>
          {data[property].name}
        </Select.Option>
      )
    }

    return result
  }

  return (
    <Form.Item {...rest}>
      <Select
        placeholder={placeholder}
        disabled={disabled}
        allowClear={true}
        onChange={(value) => onChange && onChange(value)}
      >
        {RenderOptionItem(data)}
      </Select>
    </Form.Item>
  )
}

export const RenderAppTag = (active: number, data: { [key: string]: any }) => {
  const RenderTagItem = (data: any): React.ReactNode[] => {
    let result = []
    for (const property in data) {
      if (active === data[property].value) {
        result.push(<Tag color={data[property].color}>{data[property].name}</Tag>)
      }
    }
    return result
  }

  return <div>{RenderTagItem(data)}</div>
}

export default SelectAppComponent
