import React, { useEffect, useState } from 'react'
import { Form, FormItemProps, Select, Tag } from 'antd'
import CONSTANCE from '../../services/Constance'
import { Baservices, IApiResponse } from '../../services/Basevices'
import { IResBody } from '../../services/Interfaces'

interface IProps extends FormItemProps {
  onChange?: (values: number | undefined) => any
  placeholder?: string
}

export const SelectPostStatus: React.FC<IProps> = ({ onChange, placeholder, ...rest }) => {
  // const GetSelectPendingOption = () => {
  //   if (FUNCTION.GetUserInfo().role === CONSTANCE.ACCOUNT.OFFICER_WARD) {
  //     return (
  //       <Select.Option
  //         key={CONSTANCE.POST.POST_STATUS.PENDING_WARD_APPROVE}
  //         values={CONSTANCE.POST.POST_STATUS.PENDING_WARD_APPROVE}
  //       >
  //         Chờ duyệt
  //       </Select.Option>
  //     )
  //   }
  //   if (FUNCTION.GetUserInfo().role === CONSTANCE.ACCOUNT.OFFICER_DISTRICT) {
  //     return (
  //       <Select.Option
  //         key={CONSTANCE.POST.POST_STATUS.}
  //         values={CONSTANCE.POST.POST_STATUS.PENDING_WARD_APPROVE}
  //       >
  //         Chờ duyệt
  //       </Select.Option>
  //     )
  //   }
  // }

  const GetSelectApproveOption = () => {}

  return (
    <Form.Item {...rest}>
      <Select
        placeholder={placeholder}
        allowClear={true}
        onChange={(value) => onChange && onChange(value)}
        optionFilterProp={'children'}
      >
        <Select.Option key={CONSTANCE.POST.POST_STATUS.DENY} values={CONSTANCE.POST.POST_STATUS.DENY}>
          Từ chối
        </Select.Option>
        <Select.Option key={CONSTANCE.POST.POST_STATUS.DONE} values={CONSTANCE.POST.POST_STATUS.DONE}>
          Phê duyệt
        </Select.Option>
        <Select.Option key={CONSTANCE.POST.POST_STATUS.USER_POST} values={CONSTANCE.POST.POST_STATUS.USER_POST}>
          Chờ phê duyệt
        </Select.Option>
      </Select>
    </Form.Item>
  )
}

export const RenderPostStatus = (status: number) => {
  if (CONSTANCE.POST.POST_STATUS.DENY === status) return <Tag color={'red'}>Từ chối</Tag>
  if (CONSTANCE.POST.POST_STATUS.DONE === status) return <Tag color={'blue'}>Phê duyệt</Tag>
  if (status === CONSTANCE.POST.POST_STATUS.USER_POST || status === CONSTANCE.POST.POST_STATUS.DISTRICT_CONFIRM) {
    return <Tag color={'orange'}>Chờ phê duyệt</Tag>
  }
}

interface IResPost extends IResBody {
  data: {
    id: number
    title: string
  }[]
}

interface IPropsPostApprove extends FormItemProps {
  placeholder?: string
  onChange?: (value: number | number[], data: { id: number; name: string; phone: string; title: string }) => any
  disable?: boolean
}

export const SelectPostApprove: React.FC<IPropsPostApprove> = ({ onChange, placeholder, disable, ...rest }) => {
  const getListPostAPI = (): Promise<IApiResponse<IResPost>> => {
    return Baservices.getMethod(`/web/donate/listPostBrowser`)
  }
  //
  const [loading, setLoading] = useState<boolean>()
  const [listPost, setListPost] = useState<{ id: number; title: string }[]>([])

  const getListPost = async () => {
    try {
      setLoading(true)
      const res = await getListPostAPI()
      if (res.body.status) {
        setListPost(res.body.data)
      }
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getListPost()
  }, [])

  return (
    <Form.Item {...rest}>
      <Select
        loading={loading}
        showSearch={true}
        optionFilterProp={'children'}
        disabled={disable}
        placeholder={placeholder}
        onChange={(value, option: any) => {
          onChange && onChange(value, JSON.parse(option.key as string))
        }}
      >
        {listPost.map((value) => {
          return (
            <Select.Option key={JSON.stringify(value)} value={value.id}>
              {value.title}
            </Select.Option>
          )
        })}
      </Select>
    </Form.Item>
  )
}

export const RenderDistrictApproveTag = (districtPost: number) => {
  if (districtPost) {
    return <span className={'text-rose-700 text-xs'}>(Huyện đã duyệt)</span>
  }
}
