import { makeAutoObservable } from 'mobx'
import { IPayloadCategory, IReqCategory, IResDataCategory } from './CategoryInterfaces'
import CONSTANCE from '../../../services/Constance'
import { deleteCategoryAPI, getListCategoryAPI, postCategoryAPI, putCategoryAPI } from './CategoryAPI'
import { Notification } from '../../../commons/notification/Notification'
import FUNCTION from '../../../services/Functions'

class CategoryStoreCharity {
  loading = {
    getList: false,
    submit: false,
  }
  listCategory: IResDataCategory[] = []
  total: number = 0
  payload: IPayloadCategory = {
    page: CONSTANCE.DEFAULT_PAGE,
    limit: CONSTANCE.LIMIT,
  }

  listCategoryFull: IResDataCategory[] = []

  constructor() {
    makeAutoObservable(this)
  }

  async getListCategory() {
    try {
      this.loading.getList = true
      const res = await getListCategoryAPI({
        ...this.payload,
        search: this.payload.search?.trim(),
        type: CONSTANCE.CATEGORY.CHARITY,
      })
      if (res.body.status) {
        this.listCategory = res.body.data
        this.total = res.body.paging.totalItemCount
      }
    } catch (e) {
      console.error(e)
    } finally {
      this.loading.getList = false
    }
  }

  async getListCategoryFull(groupId: number) {
    try {
      this.loading.getList = true
      const res = await getListCategoryAPI({
        page: CONSTANCE.DEFAULT_PAGE,
        group_id: groupId,
        type: CONSTANCE.CATEGORY.CHARITY,
      })
      if (res.body.status) {
        this.listCategoryFull = res.body.data
      }
    } catch (e) {
      console.error(e)
    } finally {
      this.loading.getList = false
    }
  }

  async postCategory(reqData: IReqCategory): Promise<boolean> {
    try {
      console.log('reqData', reqData)

      this.loading.submit = true
      const res = await postCategoryAPI(reqData)
      if (res.body.status) {
        Notification.PushNotification('SUCCESS', 'Thêm mới danh mục thành công')
        setTimeout(() => {
          this.getListCategory()
        }, 500)
        return new Promise((resolve) => resolve(true))
      } else {
        return new Promise((resolve) => resolve(false))
      }
    } catch (e) {
      console.error(e)
      return new Promise((resolve) => resolve(false))
    } finally {
      this.loading.submit = false
    }
  }

  async putCategory(id: number, reqData: IReqCategory): Promise<boolean> {
    try {
      this.loading.submit = true
      const res = await putCategoryAPI(id, reqData)
      if (res.body.status) {
        Notification.PushNotification('SUCCESS', 'Cập nhật danh mục thành công')
        setTimeout(() => {
          this.getListCategory()
        }, 500)
        return FUNCTION.GetPromise(true)
      } else {
        return FUNCTION.GetPromise(false)
      }
    } catch (e) {
      console.error(e)
      return FUNCTION.GetPromise(false)
    } finally {
      this.loading.submit = false
    }
  }

  async deleteCategory(id: number): Promise<boolean> {
    try {
      const res = await deleteCategoryAPI(id)
      if (res.body.status) {
        Notification.PushNotification('SUCCESS', 'Xóa danh mục thành công')
        setTimeout(() => {
          this.getListCategory()
        }, 500)
        return FUNCTION.GetPromise(true)
      } else {
        return FUNCTION.GetPromise(false)
      }
    } catch (e) {
      console.error(e)
      return FUNCTION.GetPromise(false)
    } finally {
    }
  }
}

const store = new CategoryStoreCharity()

export default store
