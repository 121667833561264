import React, { useEffect } from 'react'
import { Button, DatePicker, Input, PageHeader, Popconfirm, Table } from 'antd'
import RangePickerHoc from '../../commons/HOC/RangePickerHOC'
import TableHoc from '../../commons/HOC/TableHOC'
import { IColumn } from '../../services/Interfaces'
import ICON from '../../commons/icon/Icon'
import SelectAppComponent, { RenderAppTag } from '../select/SelectAppComponent'
import CONSTANCE from '../../services/Constance'
import { observer } from 'mobx-react'
import { Moment } from '../../services/Moment'
import NewStore from './NewStore'
import FUNCTION from '../../services/Functions'
import { ADMIN_ROUTER } from '../../router/AdminRouter'
import history from '../../services/history'

const columns: IColumn[] = [
  {
    title: 'STT',
    key: 'STT',
    dataIndex: 'STT',
    width: 80,
    align: 'center',
    render: (STT, row, index) => <div>{STT}</div>,
  },
  {
    title: 'Tiêu đề',
    key: 'title',
    dataIndex: 'title',
    render: (title, row, index) => <div>{title}</div>,
  },
  {
    title: 'Tác giả',
    key: 'create_by',
    dataIndex: 'create_by',
    render: (create_by, row, index) => <div>{create_by}</div>,
  },
  {
    title: 'Danh mục',
    key: 'post_status',
    dataIndex: 'post_status',
    render: (post_status: number, row, index) => <div>{RenderAppTag(row.type, CONSTANCE.NEWS.TYPE_BANNER)}</div>,
  },
  {
    title: 'Trạng thái',
    key: 'status',
    dataIndex: 'status',
    render: (status: number, row, index) => <div>{RenderAppTag(row.post_status, CONSTANCE.NEWS.STATUS_BANNER)}</div>,
  },
  {
    title: 'Ngày tạo',
    key: 'create_at',
    dataIndex: 'create_at',
    render: (create_at: string, row, index) => <div>{Moment.getDate(create_at)}</div>,
  },
  {
    title: '',
    key: 'id',
    dataIndex: 'id',
    width: 80,
    render: (id: number, row, index) => (
      <div className={'grid grid-cols-2 gap-1'}>
        <Popconfirm
          title={'Bạn có chắc muốn xóa tin tức này ra khỏi hệ thống không?'}
          okText={'Xóa'}
          cancelText={'Hủy'}
          okButtonProps={{ danger: true }}
          cancelButtonProps={{ type: 'primary' }}
          onConfirm={(e) => {
            NewStore.deleteNews(id)
          }}
        >
          <span className={'icon-delete'}>{ICON.BUTTON.DELETE}</span>
        </Popconfirm>
        <span
          className={'icon-edit'}
          onClick={(event) => history.push(ADMIN_ROUTER.NEWS_ADD_UPDATE.path + `?index=${id}`)}
        >
          {ICON.BUTTON.EDIT}
        </span>
      </div>
    ),
  },
]

const News = observer(() => {
  useEffect(() => {
    const idTimeOut = setTimeout(() => {
      NewStore.getListNews()
    }, 500)
    return () => clearTimeout(idTimeOut)
  }, [
    NewStore.payload.page,
    NewStore.payload.search,
    NewStore.payload.endDate,
    NewStore.payload.startDate,
    NewStore.payload.post_status,
    NewStore.payload.type,
  ])

  useEffect(() => {
    return () => {
      NewStore.payload = {
        page: CONSTANCE.DEFAULT_PAGE,
        limit: CONSTANCE.LIMIT,
      }
    }
  }, [])

  return (
    <div>
      <PageHeader title={'Tin tức'} />

      <div className={'box grid lg:grid-cols-5 md:grid-cols-2 gap-4'}>
        <Input
          placeholder={'Nhập tiêu đề'}
          onChange={(event) => {
            NewStore.payload.search = event.target.value
            NewStore.payload.page = CONSTANCE.DEFAULT_PAGE
          }}
        />
        <SelectAppComponent
          data={CONSTANCE.NEWS.TYPE_BANNER}
          placeholder={'Danh mục'}
          onChange={(data) => {
            NewStore.payload.type = data as number
            NewStore.payload.page = CONSTANCE.DEFAULT_PAGE
          }}
        />
        <SelectAppComponent
          data={CONSTANCE.NEWS.STATUS_BANNER}
          placeholder={'Trạng thái'}
          onChange={(data) => {
            NewStore.payload.post_status = data as number
            NewStore.payload.page = CONSTANCE.DEFAULT_PAGE
          }}
        />
        <RangePickerHoc
          onChange={(stringDate) => {
            NewStore.payload.startDate = stringDate[0]
            NewStore.payload.endDate = stringDate[1]
            NewStore.payload.page = CONSTANCE.DEFAULT_PAGE
          }}
        >
          <DatePicker.RangePicker />
        </RangePickerHoc>

        <div className={'flex justify-end'}>
          <Button
            onClick={(event) => history.push(ADMIN_ROUTER.NEWS_ADD_UPDATE.path)}
            type={'primary'}
            icon={<span className={'mr-2'}>{ICON.BUTTON.ADD}</span>}
          >
            Thêm mới
          </Button>
        </div>
      </div>

      <div className={'box'}>
        <TableHoc>
          <Table
            columns={columns}
            dataSource={NewStore.listNews.map((value, index) => {
              return { ...value, STT: FUNCTION.GetIndexTable(NewStore.payload.page, index) }
            })}
            loading={NewStore.loading.getList}
            pagination={{
              onChange: (page) => (NewStore.payload.page = page),
              total: NewStore.total,
              current: NewStore.payload.page,
            }}
          />
        </TableHoc>
      </div>
    </div>
  )
})

export default News
