import React from 'react'
import { Badge, Menu } from 'antd'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import ICON from '../../commons/icon/Icon'
import { ADMIN_ROUTER } from '../../router/AdminRouter'
import { observer } from 'mobx-react'
import AppStore from '../app-store/AppStore'

interface ISingleMenu {
  key: string
  value: string
  path: string
  icon?: React.ReactNode
  count?: number
}

interface ISubMenu {
  key: string
  value: string
  icon?: React.ReactNode
  listChild: ISingleMenu[]
}

interface IGroupItem {
  key: string
  title: string
  listChild: ISingleMenu[]
}

interface IGroupMenu {
  key: string
  value: string
  icon?: React.ReactNode
  listChild: IGroupItem[]
}

interface IDataMenu {
  type: 'SINGLE_MENU' | 'SUB_MENU' | 'GROUP_MENU'
  item: ISingleMenu | ISubMenu | IGroupMenu
}

export const dataMenuAdmin: IDataMenu[] = [
  {
    type: 'SINGLE_MENU',
    item: {
      key: ADMIN_ROUTER.DASHBOARD.path,
      path: ADMIN_ROUTER.DASHBOARD.path,
      value: 'Tổng quan',
      icon: ICON.MENU_ICON.DASHBOARD,
    } as ISingleMenu,
  },
  {
    type: 'SINGLE_MENU',
    item: {
      key: ADMIN_ROUTER.CUSTOMER.path,
      path: ADMIN_ROUTER.CUSTOMER.path,
      value: 'Danh sách người dùng',
      icon: ICON.MENU_ICON.CUSTOMER,
    } as ISingleMenu,
  },
  {
    type: 'SINGLE_MENU',
    item: {
      key: ADMIN_ROUTER.POST.path,
      path: ADMIN_ROUTER.POST.path,
      value: 'Tin đăng',
      count: AppStore.countPost,
      icon: ICON.MENU_ICON.LETTERS,
    } as ISingleMenu,
  },
  {
    type: 'SINGLE_MENU',
    item: {
      key: ADMIN_ROUTER.REQUEST_SUPPORT.path,
      path: ADMIN_ROUTER.REQUEST_SUPPORT.path,
      value: 'Ủng hộ',
      icon: ICON.MENU_ICON.APPROVE,
      count: AppStore.countRequestSupport,
    } as ISingleMenu,
  },
  // {
  //   type: 'SINGLE_MENU',
  //   item: {
  //     key: 'ADMIN_ROUTER.CUSTOMER.path',
  //     path: 'ADMIN_ROUTER.CUSTOMER.path',
  //     value: 'Đia chỉ nhân đạo',
  //     icon: ICON.MENU_ICON.GLOBE,
  //   } as ISingleMenu,
  // },
  {
    type: 'SINGLE_MENU',
    item: {
      key: ADMIN_ROUTER.CONFIG.path,
      path: ADMIN_ROUTER.CONFIG.path,
      value: 'Thiết lập',
      icon: ICON.MENU_ICON.CONFIG,
    } as ISingleMenu,
  },
  {
    type: 'SINGLE_MENU',
    item: {
      key: ADMIN_ROUTER.NEWS.path,
      path: ADMIN_ROUTER.NEWS.path,
      value: 'Tin tức',
      icon: ICON.MENU_ICON.NOTIFICATION,
    } as ISingleMenu,
  },
  {
    type: 'SINGLE_MENU',
    item: {
      key: ADMIN_ROUTER.ACCOUNT.path,
      path: ADMIN_ROUTER.ACCOUNT.path,
      value: 'Tài khoản',
      icon: ICON.MENU_ICON.ACCOUNT,
    } as ISingleMenu,
  },
  // {
  //   type: 'SUB_MENU',
  //   item: {
  //     key: 'product',
  //     value: 'Sản phẩm',
  //     icon: ICON.MENU_ICON.PRODUCT,
  //     listChild: [
  //       {
  //         key: 'ADMIN_ROUTER.PRODUCT_LIST.path',
  //         path: 'ADMIN_ROUTER.PRODUCT_LIST.path',
  //         value: 'Sản phẩm',
  //       },
  //     ],
  //   } as ISubMenu,
  // },
]

// export const dataMenuEnterPrise: IDataMenu[] = [
//   {
//     type: 'SINGLE_MENU',
//     item: {
//       key: '001',
//       path: 'ENTERPRISE_ROUTER.DASHBOARD.path',
//       value: 'Tổng Quan',
//       ICON: ICON.MENU_ICON.DASHBOARD,
//     } as ISingleMenu,
//   },
// ]

export const dataMenuEnterPrise: IDataMenu[] = [
  {
    type: 'SINGLE_MENU',
    item: {
      key: ADMIN_ROUTER.DASHBOARD.path,
      path: ADMIN_ROUTER.DASHBOARD.path,
      value: 'Tổng quan',
      icon: ICON.MENU_ICON.DASHBOARD,
    } as ISingleMenu,
  },
  {
    type: 'SINGLE_MENU',
    item: {
      key: ADMIN_ROUTER.CUSTOMER.path,
      path: ADMIN_ROUTER.CUSTOMER.path,
      value: 'Danh sách người dùng',
      icon: ICON.MENU_ICON.CUSTOMER,
    } as ISingleMenu,
  },
  {
    type: 'SINGLE_MENU',
    item: {
      key: ADMIN_ROUTER.POST.path,
      path: ADMIN_ROUTER.POST.path,
      value: 'Tin đăng',
      count: AppStore.countPost,
      icon: ICON.MENU_ICON.LETTERS,
    } as ISingleMenu,
  },
  {
    type: 'SINGLE_MENU',
    item: {
      key: ADMIN_ROUTER.REQUEST_SUPPORT.path,
      path: ADMIN_ROUTER.REQUEST_SUPPORT.path,
      value: 'Ủng hộ',
      icon: ICON.MENU_ICON.APPROVE,
      count: AppStore.countRequestSupport,
    } as ISingleMenu,
  },
  // {
  //   type: 'SINGLE_MENU',
  //   item: {
  //     key: 'ADMIN_ROUTER.CUSTOMER.path',
  //     path: 'ADMIN_ROUTER.CUSTOMER.path',
  //     value: 'Đia chỉ nhân đạo',
  //     icon: ICON.MENU_ICON.GLOBE,
  //   } as ISingleMenu,
  // },
  {
    type: 'SINGLE_MENU',
    item: {
      key: ADMIN_ROUTER.CONFIG.path,
      path: ADMIN_ROUTER.CONFIG.path,
      value: 'Thiết lập',
      icon: ICON.MENU_ICON.CONFIG,
    } as ISingleMenu,
  },
  {
    type: 'SINGLE_MENU',
    item: {
      key: ADMIN_ROUTER.NEWS.path,
      path: ADMIN_ROUTER.NEWS.path,
      value: 'Tin tức',
      icon: ICON.MENU_ICON.NOTIFICATION,
    } as ISingleMenu,
  },
  // {
  //   type: 'SINGLE_MENU',
  //   item: {
  //     key: ADMIN_ROUTER.ACCOUNT.path,
  //     path: ADMIN_ROUTER.ACCOUNT.path,
  //     value: 'Tài khoản',
  //     icon: ICON.MENU_ICON.ACCOUNT,
  //   } as ISingleMenu,
  // },
  // {
  //   type: 'SUB_MENU',
  //   item: {
  //     key: 'product',
  //     value: 'Sản phẩm',
  //     icon: ICON.MENU_ICON.PRODUCT,
  //     listChild: [
  //       {
  //         key: 'ADMIN_ROUTER.PRODUCT_LIST.path',
  //         path: 'ADMIN_ROUTER.PRODUCT_LIST.path',
  //         value: 'Sản phẩm',
  //       },
  //     ],
  //   } as ISubMenu,
  // },
]

const MenuComponent: React.FC<{ data: IDataMenu[] }> = observer(({ data }) => {
  const openKey = (dataMenu: IDataMenu[]): { openKey: string; key: string } => {
    const { pathname } = window.location
    let result: { openKey: string; key: string } = { openKey: '', key: '' }

    dataMenu.forEach((value) => {
      const itemSingle = value.item as ISingleMenu
      const itemSub = value.item as ISubMenu
      const itemGroup = value.item as IGroupMenu

      const getResult = (item: ISingleMenu): boolean => {
        if (item.path.split('?')[0].split('/')[1] === pathname.split('/')[1]) {
          result.key = item.key
          return true
        }
        return false
      }

      if (value.type === 'SINGLE_MENU') {
        if (getResult(itemSingle)) result.openKey = itemSingle.key
      } else if (value.type === 'SUB_MENU') {
        itemSub.listChild.forEach((value1) => {
          if (getResult(value1)) result.openKey = itemSub.key
        })
      } else {
        itemGroup.listChild.forEach((value1) => {
          let check: boolean = false
          value1.listChild.forEach((value2) => {
            if (getResult(value2)) check = true
          })
          if (check) result.openKey = itemGroup.key
        })
      }
    })
    return result
  }

  const renderMenuItem = (value: IDataMenu): React.ReactNode => {
    const itemSingle: ISingleMenu = value.item as ISingleMenu
    const itemSubMenu: ISubMenu = value.item as ISubMenu
    const itemGroup: IGroupMenu = value.item as IGroupMenu

    const renderItemSingleMenu = (item: ISingleMenu): React.ReactNode => {
      return (
        <Menu.Item key={item.key} icon={item.icon}>
          <div className={'flex justify-between items-center'}>
            <Link to={item.path}>{item.value}</Link>
            {item.key === ADMIN_ROUTER.POST.path ? <Badge count={AppStore.countPost} size={'small'} /> : null}
            {item.key === ADMIN_ROUTER.REQUEST_SUPPORT.path ? (
              <Badge count={AppStore.countRequestSupport} size={'small'} />
            ) : null}
          </div>
        </Menu.Item>
      )
    }

    if (value.type === 'SINGLE_MENU') return renderItemSingleMenu(itemSingle)
    else if (value.type === 'SUB_MENU')
      return (
        <Menu.SubMenu key={itemSubMenu.key} icon={itemSubMenu.icon} title={itemSubMenu.value}>
          {itemSubMenu.listChild.map((value1) => renderItemSingleMenu(value1))}
        </Menu.SubMenu>
      )
    else
      return (
        <Menu.SubMenu key={itemGroup.key} icon={itemGroup.icon} title={itemGroup.value}>
          {itemGroup.listChild.map((value1) => {
            return (
              <Menu.ItemGroup key={value1.key} title={value1.title}>
                {value1.listChild.map((value2) => renderItemSingleMenu(value2))}
              </Menu.ItemGroup>
            )
          })}
        </Menu.SubMenu>
      )
  }

  return (
    <div className={'menu'}>
      <MenuStyles
        theme="light"
        mode="inline"
        defaultSelectedKeys={[openKey(data).key]}
        defaultOpenKeys={[openKey(data).openKey]}
      >
        {data.map((value) => renderMenuItem(value))}
      </MenuStyles>
    </div>
  )
})

const MenuStyles = styled(Menu)`
  font-family: OpenSans !important;
`

export default MenuComponent
