import React, { useEffect, useState } from 'react'
import { PageHeader, Spin, Tabs } from 'antd'
import { observer } from 'mobx-react'
import store from './CustomerDetailStore'
import FUNCTION from '../../../services/Functions'
import history from '../../../services/history'
import InfoCustomer from './InfoCustomer'
import ListPost from './ListPost'
import ListDonate from './ListDonate'

const CustomerDetail = observer(() => {
  useEffect(() => {
    store.getDetailCustomer(FUNCTION.GetId())
  }, [])

  const [activeKey, setActiveKey] = useState<string | undefined>(FUNCTION.GetTabsUrl() || 'tab1')

  return (
    <Spin spinning={store.loading}>
      <PageHeader title={'Chi tiết người dùng'} onBack={(e) => history.goBack()} />

      <div className={'box'}>
        <Tabs
          onChange={(tab) => {
            FUNCTION.SetSearchPrams('tabs', tab)
            setActiveKey(tab)
          }}
          activeKey={activeKey}
        >
          <Tabs.TabPane key={'tab1'} tab={<div className={'text-base'}>Thông tin cá nhân</div>}>
            <InfoCustomer />
          </Tabs.TabPane>
          <Tabs.TabPane key={'tab2'} tab={<div className={'text-base'}>Danh sách bài đăng</div>}>
            <ListPost />
          </Tabs.TabPane>
          <Tabs.TabPane key={'tab3'} tab={<div className={'text-base'}>Danh sách ủng hộ</div>}>
            <ListDonate />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </Spin>
  )
})

export default React.memo(CustomerDetail)
