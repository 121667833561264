import { makeAutoObservable } from 'mobx'
import { IResDataAddress, IResDataBank } from './SelectAddressInterfaces'
import { getListBankAPI, getListDistrictAPI, getListProvincesAPI, getListWardAPI } from './SelectAddressAPI'

class SelectAddressStore {
  loading = {
    getProvince: false,
    getDistrict: false,
    getWard: false,
    getBank: false,
  }

  listProvince: IResDataAddress[] = []
  listDistrict: IResDataAddress[] = []
  listWard: IResDataAddress[] = []
  listBank: IResDataBank[] = []

  constructor() {
    makeAutoObservable(this)
  }

  async getListProvince() {
    try {
      this.loading.getProvince = true
      const res = await getListProvincesAPI()
      if (res.body.status) {
        this.listProvince = res.body.data
      }
    } catch (e) {
      console.error(e)
    } finally {
      this.loading.getProvince = false
    }
  }

  async getListDistrict(provinceId: number) {
    try {
      this.loading.getDistrict = true
      const res = await getListDistrictAPI(provinceId)
      if (res.body.status) {
        this.listDistrict = res.body.data
      }
    } catch (e) {
      console.error(e)
    } finally {
      this.loading.getDistrict = false
    }
  }

  async getListWard(districtId: number) {
    try {
      this.loading.getWard = true
      const res = await getListWardAPI(districtId)
      if (res.body.status) {
        this.listWard = res.body.data
      }
    } catch (e) {
      console.error(e)
    } finally {
      this.loading.getWard = false
    }
  }

  async getListBank() {
    try {
      this.loading.getBank = true
      const res = await getListBankAPI()
      if (res.body.status) {
        this.listBank = res.body.data
      }
    } catch (e) {
      console.error(e)
    } finally {
      this.loading.getBank = false
    }
  }
}

const store = new SelectAddressStore()

export default store
