import { makeAutoObservable } from 'mobx'
import { IPayloadListDonator, IResDataDonator } from '../PostInterface'
import { getListDonatorInPostAPI } from '../PostAPI'
import CONSTANCE from '../../../services/Constance'

class ListDonatorStore {
  loading: boolean = false
  payload: IPayloadListDonator = {
    page: CONSTANCE.DEFAULT_PAGE,
    limit: CONSTANCE.LIMIT,
  }
  listDonator: IResDataDonator[] = []
  total: number = 0

  constructor() {
    makeAutoObservable(this)
  }

  async getListDonator(id: number) {
    try {
      this.loading = true
      const res = await getListDonatorInPostAPI(id, { ...this.payload, search: this.payload.search?.trim() })
      if (res.body.status) {
        this.listDonator = res.body.data
        this.total = res.body.paging.totalItemCount
      }
    } catch (e) {
      console.error(e)
    } finally {
      this.loading = false
    }
  }
}

const store = new ListDonatorStore()

export default store
