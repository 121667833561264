import { Baservices, IApiResponse } from '../../services/Basevices'
import { IResBody } from '../../services/Interfaces'

export interface IResCountMenu extends IResBody {
  data: number
}

export const getCountMenu = (type: number): Promise<IApiResponse<IResCountMenu>> => {
  return Baservices.getMethod(`/web/post/countPost`, { type: type })
}
