import React, { useEffect, useRef } from 'react'
import { Affix, Button, Col, DatePicker, Input, PageHeader, Popconfirm, Row, Table } from 'antd'
import RangePickerHoc from '../../commons/HOC/RangePickerHOC'
import TableHoc from '../../commons/HOC/TableHOC'
import { IColumn } from '../../services/Interfaces'
import { observer } from 'mobx-react'
import store from './AccountStore'
import CONSTANCE from '../../services/Constance'
import SelectTypeAccount, { RenderTypeAccount } from '../select/SelectTypeAccount'
import { RenderStatus } from '../select/SelectStatusComponent'
import { Moment } from '../../services/Moment'
import ICON from '../../commons/icon/Icon'
import FUNCTION from '../../services/Functions'
import AddUpdateAccount from './component/AddUpdateAccount'
import { IResDataAccount } from './AccountInterface'

const columns: IColumn[] = [
  {
    title: 'STT',
    key: 'STT',
    dataIndex: 'STT',
    width: 100,
    align: 'center',
    render: (STT: number, row, index) => <div>{STT}</div>,
  },
  {
    title: 'Số điện thoại',
    key: 'phone',
    dataIndex: 'phone',
    render: (phone: string, row, index) => <div>{phone}</div>,
  },
  {
    title: 'Họ tên',
    key: 'name',
    dataIndex: 'name',
    render: (name: string, row, index) => <div>{name}</div>,
  },
  {
    title: 'Vai trò',
    key: 'role',
    dataIndex: 'role',
    render: (role: number, row, index) => (
      <div>
        <div>{RenderTypeAccount(role)}</div>
        <div className={'text-xs mt-2 text-rose-600'}>
          {row.DFProvince.name}
          {row.DFDistrict ? ' - ' + row.DFDistrict.name : ''}
          {row.DFWard ? ' - ' + row.DFWard.name : ''}
        </div>
      </div>
    ),
  },
  {
    title: 'Trạng thái',
    key: 'status',
    dataIndex: 'status',
    render: (status: number, row, index) => <div>{RenderStatus(status)}</div>,
  },
  {
    title: 'Ngày tạo',
    key: 'create_at',
    dataIndex: 'create_at',
    render: (create_at: string, row, index) => <div>{Moment.getDate(create_at)}</div>,
  },
]
const Admincolumns: IColumn[] = [
  {
    title: 'STT',
    key: 'STT',
    dataIndex: 'STT',
    width: 100,
    align: 'center',
    render: (STT: number, row, index) => <div>{STT}</div>,
  },
  {
    title: 'Số điện thoại',
    key: 'phone',
    dataIndex: 'phone',
    render: (phone: string, row, index) => <div>{phone}</div>,
  },
  {
    title: 'Họ tên',
    key: 'name',
    dataIndex: 'name',
    render: (name: string, row, index) => <div>{name}</div>,
  },
  {
    title: 'Vai trò',
    key: 'role',
    dataIndex: 'role',
    render: (role: number, row, index) => (
      <div>
        <div>{RenderTypeAccount(role)}</div>
        <div className={'text-xs mt-2 text-rose-600'}>
          {row.DFProvince.name}
          {row.DFDistrict ? ' - ' + row.DFDistrict.name : ''}
          {row.DFWard ? ' - ' + row.DFWard.name : ''}
        </div>
      </div>
    ),
  },
  {
    title: 'Trạng thái',
    key: 'status',
    dataIndex: 'status',
    render: (status: number, row, index) => <div>{RenderStatus(status)}</div>,
  },
  {
    title: 'Ngày tạo',
    key: 'create_at',
    dataIndex: 'create_at',
    render: (create_at: string, row, index) => <div>{Moment.getDate(create_at)}</div>,
  },
  {
    title: 'Thao tác',
    key: 'option',
    dataIndex: 'option',
    align: 'center',
    render: (option, row, index) => <RenderOption row={row} />,
  },
]

const RenderOption = (props: { row: IResDataAccount }) => {
  const modalUpdateRef = useRef<Function>()
  return (
    <div>
      <Popconfirm
        title={'Bạn có muốn xóa tài khoản này ra khỏi hệ thống'}
        placement={'topLeft'}
        okText={'Xóa'}
        okButtonProps={{
          danger: true,
        }}
        cancelButtonProps={{
          type: 'primary',
        }}
        onConfirm={(e) => store.deleteAccount(props.row.id)}
      >
        <span className={'mr-2 icon-delete'}>{ICON.BUTTON.DELETE}</span>
      </Popconfirm>
      <span className={'icon-edit'} onClick={() => modalUpdateRef.current && modalUpdateRef.current(props.row)}>
        {ICON.BUTTON.EDIT}
      </span>

      <AddUpdateAccount modal={(fn) => (modalUpdateRef.current = fn)} type={'UPDATE'} />
    </div>
  )
}

const Account = observer(() => {
  const modalAddRef = useRef<Function>()
  useEffect(() => {
    store.loading.getList = true
    const idTimeout = setTimeout(() => {
      store.getListAccount()
      store.getUserInfo()
    }, 500)
    return () => clearTimeout(idTimeout)
  }, [
    store.payload.status,
    store.payload.page,
    store.payload.role,
    store.payload.search,
    store.payload.startDate,
    store.payload.endDate,
  ])

  useEffect(() => {
    return () => {
      store.payload = {
        page: CONSTANCE.DEFAULT_PAGE,
        limit: CONSTANCE.LIMIT,
      }
    }
  }, [])

  return (
    <div>
      <PageHeader
        title={'Quản lý tài khoản'}
        extra={
          FUNCTION.GetUserInfo().role === CONSTANCE.ACCOUNT.ADMIN && (
            <Button type={'primary'} onClick={() => modalAddRef.current && modalAddRef.current()}>
              Thêm mới
            </Button>
          )
        }
      />

      <Affix offsetTop={CONSTANCE.OFFSET_TOP}>
        <div className={'box'}>
          <Row gutter={[16, 4]}>
            <Col lg={6} md={6} sm={24}>
              <Input
                placeholder={'Nhập tên hoặc số điện thoại'}
                allowClear={true}
                onChange={(event) => {
                  store.payload.search = event.target.value
                  store.payload.page = CONSTANCE.DEFAULT_PAGE
                }}
              />
            </Col>
            <Col lg={6} md={6} sm={24}>
              <SelectTypeAccount
                onChange={(value) => {
                  store.payload.role = value
                  store.payload.page = CONSTANCE.DEFAULT_PAGE
                }}
              />
            </Col>
            <Col lg={6} md={6} sm={24}>
              <RangePickerHoc
                onChange={(stringDate) => {
                  store.payload.page = CONSTANCE.DEFAULT_PAGE
                  store.payload.startDate = stringDate[0]
                  store.payload.endDate = stringDate[1]
                }}
              >
                <DatePicker.RangePicker />
              </RangePickerHoc>
            </Col>
            <Col lg={6} md={6} sm={24}>
              <div className={'mt-1 flex flex-row-reverse'}>Kết quả lọc: {store.total}</div>
            </Col>
          </Row>
        </div>
      </Affix>

      <div className={'box'}>
        <TableHoc>
          <Table
            columns={FUNCTION.GetUserInfo().role === CONSTANCE.ACCOUNT.ADMIN ? Admincolumns : columns}
            loading={store.loading.getList}
            dataSource={store.listAccount.map((value, index) => {
              return { ...value, STT: FUNCTION.GetIndexTable(store.payload.page, index), key: value.id }
            })}
            pagination={{
              onChange: (page) => (store.payload.page = page),
              total: store.total,
              current: store.payload.page,
            }}
          />
        </TableHoc>
      </div>

      <AddUpdateAccount
        type={'ADD'}
        modal={(fn) => {
          modalAddRef.current = fn
        }}
      />
    </div>
  )
})

export default React.memo(Account)
