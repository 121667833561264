import React, { useEffect, useState } from 'react'
import { DatePicker, Divider, Form, Input, Modal } from 'antd'
import ModalHoc from '../../../commons/HOC/ModalHOC'
import LayoutForm from '../../../commons/layout-form/LayoutForm'
import { IReqRequestSupport, IResDataRequestSupport } from '../RequestSupportInterfaces'
import { SelectStatusApproveRequestSupport } from '../../select/SelectStatusRequestSupport'
import UploadComponent, { IFile } from '../../../commons/upload-file/UploadComponent'
import SelectCategoryComponent from '../../select/SelectCategoryComponent'
import { useForm } from 'antd/es/form/Form'
import moment from 'moment'
import CONSTANCE from '../../../services/Constance'
import store from '../RequestSupportStore'
import FUNCTION from '../../../services/Functions'
import RenderVideoComponent from '../../../commons/render-video/RenderVideoComponent'
import detailSupportStore from './DetailSupportStore'

interface IProps {
  openModal?: (fn: Function) => any
  closeModal?: () => any
}

interface IForm {
  id: number
  name: string
  form_support: number[]
  note: string
  title: string
  content: string
  image: IFile[]
  video: IFile[]
  create_at: moment.Moment
  receiver: string
  implement_status: number
  status: number
  post_name: string
}

const UpdateRequestSupport: React.FC<IProps> = ({ openModal, closeModal }) => {
  const [visible, setVisible] = useState<boolean>(false)
  const [form] = useForm()

  const [urlVideo, setUrlVideo] = useState<string | undefined>(undefined)
  const [isPostDone, setIsPostDone] = useState<boolean>(false)

  const onOpenModal = (record: IResDataRequestSupport) => {
    setVisible(true)
    const dataForm: IForm = {
      id: record.id,
      name: record.name,
      form_support: record.form_support.map((value) => value.id),
      note: record.note,
      content: record.content,
      image: FUNCTION.SplitMedia(record.DonateImages).image.map((value) => {
        return {
          url: value.url,
          name: value.media_path,
          status: 'done',
          uid: value.media_path,
          response: {
            data: {
              url: value.url,
              filename: value.media_path,
            },
          },
        }
      }),
      video: FUNCTION.SplitMedia(record.DonateImages).video.map((value) => {
        return {
          url: value.url,
          name: value.media_path,
          status: 'done',
          uid: value.media_path,
          response: {
            data: {
              url: value.url,
              filename: value.media_path,
            },
          },
        }
      }),
      create_at: moment(record.create_at),
      receiver: record.DonateRequest.name,
      title: record.title,
      status: record.status,
      implement_status: record.implement_status,
      post_name: record.DonateRequest.title,
    }

    form.setFieldsValue(dataForm)
    if (FUNCTION.SplitMedia(record.DonateImages).video.length > 0) {
      setUrlVideo(FUNCTION.SplitMedia(record.DonateImages).video[0].url)
    }
    if (
      record.status === CONSTANCE.REQUEST_SUPPORT.STATUS_APPROVE.DONE ||
      record.status === CONSTANCE.REQUEST_SUPPORT.STATUS_APPROVE.COMPLETE
    ) {
      setIsPostDone(true)
    }
  }

  const onCloseModal = () => {
    setVisible(false)
    closeModal && closeModal()
    setIsPostDone(false)
  }

  useEffect(() => {
    openModal && openModal(onOpenModal)
  }, [])

  const onFinish = async (values: IForm) => {
    const arrImage: {
      url: string
      type: number
    }[] = values.image
      ? values.image.map((value) => {
          return {
            url: value.response?.data.filename as string,
            type: CONSTANCE.MEDIA.IMAGES,
          }
        })
      : []

    const arrVideo: {
      url: string
      type: number
    }[] = values.video
      ? values.video.map((value) => {
          return {
            url: value.response?.data.filename as string,
            type: CONSTANCE.MEDIA.VIDEO,
          }
        })
      : []

    const reqData: IReqRequestSupport = {
      form_support: values.form_support,
      note: values.note,
      content: values.content,
      name: values.name,
      title: values.title,
      array_image: [...arrImage, ...arrVideo],
      end_date: moment(values.create_at).toISOString(),
    }

    if (
      detailSupportStore.detailRequestSupport?.status !== CONSTANCE.REQUEST_SUPPORT.STATUS_APPROVE.DONE &&
      detailSupportStore.detailRequestSupport?.status !== CONSTANCE.REQUEST_SUPPORT.STATUS_APPROVE.COMPLETE
    ) {
      delete reqData.end_date
    }

    const result = await store.putRequestSupport(form.getFieldValue('id'), reqData)
    if (result) {
      onCloseModal()
      store.getListRequestSupport()
    }
  }

  return (
    <ModalHoc>
      <Modal
        title={'Sửa yêu cầu từ thiện'}
        visible={visible}
        width={800}
        onCancel={onCloseModal}
        onOk={(e) => form.submit()}
      >
        <Form layout={'vertical'} form={form} onFinish={onFinish}>
          <LayoutForm labelGroup={'Thông tin người ủng hộ'}>
            <Form.Item
              label={'Họ tên người ủng hộ'}
              name={'name'}
              rules={[{ required: true, whitespace: true, message: 'Vui lòng nhập tên người ủng hộ' }]}
            >
              <Input placeholder={'Nhập tên người ủng hộ ủng hộ'} />
            </Form.Item>

            <Form.Item label={'Họ tên người nhận hỗ trợ'} name={'receiver'}>
              <Input placeholder={'Nhập họ tên người nhận hỗ trợ'} disabled={true} />
            </Form.Item>

            <Form.Item
              label={'Thời gian thực hiện'}
              name={'create_at'}
              rules={[{ required: true, message: 'Vui lòng chọn ngày' }]}
            >
              <DatePicker style={{ width: '100%' }} format={'DD/MM/YYYY'} disabled={isPostDone ? false : true} />
            </Form.Item>

            <Form.Item label={'Bài đăng'} name={'post_name'}>
              <Input placeholder={'Chọn bài đăng'} disabled={true} />
            </Form.Item>

            <Form.Item
              label={'Lời nhắn'}
              name={'note'}
              rules={[{ required: true, whitespace: true, message: 'Vui lòng nhập lời nhắn' }]}
            >
              <Input placeholder={'Nhập tiêu đề'} />
            </Form.Item>

            <Form.Item label={'Tiêu đề'} name={'title'} rules={[{ required: true, message: 'Vui lòng nhập tiêu đề' }]}>
              {/* <Input.TextArea rows={5} placeholder={'Nhập tiêu đề'} /> */}
              <Input placeholder={'Nhập tiêu đề'} />
            </Form.Item>

            {isPostDone && (
              <Form.Item label={'Nội dung'} name={'content'}>
                <Input.TextArea rows={5} placeholder={'Nhập nội dung'} />
              </Form.Item>
            )}
          </LayoutForm>

          <Divider />

          <LayoutForm labelGroup={'Thông tin ủng hộ'}>
            <SelectCategoryComponent
              placeholder={'Chọn nhu cầu hỗ trợ'}
              type={'SUPPORT_NEEDS'}
              label={'Hình thức ủng hộ'}
              mode={'multiple'}
              name={'form_support'}
            />
            {/*<SelectStatusRequestSupport*/}
            {/*  placeholder={'Chọn trạng thái thực hiện'}*/}
            {/*  label={'Trạng thái thực hiện'}*/}
            {/*  name={'implement_status'}*/}
            {/*  disable={true}*/}
            {/*/>*/}
            <SelectStatusApproveRequestSupport
              placeholder={'Chọn trạng liên hệ'}
              label={'Trạng thái liên hệ'}
              name={'status'}
              disable={true}
              displayStatusPending={true}
            />
          </LayoutForm>
          {isPostDone && (
            <div>
              <Divider />

              <LayoutForm labelGroup={'Hình ảnh thực tế'}>
                <UploadComponent name={'image'} limit={5} />
              </LayoutForm>

              <Divider />

              <LayoutForm labelGroup={'Video thực tế'}>
                {!urlVideo && (
                  <UploadComponent
                    logger={(data) => {
                      if (data.length > 0) {
                        setUrlVideo(data[0].response?.data.url)
                      }
                    }}
                    limit={1}
                    name={'video'}
                    placeholder={'Tải video'}
                    type={'picture-card'}
                    accept={'.mp4'}
                  />
                )}

                {urlVideo && (
                  <RenderVideoComponent
                    src={urlVideo}
                    onClose={() => {
                      setUrlVideo(undefined)
                      form.setFieldsValue({ video: undefined })
                    }}
                  />
                )}
              </LayoutForm>
            </div>
          )}
        </Form>
      </Modal>
    </ModalHoc>
  )
}

export default UpdateRequestSupport
