import { BASE_URL } from './Basevices'

const CONSTANCE = {
  REG: {
    PASS: /^[a-zA-Z0-9!@#$%^&*]*$/,
    USERNAME: /^[a-zA-Z0-9]+$/,
    PHONE:
      /(^((09|03|07|08|05)+([0-9]{8}))$)|(^((0296|0297|0254|0291|0209|0222|0275|0274|0256|0271|0252|0290|0206|0292|0236|0215|0262|0261|0251|0277|0269|0219|0226|024|0239|0220|0225|0293|0218|028|0221|0258|0297|0260|0213|0205|0214|0263|0272|0228|0238|0229|0259|0210|0257|0232|0235|0255|0203|0233|0299|0212|0276|0227|0208|0237|0234|0273|0294|0207|0270|0211|0216)+([0-9]{8}))$)/,
    NUMBER: /^[0-9]+$/,
    NAME: /^[\D]{0,50}$/,
    CODE: /^[a-zA-Z0-9]*$/,
    UNICODE:
      /^[aAàÀảẢãÃáÁạẠăĂằẰẳẲẵẴắẮặẶâÂầẦẩẨẫẪấẤậẬbBcCdDđĐeEèÈẻẺẽẼéÉẹẸêÊềỀểỂễỄếẾệỆ fFgGhHiIìÌỉỈĩĨíÍịỊjJkKlLmMnNoOòÒỏỎõÕóÓọỌôÔồỒổỔỗỖốỐộỘơƠờỜởỞỡỠớỚợỢpPqQrRsStTu UùÙủỦũŨúÚụỤưƯừỪửỬữỮứỨựỰvVwWxXyYỳỲỷỶỹỸýÝỵỴzZ0-9-]*$/,
    UNICODE_NOT_NUM:
      /^[aAàÀảẢãÃáÁạẠăĂằẰẳẲẵẴắẮặẶâÂầẦẩẨẫẪấẤậẬbBcCdDđĐeEèÈẻẺẽẼéÉẹẸêÊềỀểỂễỄếẾệỆ fFgGhHiIìÌỉỈĩĨíÍịỊjJkKlLmMnNoOòÒỏỎõÕóÓọỌôÔồỒổỔỗỖốỐộỘơƠờỜởỞỡỠớỚợỢpPqQrRsStTu UùÙủỦũŨúÚụỤưƯừỪửỬữỮứỨựỰvVwWxXyYỳỲỷỶỹỸýÝỵỴzZ-]*$/,
    EMAIL: /^[0-9]*@[a-z]*\.[a-z]{0,3}$/,
  },
  LIMIT: 12,
  DEFAULT_PAGE: 1,
  ACCOUNT: {
    ADMIN: 1, // admin
    OFFICER_PROVINCE: 2, // cán bộ địa phương cấp tỉnh
    OFFICER_DISTRICT: 3, // cán bộ địa phương cấp huyện
    OFFICER_WARD: 4, // cán bộ địa phương cấp xã
    CUSTOMER: 5, // khách hàng
  },
  CATEGORY: {
    CHARITY: 1, // đối tượng
    SUPPORT_NEEDS: 2, // nhu cầu hỗ trợ
  },
  POST: {
    POST_STATUS: {
      DENY: 0, //// Từ chối
      USER_POST: 1, //// Khách HÀNG / NGƯỜI DÙNG ĐĂNG TIN
      DISTRICT_CONFIRM: 2, //// HUYỆN ĐĂNG
      DONE: 3, //// TỈNH DUYỆT
    },
    REQUEST_EDIT: {
      NOT_REQUEST: 0,
      PROVINCE_REQUEST_UPDATE: 1,
      DISTRICT_REQUEST_UPDATE: 2,
    },
  },
  STATUS: {
    ACTIVE: 1,
    INACTIVE: 0,
  },
  GENDER: {
    MALE: 1,
    FEMALE: 2,
  },
  OFFSET_TOP: 35,
  NAME_UPLOAD: {
    IMAGE: 'image',
    VIDEO: 'video',
  },
  PATH_UPLOAD: {
    IMAGE: `${BASE_URL}/file/upload-single/1`,
    VIDEO: `${BASE_URL}/file/upload-single/2`,
  },
  SIZE_UPLOAD: {
    IMAGE: 7,
    VIDEO: 7,
  },
  DURATION: {
    MIN: 10,
    MAX: 600,
  },
  MEDIA: {
    IMAGES: 1,
    VIDEO: 2,
  },
  SCROLL_FORM: {
    behavior: 'smooth',
    scrollMode: 'always',
    block: 'center',
  },
  REQUEST_SUPPORT: {
    STATUS: {
      PAUSE: 0, //// TẠM DỪNG
      PENDING: 1, //// ĐANG THỰC HIỆN
      DONE: 2, //// HOÀN THÀNH
    },
    STATUS_APPROVE: {
      DENY: 0, //// Từ chối
      USER_DONATE: 1, //// Khách HÀNG / NGƯỜI DÙNG ỦNG HỘ
      DISTRICT_APPROVE: 2, //// HUYỆN APPROVE
      DONE: 3, //// TỈNH DUYỆT
      COMPLETE: 4, // Chỉnh sửa
    },
  },
  NEWS: {
    TYPE_BANNER: {
      BANNER: { value: 1, name: 'Banner', color: 'red' },
      RULE: { value: 2, name: 'Điều khoản sử dụng', color: 'blue' },
    },
    STATUS_BANNER: {
      POST: { value: 1, name: 'Đăng bài', color: 'blue' },
      DRAFT: { value: 2, name: 'Lưu nháp', color: 'red' },
    },
  },
}

export default CONSTANCE
