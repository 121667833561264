import React, { useEffect, useRef } from 'react'
import { Button, Col, DatePicker, Divider, Input, Popconfirm, Row, Table } from 'antd'
import RangePickerHoc from '../../../commons/HOC/RangePickerHOC'
import TableHoc from '../../../commons/HOC/TableHOC'
import { IColumn } from '../../../services/Interfaces'
import ICON from '../../../commons/icon/Icon'
import store from './GroupStore'
import { observer } from 'mobx-react'
import CONSTANCE from '../../../services/Constance'
import AddUpdateGroup from './AddUpdateGroup'
import FUNCTION from '../../../services/Functions'
import { IResDataGroup } from './GroupInterface'
import PopconfirmHoc from '../../../commons/HOC/PopconfirmHOC'
import { Moment } from '../../../services/Moment'

const columns: IColumn[] = [
  {
    title: 'STT',
    key: 'STT',
    dataIndex: 'STT',
    width: 80,
    align: 'center',
    render: (STT: number, row, index) => <div>{STT}</div>,
  },
  {
    title: 'Tên phân nhóm',
    key: 'name',
    dataIndex: 'name',
    render: (name: string, row, index) => <div>{name}</div>,
  },
  {
    title: 'Mô tả',
    key: 'description',
    dataIndex: 'description',
    render: (description: string, row, index) => <div>{description}</div>,
  },
  {
    title: 'Ngày tạo',
    key: 'create_at',
    dataIndex: 'create_at',
    render: (create_at: string, row, index) => <div>{Moment.getDate(create_at)}</div>,
  },
  {
    title: '',
    key: 'id',
    dataIndex: 'id',
    align: 'center',
    render: (id: number, row, index) => <RenderOption row={row} />,
  },
]

const RenderOption = (props: { row: IResDataGroup }) => {
  const modalUpdateRef = useRef<Function>()

  return (
    <div>
      <PopconfirmHoc>
        <Popconfirm
          title={'Bạn có chắc muốn xóa phân nhóm này không?'}
          onConfirm={(e) => store.deleteGroup(props.row.id)}
        >
          <span className={'mr-2 icon-delete'}>{ICON.BUTTON.DELETE}</span>
        </Popconfirm>
      </PopconfirmHoc>

      <span className={'icon-edit'} onClick={(event) => modalUpdateRef.current && modalUpdateRef.current(props.row)}>
        {ICON.BUTTON.EDIT}
      </span>
      <AddUpdateGroup type={'UPDATE'} openModal={(fn) => (modalUpdateRef.current = fn)} />
    </div>
  )
}

const Group = observer(() => {
  const modalAddRef = useRef<Function>()

  useEffect(() => {
    const idTimeout = setTimeout(() => {
      store.getListGroup()
    }, 500)
    return () => clearTimeout(idTimeout)
  }, [store.payload.search, store.payload.page, store.payload.endDate, store.payload.endDate])

  useEffect(() => {
    return () => {
      store.payload = {
        page: CONSTANCE.DEFAULT_PAGE,
        limit: CONSTANCE.LIMIT,
      }
    }
  }, [])

  return (
    <div>
      <Row gutter={[16, 4]}>
        <Col lg={8} md={8} sm={24}>
          <Input
            placeholder={'Tìm kiếm theo tên phân  nhóm'}
            allowClear={true}
            onChange={(event) => {
              store.payload.search = event.target.value
              store.payload.page = CONSTANCE.DEFAULT_PAGE
            }}
          />
        </Col>
        <Col lg={8} md={8} sm={24}>
          <RangePickerHoc
            onChange={(stringDate) => {
              store.payload.startDate = stringDate[0]
              store.payload.endDate = stringDate[1]
              store.payload.page = CONSTANCE.DEFAULT_PAGE
            }}
          >
            <DatePicker.RangePicker />
          </RangePickerHoc>
        </Col>
        <Col lg={8} md={8} sm={24}>
          <div className={'flex justify-end'}>
            <Button
              type={'primary'}
              icon={ICON.BUTTON.ADD}
              onClick={(event) => {
                modalAddRef.current && modalAddRef.current()
              }}
            >
              Thêm mới
            </Button>
          </div>
        </Col>
      </Row>
      <Divider />
      <div className={'mb-2 flex justify-end'}>Kết quả lọc: {store.total}</div>
      <TableHoc>
        <Table
          columns={columns}
          loading={store.loading.getList}
          dataSource={store.listGroup.map((value, index) => {
            return { ...value, key: value.id, STT: FUNCTION.GetIndexTable(store.payload.page, index) }
          })}
          pagination={{
            onChange: (page) => (store.payload.page = page),
            total: store.total,
            current: store.payload.page,
          }}
        />
      </TableHoc>
      <AddUpdateGroup type={'ADD'} openModal={(fn) => (modalAddRef.current = fn)} />
    </div>
  )
})

export default React.memo(Group)
