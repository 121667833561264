import React from 'react'
import CONSTANCE from '../../services/Constance'
import { Form, FormItemProps, Select, Tag } from 'antd'

interface IProps extends FormItemProps {
  onChange?: (values?: number) => any
  placeholder?: string
}

export const SelectGender: React.FC<IProps> = ({ onChange, placeholder, ...rest }) => {
  return (
    <Form.Item {...rest}>
      <Select
        placeholder={placeholder}
        allowClear={true}
        optionFilterProp={'children'}
        onChange={(value) => onChange && onChange(value)}
      >
        <Select.Option key={CONSTANCE.GENDER.MALE} value={CONSTANCE.GENDER.MALE}>
          Nam
        </Select.Option>
        <Select.Option key={CONSTANCE.GENDER.FEMALE} value={CONSTANCE.GENDER.FEMALE}>
          Nữ
        </Select.Option>
      </Select>
    </Form.Item>
  )
}

export const RenderGender = (values: number) => {
  if (CONSTANCE.GENDER.MALE === values) return <Tag color={'blue'}>Nam</Tag>
  if (CONSTANCE.GENDER.FEMALE === values) return <Tag color={'pink'}>Nữ</Tag>
}
