import { makeAutoObservable } from 'mobx'
import { IPayloadGroup, IReqGroup, IResDataGroup } from './GroupInterface'
import CONSTANCE from '../../../services/Constance'
import { deleteGroupAPI, getListGroupAPI, postGroupAPI, putGroupAPI } from './GroupAPI'
import { Notification } from '../../../commons/notification/Notification'

class GroupStore {
  loading = {
    getList: false,
    submit: false,
  }
  listGroup: IResDataGroup[] = []

  total: number = 0
  payload: IPayloadGroup = {
    page: CONSTANCE.DEFAULT_PAGE,
    limit: CONSTANCE.LIMIT,
  }

  listGroupFull: IResDataGroup[] = []

  constructor() {
    makeAutoObservable(this)
  }

  async getListGroup() {
    try {
      this.loading.getList = true
      const res = await getListGroupAPI({ ...this.payload, search: this.payload.search?.trim() })
      if (res.body.status) {
        this.listGroup = res.body.data
        this.total = res.body.paging.totalItemCount
      }
    } catch (e) {
      console.error(e)
    } finally {
      this.loading.getList = false
    }
  }

  async getListGroupFull() {
    try {
      this.loading.getList = true
      const res = await getListGroupAPI({ page: CONSTANCE.DEFAULT_PAGE })
      if (res.body.status) {
        this.listGroupFull = res.body.data
      }
    } catch (e) {
      console.error(e)
    } finally {
      this.loading.getList = false
    }
  }

  async postGroup(reqData: IReqGroup): Promise<boolean> {
    try {
      this.loading.submit = true
      const res = await postGroupAPI(reqData)
      if (res.body.status) {
        Notification.PushNotification('SUCCESS', 'Thêm mới phân nhóm thành công')
        setTimeout(() => {
          this.getListGroup()
        }, 500)
        return new Promise((resolve) => resolve(true))
      } else {
        return new Promise((resolve) => resolve(false))
      }
    } catch (e) {
      console.error(e)
      return new Promise((resolve) => resolve(false))
    } finally {
      this.loading.submit = false
    }
  }

  async putGroup(id: number, reqData: IReqGroup): Promise<boolean> {
    try {
      this.loading.submit = true
      const res = await putGroupAPI(id, reqData)
      if (res.body.status) {
        Notification.PushNotification('SUCCESS', 'Cập nhật phân nhóm thành công')
        setTimeout(() => {
          this.getListGroup()
        }, 500)
        return new Promise((resolve) => resolve(true))
      } else {
        return new Promise((resolve) => resolve(false))
      }
    } catch (e) {
      console.error(e)
      return new Promise((resolve) => resolve(false))
    } finally {
      this.loading.submit = false
    }
  }

  async deleteGroup(id: number): Promise<boolean> {
    try {
      const res = await deleteGroupAPI(id)
      if (res.body.status) {
        Notification.PushNotification('SUCCESS', 'Xóa phân nhóm thành công')
        setTimeout(() => {
          this.getListGroup()
        }, 500)
        return new Promise((resolve) => resolve(true))
      } else {
        return new Promise((resolve) => resolve(false))
      }
    } catch (e) {
      console.error(e)
      return new Promise((resolve) => resolve(false))
    } finally {
    }
  }
}

const store = new GroupStore()

export default store
