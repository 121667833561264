import { useEffect } from 'react'
import './App.scss'
import LayoutComponent from './modules/layout/LayoutComponent'
import { Route, Switch } from 'react-router-dom'
import Login from './modules/login/Login'
import { getToken } from './services/Basevices'
import history from './services/history'

function App() {
  useEffect(() => {
    if (!getToken()) {
      history.push('/login')
    } else {
      if (window.location.pathname === '/') {
        history.push('/dashboard')
      }
    }
  }, [])

  return (
    <div className="App">
      <Switch>
        <Route exact path={'/login'} component={Login} />
        <Route component={LayoutComponent} />
      </Switch>
    </div>
  )
}

export default App
//
