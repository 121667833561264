import { useEffect, useRef } from 'react'
import { Button, Col, DatePicker, Input, PageHeader, Row, Table, Tag } from 'antd'
import { IColumn } from '../../services/Interfaces'
import RangePickerHoc from '../../commons/HOC/RangePickerHOC'
import { IResDataRequestSupport } from './RequestSupportInterfaces'
import store from './RequestSupportStore'
import { observer } from 'mobx-react'
import CONSTANCE from '../../services/Constance'
import { Moment } from '../../services/Moment'
import FUNCTION from '../../services/Functions'
import RequestSupportDetail from './component/RequestSupportDetail'
import {
  RenderStatusApproveRequestSupport,
  SelectStatusApproveRequestSupport,
} from '../select/SelectStatusRequestSupport'
import TableHoc from '../../commons/HOC/TableHOC'
import AddRequestSupport from './component/AddRequestSupport'
import ICON from '../../commons/icon/Icon'
import history from '../../services/history'
import { ADMIN_ROUTER } from '../../router/AdminRouter'

const RequestSupport = observer(() => {
  const columns: IColumn[] = [
    {
      title: 'STT',
      key: 'STT',
      dataIndex: 'STT',
      align: 'center',
      width: 80,
      render: (STT: number, row: IResDataRequestSupport, index) => <div>{STT}</div>,
    },
    // {
    //   title: 'Tiêu đề',
    //   key: 'title',
    //   dataIndex: 'title',
    //   width: 500,
    //   render: (title, row: IResDataRequestSupport, index) => <div>{row.note || '_ _ _'}</div>,
    // },
    {
      title: 'Người từ thiện(SĐT)',
      key: 'donator',
      dataIndex: 'donator',
      render: (value, row: IResDataRequestSupport, index) => (
        <div>
          {row.name}
          <div className={'text-xs text-rose-600 mt-1'}>({row.phone})</div>
        </div>
      ),
    },
    {
      title: 'Tiêu đề tin đăng',
      key: 'DonateRequest',
      dataIndex: 'DonateRequest',
      render: (DonateRequest: { title: string; id: number }, row: IResDataRequestSupport, index) => (
        <div
          onClick={(event) => history.push(ADMIN_ROUTER.POST_DETAIL.path + `?index=${DonateRequest.id}`)}
          className={'cursor-pointer text-blue-700 hover:underline hover:underline-offset-1'}
        >
          {DonateRequest && DonateRequest.title}
        </div>
      ),
    },
    // {
    //   title: 'TT thực hiện',
    //   key: 'status',
    //   dataIndex: 'status',
    //   align: 'center',
    //   render: (status, row: IResDataRequestSupport, index) => (
    //     <div>{RenderStatusRequestSupport(row.implement_status)}</div>
    //   ),
    // },
    {
      title: 'TT liên hệ',
      key: 'status',
      dataIndex: 'status',
      width: 150,
      render: (status, row: IResDataRequestSupport, index) => (
        <div>
          {RenderStatusApproveRequestSupport(row.status)}
          <div className={'text-xs mt-2 text-rose-600'}>
            {row.district_post &&
            (row.status === CONSTANCE.REQUEST_SUPPORT.STATUS_APPROVE.DISTRICT_APPROVE ||
              row.status === CONSTANCE.REQUEST_SUPPORT.STATUS_APPROVE.USER_DONATE)
              ? 'Huyện đã liên hệ'
              : ''}
          </div>
        </div>
      ),
    },
    {
      title: 'Hình thức ủng hộ',
      key: 'category',
      dataIndex: 'category',
      render: (value, row: IResDataRequestSupport, index) => (
        <div>
          {row.form_support.map((value1) => (
            <Tag color={'blue'}>{value1.name}</Tag>
          ))}
        </div>
      ),
    },
    {
      title: 'Ngày tạo',
      key: 'create_at',
      dataIndex: 'create_at',
      align: 'center',
      render: (value, row: IResDataRequestSupport, index) => <div>{Moment.getDate(row.create_at)}</div>,
    },
    {
      title: '',
      key: 'id',
      dataIndex: 'id',
      align: 'center',
      render: (id, row: IResDataRequestSupport, index) => (
        <div
          onClick={() => {
            FUNCTION.SetSearchPrams('index', String(id))
            openModalRef.current && openModalRef.current(row)
          }}
          className={'icon-detail'}
        >
          {ICON.DETAIL}
        </div>
      ),
    },
  ]

  const openModalRef = useRef<Function>()
  const openModalAddRef = useRef<Function>()
  useEffect(() => {
    store.loading.getList = true
    const idTimeOut = setTimeout(() => {
      store.getListRequestSupport()
    }, 500)
    return () => clearTimeout(idTimeOut)
  }, [
    store.payload.page,
    store.payload.status,
    store.payload.search,
    store.payload.startDate,
    store.payload.endDate,
    store.payload.implement_status,
  ])

  useEffect(() => {
    return () => {
      store.payload = {
        page: CONSTANCE.DEFAULT_PAGE,
        limit: CONSTANCE.LIMIT,
      }
    }
  }, [])

  return (
    <div>
      <PageHeader
        title={'Quản lý ủng hộ'}
        extra={
          <Button
            onClick={(event) => openModalAddRef.current && openModalAddRef.current()}
            icon={<span className={'mr-2'}>{ICON.BUTTON.ADD}</span>}
            type={'primary'}
          >
            Thêm mới
          </Button>
        }
      />

      <div className={'box'}>
        <Row gutter={[16, 4]}>
          <Col md={8} lg={8} sm={24}>
            <Input
              placeholder={'Nhập tên tiêu đề, tên người đăng, số điện thoại'}
              onChange={(event) => {
                store.payload.search = event.target.value
                store.payload.page = CONSTANCE.DEFAULT_PAGE
              }}
            />
          </Col>
          {/*<Col md={6} lg={6} sm={24}>*/}
          {/*  <SelectStatusRequestSupport*/}
          {/*    placeholder={'Trạng thái thực hiện'}*/}
          {/*    onChange={(values) => {*/}
          {/*      store.payload.implement_status = values*/}
          {/*      store.payload.page = CONSTANCE.DEFAULT_PAGE*/}
          {/*    }}*/}
          {/*  />*/}
          {/*</Col>*/}
          <Col md={8} lg={8} sm={24}>
            <SelectStatusApproveRequestSupport
              placeholder={'Trạng thái liên hệ'}
              onChange={(values) => {
                store.payload.status = values
                store.payload.page = CONSTANCE.DEFAULT_PAGE
              }}
            />
          </Col>
          <Col md={8} lg={8} sm={24}>
            <RangePickerHoc
              onChange={(stringDate) => {
                store.payload.startDate = stringDate[0]
                store.payload.endDate = stringDate[1]
                store.payload.page = CONSTANCE.DEFAULT_PAGE
              }}
            >
              <DatePicker.RangePicker />
            </RangePickerHoc>
          </Col>
        </Row>
      </div>

      <div className={'box'}>
        <div className={'mb-1'}>Kết quả lọc {store.total}</div>
        <TableHoc>
          <Table
            columns={columns}
            loading={store.loading.getList}
            dataSource={store.listRequestSupport.map((value, index) => {
              return { ...value, STT: FUNCTION.GetIndexTable(store.payload.page, index) }
            })}
            pagination={{
              onChange: (page) => (store.payload.page = page),
              total: store.total,
              current: store.payload.page,
            }}
            // onRow={(record) => {
            //   return {
            //     onClick: () => {
            //       FUNCTION.SetSearchPrams('index', String(record.id))
            //       openModalRef.current && openModalRef.current(record)
            //     },
            //   }
            // }}
          />
        </TableHoc>
      </div>
      <RequestSupportDetail openModal={(fn) => (openModalRef.current = fn)} />
      <AddRequestSupport openModal={(fn) => (openModalAddRef.current = fn)} />
    </div>
  )
})

export default RequestSupport
