import React, { useEffect } from 'react'
import { DatePicker, Input, Table, Tag } from 'antd'
import RangePickerHoc from '../../../commons/HOC/RangePickerHOC'
import TableHoc from '../../../commons/HOC/TableHOC'
import { IColumn } from '../../../services/Interfaces'
import { RenderStatusApproveRequestSupport, RenderStatusRequestSupport } from '../../select/SelectStatusRequestSupport'
import { Moment } from '../../../services/Moment'
import ICON from '../../../commons/icon/Icon'
import { observer } from 'mobx-react'
import ListDonateStore from './ListDonateStore'
import listDonateStore from './ListDonateStore'
import CONSTANCE from '../../../services/Constance'
import FUNCTION from '../../../services/Functions'

const columns: IColumn[] = [
  {
    title: 'STT',
    key: 'STT',
    dataIndex: 'STT',
    render: (STT: number, row, index) => <div>{STT}</div>,
  },
  {
    title: 'Tiêu đề',
    key: 'content',
    dataIndex: 'content',
    render: (content: string, row, index) => <div>{content || '_ _ _'}</div>,
  },
  {
    title: 'Người nhận hỗ trợ(SĐT)',
    key: 'DonateRequest',
    dataIndex: 'DonateRequest',
    render: (DonateRequest: { name: string; phone: string }, row, index) => (
      <div>
        {DonateRequest.name} - {DonateRequest.phone}
      </div>
    ),
  },
  {
    title: 'Hình thức ủng hộ',
    key: 'form_support',
    dataIndex: 'form_support',
    render: (form_support: { name: string }[], row, index) => (
      <div>
        {form_support.map((value) => (
          <Tag color={'blue'}>{value.name}</Tag>
        ))}
      </div>
    ),
  },
  {
    title: 'TT thực hiện',
    key: 'implement_status',
    dataIndex: 'implement_status',
    render: (implement_status: number, row, index) => <div>{RenderStatusRequestSupport(implement_status)}</div>,
  },
  {
    title: 'TT phê duyệt',
    key: 'status',
    dataIndex: 'status',
    render: (status: number, row, index) => <div>{RenderStatusApproveRequestSupport(status)}</div>,
  },
  {
    title: ' Ngày thay đổi cuối',
    key: 'update_at',
    dataIndex: 'update_at',
    render: (update_at: string, row, index) => <div>{Moment.getDate(update_at)}</div>,
  },
  {
    title: 'Ngày tạo',
    key: 'create_at',
    dataIndex: 'create_at',
    render: (create_at: string, row, index) => <div>{Moment.getDate(create_at)}</div>,
  },
  {
    title: '',
    key: 'id',
    dataIndex: 'id',
    align: 'center',
    render: (value, row, index) => <div className={'icon-detail'}>{ICON.DETAIL}</div>,
  },
]

const ListDonate = observer(() => {
  useEffect(() => {
    const timeOutId = setTimeout(() => {
      ListDonateStore.setParams()
      ListDonateStore.getList()
    }, 500)

    return () => {
      clearTimeout(timeOutId)
    }
  }, [
    ListDonateStore.payload.page,
    ListDonateStore.payload.endDate,
    ListDonateStore.payload.startDate,
    ListDonateStore.payload.status,
    ListDonateStore.payload.customer_id,
    ListDonateStore.payload.is_active,
    listDonateStore.payload.search,
  ])

  useEffect(() => {
    return () => {
      ListDonateStore.payload = {
        page: CONSTANCE.DEFAULT_PAGE,
        limit: CONSTANCE.LIMIT,
        customer_id: FUNCTION.GetId(),
      }
    }
  }, [])

  return (
    <div className={'container'}>
      <div className={'grid grid-cols-2 gap-3 mb-5'}>
        <Input
          onChange={(event) => {
            ListDonateStore.payload.search = event.target.value
          }}
          placeholder={'Nhập tên tiêu đề, tên người đăng, số điện thoại người từ thiện'}
        />
        {/*<SelectStatusComponent onChange={(value) => {}} />*/}
        <RangePickerHoc
          onChange={(stringDate) => {
            ListDonateStore.payload.startDate = stringDate[0]
            ListDonateStore.payload.endDate = stringDate[1]
            ListDonateStore.payload.page = CONSTANCE.DEFAULT_PAGE
          }}
        >
          <DatePicker.RangePicker />
        </RangePickerHoc>
      </div>

      <div className={'mb-2 font-bold text-stone-500'}>Kết quả lọc: {ListDonateStore.total}</div>

      <TableHoc>
        <Table
          columns={columns}
          loading={ListDonateStore.loading}
          dataSource={ListDonateStore.listDonate.map((value, index) => {
            return { ...value, STT: FUNCTION.GetIndexTable(ListDonateStore.payload.page, index) }
          })}
          pagination={{
            onChange: (page) => {
              ListDonateStore.payload.page = page
            },
            total: ListDonateStore.total,
            current: ListDonateStore.payload.page,
          }}
        />
      </TableHoc>
    </div>
  )
})

export default ListDonate
