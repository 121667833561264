import React, { useEffect, useRef } from 'react'
import { Button, Col, Collapse, DatePicker, Input, Popconfirm, Row, Table } from 'antd'
import RangePickerHoc from '../../../commons/HOC/RangePickerHOC'
import ICON from '../../../commons/icon/Icon'
import TableHoc from '../../../commons/HOC/TableHOC'
import { observer } from 'mobx-react'
import { IColumn } from '../../../services/Interfaces'
import { Moment } from '../../../services/Moment'
import { IResDataCategory } from './CategoryInterfaces'
import storeCharity from './CategoryStoreCharity'
import storeSupportNeed from './CategoryStoreSupportNeed'
import CONSTANCE from '../../../services/Constance'
import PopconfirmHoc from '../../../commons/HOC/PopconfirmHOC'
import FUNCTION from '../../../services/Functions'
import AddUpdateCategory from './AddUpdateCategory'
import { CollapseStyles } from '../../../styles/CollapseStyles'

const CategoryItem = observer((props: { type: 1 | 2 }) => {
  const columns: IColumn[] = [
    {
      title: 'STT',
      key: 'STT',
      dataIndex: 'STT',
      align: 'center',
      width: 80,
      render: (STT, row, index) => <div>{STT}</div>,
    },
    {
      title: 'Tên danh mục',
      key: 'name',
      dataIndex: 'name',
      render: (name: string, row, index) => <div>{name ? name : '-'}</div>,
    },
    {
      title: 'Mô tả',
      key: 'description',
      dataIndex: 'description',
      render: (description: string, row, index) => <div>{description}</div>,
    },
    {
      title: 'Phân nhóm',
      key: 'Group',
      dataIndex: 'Group',
      render: (Group: { name: string }, row, index) => <div>{Group?.name ? Group?.name : ''}</div>,
    },
    {
      title: 'Ngày tạo',
      key: 'create_at',
      dataIndex: 'create_at',
      render: (create_at: string, row, index) => <div>{Moment.getDate(create_at)}</div>,
    },
    {
      title: '',
      key: 'id',
      dataIndex: 'id',
      align: 'center',
      render: (id: number, row, index) => <RenderOption row={row} postType={props.type} />,
    },
  ]

  const RenderOption = (props: { row: IResDataCategory; postType: number }) => {
    const modalUpdateRef = useRef<Function>()

    return (
      <div>
        <PopconfirmHoc>
          <Popconfirm
            title={'Bạn có muốn xóa danh mục này'}
            placement={'topLeft'}
            onConfirm={(e) => {
              props.postType === CONSTANCE.CATEGORY.CHARITY
                ? storeCharity.deleteCategory(props.row.id)
                : storeSupportNeed.deleteCategory(props.row.id)
            }}
          >
            <span className={'mr-2 icon-delete'}>{ICON.BUTTON.DELETE}</span>
          </Popconfirm>
        </PopconfirmHoc>
        <span className={'icon-edit'} onClick={(event) => modalUpdateRef.current && modalUpdateRef.current(props.row)}>
          {ICON.BUTTON.EDIT}
        </span>
        <AddUpdateCategory
          type={'UPDATE'}
          openModal={(fn) => (modalUpdateRef.current = fn)}
          postType={props.postType as 1 | 2}
        />
      </div>
    )
  }

  const modalAddRef = useRef<Function>()

  useEffect(() => {
    const idTimeout = setTimeout(() => {
      if (props.type === CONSTANCE.CATEGORY.CHARITY) {
        storeCharity.getListCategory()
      } else {
        storeSupportNeed.getListCategory()
      }
    }, 500)
    return () => clearTimeout(idTimeout)
  }, [
    storeCharity.payload.search,
    storeCharity.payload.startDate,
    storeCharity.payload.endDate,
    storeSupportNeed.payload.search,
    storeSupportNeed.payload.startDate,
    storeSupportNeed.payload.endDate,
  ])

  useEffect(() => {
    return () => {
      if (props.type === CONSTANCE.CATEGORY.CHARITY) {
        storeCharity.payload = {
          page: CONSTANCE.DEFAULT_PAGE,
          limit: CONSTANCE.LIMIT,
        }
      } else {
        storeSupportNeed.payload = {
          page: CONSTANCE.DEFAULT_PAGE,
          limit: CONSTANCE.LIMIT,
        }
      }
    }
  }, [])

  const getDataSource = (data: IResDataCategory[]) => {
    return data.map((value: any, index: number) => {
      return { ...value, key: value.id, STT: FUNCTION.GetIndexTable(storeCharity.payload.page, index) }
    })
  }

  return (
    <div>
      <Row gutter={[16, 4]} className={'mb-5'}>
        <Col lg={8} md={8} sm={24}>
          <Input
            placeholder={'Tìm kiếm theo tên danh mục'}
            allowClear={true}
            onChange={(event) => {
              if (props.type === CONSTANCE.CATEGORY.CHARITY) {
                storeCharity.payload.search = event.target.value
                storeCharity.payload.page = CONSTANCE.DEFAULT_PAGE
              } else {
                storeSupportNeed.payload.search = event.target.value
                storeSupportNeed.payload.page = CONSTANCE.DEFAULT_PAGE
              }
            }}
          />
        </Col>
        <Col lg={8} md={8} sm={24}>
          <RangePickerHoc
            onChange={(stringDate) => {
              if (props.type === CONSTANCE.CATEGORY.CHARITY) {
                storeCharity.payload.startDate = stringDate[0]
                storeCharity.payload.endDate = stringDate[1]
                storeCharity.payload.page = CONSTANCE.DEFAULT_PAGE
              } else {
                storeSupportNeed.payload.startDate = stringDate[0]
                storeSupportNeed.payload.endDate = stringDate[1]
                storeSupportNeed.payload.page = CONSTANCE.DEFAULT_PAGE
              }
            }}
          >
            <DatePicker.RangePicker />
          </RangePickerHoc>
        </Col>
        <Col lg={8} md={8} sm={24}>
          <div className={'flex justify-end mt-1'}>
            <Button
              icon={ICON.BUTTON.ADD}
              type={'primary'}
              onClick={(event) => modalAddRef.current && modalAddRef.current()}
            >
              Thêm mới
            </Button>
          </div>
        </Col>
      </Row>

      <TableHoc>
        <Table
          columns={columns}
          loading={
            props.type === CONSTANCE.CATEGORY.CHARITY ? storeCharity.loading.getList : storeSupportNeed.loading.getList
          }
          dataSource={
            props.type === CONSTANCE.CATEGORY.CHARITY
              ? getDataSource(storeCharity.listCategory)
              : getDataSource(storeSupportNeed.listCategory)
          }
        />
      </TableHoc>

      <AddUpdateCategory type={'ADD'} openModal={(fn) => (modalAddRef.current = fn)} postType={props.type} />
    </div>
  )
})

const Category = () => {
  return (
    <div>
      <CollapseStyles>
        <Collapse.Panel key={1} header={'Đối tượng'}>
          <CategoryItem type={1} />
        </Collapse.Panel>
        <Collapse.Panel key={2} header={'Nhu cầu hỗ trợ'}>
          <CategoryItem type={2} />
        </Collapse.Panel>
      </CollapseStyles>
    </div>
  )
}

export default Category
