import { makeAutoObservable } from 'mobx'
import { IResDataCustomer } from '../CustomerInterface'
import { getDetailCustomerAPI } from '../CustomerApi'

class CustomerDetailStore {
  loading: boolean = false
  detailCustomer: IResDataCustomer | undefined = undefined

  constructor() {
    makeAutoObservable(this)
  }

  async getDetailCustomer(id: number) {
    try {
      this.loading = true
      const res = await getDetailCustomerAPI(id)
      if (res.body.status) {
        this.detailCustomer = res.body.data
      }
    } catch (e) {
      console.error(e)
    } finally {
      this.loading = false
    }
  }
}

const store = new CustomerDetailStore()

export default store
