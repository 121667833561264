import React from 'react'
import { Col, Row } from 'antd'
import styled from 'styled-components'

interface IProps {
  labelGroup?: string
}

const LayoutForm: React.FC<IProps> = ({ labelGroup, children }) => {
  return (
    <Row justify={'center'}>
      <StyleLabelGroup lg={7} md={24} sm={24} className={'text-base text-rose-600'}>
        {labelGroup}
      </StyleLabelGroup>
      <Col lg={15} md={24} sm={24}>
        {children}
      </Col>
    </Row>
  )
}

const StyleLabelGroup = styled(Col)`
  //font-family: OpenSans-Semibold, sans-serif;
  //font-size: 16px;
`

export default LayoutForm
