import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import store from './SelectAddressStore'
import { Form, FormItemProps, Select } from 'antd'

interface IProps extends FormItemProps {
  onChange?: (values?: number) => any
  placeholder?: string
  disabled?: boolean
}

interface IPropsProvince extends IProps {}

interface IPropsDistrict extends IProps {
  provinceId?: number
}

interface IPropsWard extends IProps {
  districtId?: number
}

interface IPropsBank extends IProps {}

export const SelectProvincesComponent: React.FC<IPropsProvince> = observer(
  ({ onChange, placeholder, disabled, ...rest }) => {
    useEffect(() => {
      if (store.listProvince.length === 0) {
        store.getListProvince()
      }
    }, [])
    console.log(rest)
    return (
      <Form.Item {...rest}>
        <Select
          placeholder={placeholder}
          allowClear={true}
          disabled={disabled}
          // showSearch
          optionFilterProp={'children'}
          onChange={(value) => onChange && onChange(value)}
        >
          {store.listProvince.map((value) => {
            return (
              <Select.Option key={value.id} value={value.id}>
                {value.name}
              </Select.Option>
            )
          })}
        </Select>
      </Form.Item>
    )
  }
)

export const SelectDistrictComponent: React.FC<IPropsDistrict> = observer(
  ({ onChange, placeholder, disabled, provinceId, ...rest }) => {
    useEffect(() => {
      provinceId && store.getListDistrict(provinceId)
    }, [provinceId])
    return (
      <Form.Item {...rest}>
        <Select
          placeholder={placeholder}
          allowClear={true}
          disabled={disabled}
          optionFilterProp={'children'}
          onChange={(value) => onChange && onChange(value)}
        >
          {store.listDistrict.map((value) => {
            return (
              <Select.Option key={value.id} value={value.id}>
                {value.name}
              </Select.Option>
            )
          })}
        </Select>
      </Form.Item>
    )
  }
)

export const SelectWardComponent: React.FC<IPropsWard> = observer(
  ({ onChange, placeholder, disabled, districtId, ...rest }) => {
    useEffect(() => {
      districtId && store.getListWard(districtId)
    }, [districtId])
    return (
      <Form.Item {...rest}>
        <Select
          placeholder={placeholder}
          allowClear={true}
          disabled={disabled}
          optionFilterProp={'children'}
          onChange={(value) => onChange && onChange(value)}
        >
          {store.listWard.map((value) => {
            return (
              <Select.Option key={value.id} value={value.id}>
                {value.name}
              </Select.Option>
            )
          })}
        </Select>
      </Form.Item>
    )
  }
)

export const SelectBankComponent: React.FC<IPropsBank> = observer(({ onChange, placeholder, disabled, ...rest }) => {
  useEffect(() => {
    if (store.listBank.length === 0) {
      store.getListBank()
    }
  }, [])
  return (
    <Form.Item {...rest}>
      <Select
        placeholder={placeholder}
        allowClear={true}
        disabled={disabled}
        onChange={(value) => onChange && onChange(value)}
      >
        {store.listBank.map((value) => {
          return (
            <Select.Option key={value.id} value={value.id}>
              {value.name}
            </Select.Option>
          )
        })}
      </Select>
    </Form.Item>
  )
})
