import React from 'react'
import CONSTANCE from '../../services/Constance'
import { Form, FormItemProps, Select, Tag } from 'antd'

interface IProps extends FormItemProps {
  onChange?: (values: number | undefined) => any
  placeholder?: string
  disable?: boolean
  displayStatusPending?: boolean
}

export const RenderStatusRequestSupport = (status: number) => {
  if (status === CONSTANCE.REQUEST_SUPPORT.STATUS.DONE) {
    return <Tag color={'green'}>Hoàn thành</Tag>
  }
  if (status === CONSTANCE.REQUEST_SUPPORT.STATUS.PAUSE) {
    return <Tag color={'red'}>Tạm dừng</Tag>
  }
  if (status === CONSTANCE.REQUEST_SUPPORT.STATUS.PENDING) {
    return <Tag color={'orange'}>Đang xử lý</Tag>
  }
}

export const SelectStatusRequestSupport: React.FC<IProps> = ({ onChange, disable, placeholder, ...rest }) => {
  return (
    <Form.Item {...rest}>
      <Select
        placeholder={placeholder}
        allowClear={true}
        onChange={(value) => onChange && onChange(value)}
        optionFilterProp={'children'}
        disabled={disable}
      >
        <Select.Option key={CONSTANCE.REQUEST_SUPPORT.STATUS.DONE} value={CONSTANCE.REQUEST_SUPPORT.STATUS.DONE}>
          Hoàn thành
        </Select.Option>
        <Select.Option key={CONSTANCE.REQUEST_SUPPORT.STATUS.PENDING} value={CONSTANCE.REQUEST_SUPPORT.STATUS.PENDING}>
          Đang xử lý
        </Select.Option>
        <Select.Option key={CONSTANCE.REQUEST_SUPPORT.STATUS.PAUSE} value={CONSTANCE.REQUEST_SUPPORT.STATUS.PAUSE}>
          Tạm dừng
        </Select.Option>
      </Select>
    </Form.Item>
  )
}

export const RenderStatusApproveRequestSupport = (status: number) => {
  if (status === CONSTANCE.REQUEST_SUPPORT.STATUS_APPROVE.DENY) {
    return <Tag color={'red'}>Xóa</Tag>
  }
  if (
    status === CONSTANCE.REQUEST_SUPPORT.STATUS_APPROVE.USER_DONATE ||
    status === CONSTANCE.REQUEST_SUPPORT.STATUS_APPROVE.DISTRICT_APPROVE
  ) {
    return <Tag color={'orange'}>Chưa liên hệ</Tag>
  }
  if (status === CONSTANCE.REQUEST_SUPPORT.STATUS_APPROVE.DONE) {
    return <Tag color={'blue'}>Đã liên hệ</Tag>
  }
  if (status === CONSTANCE.REQUEST_SUPPORT.STATUS_APPROVE.COMPLETE) {
    return <Tag color={'blue'}>Hoàn thành</Tag>
  }
}

export const SelectStatusApproveRequestSupport: React.FC<IProps> = ({
  onChange,
  disable,
  displayStatusPending,
  placeholder,
  ...rest
}) => {
  return (
    <Form.Item {...rest}>
      <Select
        placeholder={placeholder}
        allowClear={true}
        onChange={(value) => onChange && onChange(value)}
        optionFilterProp={'children'}
        disabled={disable}
      >
        <Select.Option
          key={CONSTANCE.REQUEST_SUPPORT.STATUS_APPROVE.DENY}
          value={CONSTANCE.REQUEST_SUPPORT.STATUS_APPROVE.DENY}
        >
          Xóa
        </Select.Option>
        <Select.Option
          key={CONSTANCE.REQUEST_SUPPORT.STATUS_APPROVE.DISTRICT_APPROVE}
          value={CONSTANCE.REQUEST_SUPPORT.STATUS_APPROVE.DISTRICT_APPROVE}
        >
          Chưa liên hệ
        </Select.Option>
        {displayStatusPending && (
          <Select.Option
            key={CONSTANCE.REQUEST_SUPPORT.STATUS_APPROVE.USER_DONATE}
            value={CONSTANCE.REQUEST_SUPPORT.STATUS_APPROVE.USER_DONATE}
          >
            Chưa liên hệ
          </Select.Option>
        )}
        <Select.Option
          key={CONSTANCE.REQUEST_SUPPORT.STATUS_APPROVE.DONE}
          value={CONSTANCE.REQUEST_SUPPORT.STATUS_APPROVE.DONE}
        >
          Đã liên hệ
        </Select.Option>
        <Select.Option
          key={CONSTANCE.REQUEST_SUPPORT.STATUS_APPROVE.COMPLETE}
          value={CONSTANCE.REQUEST_SUPPORT.STATUS_APPROVE.COMPLETE}
        >
          Hoàn thành
        </Select.Option>
      </Select>
    </Form.Item>
  )
}
