import React, { useEffect, useState } from 'react'
import { DatePicker, Form, Modal } from 'antd'
import ModalHoc from '../../../commons/HOC/ModalHOC'
import { useForm } from 'antd/es/form/Form'
import store from '../page/PostDetailStore'
import CONSTANCE from '../../../services/Constance'
import FUNCTION from '../../../services/Functions'
import AppStore from '../../app-store/AppStore'
import { Notification } from '../../../commons/notification/Notification'
import moment from 'moment'

const FormApprove = (props: { modalApprove?: (fn: Function) => any }) => {
  const [visible, setVisible] = useState<boolean>(false)
  const [formApprove] = useForm()

  const onApprovePost = async (values: { end_date: string }) => {
    const result = await store.putChangeStatus({
      status: CONSTANCE.POST.POST_STATUS.DONE,
      post_ids: [FUNCTION.GetId()],
      reason: '',
      end_date: moment(values.end_date).toISOString(),
    })
    if (result) {
      AppStore.getCount()
      Notification.SweetAlert('SUCCESS', 'Phê duyệt thành công tin đăng')
      setVisible(false)
    }
  }

  const onOpenModal = () => {
    setVisible(true)
  }

  useEffect(() => {
    props.modalApprove && props.modalApprove(onOpenModal)
  }, [])

  return (
    <ModalHoc>
      <Modal
        title={'Thời gian hết hạn tin đăng'}
        visible={visible}
        onCancel={(e) => setVisible(false)}
        onOk={(e) => {
          formApprove.submit()
        }}
      >
        <Form onFinish={onApprovePost} form={formApprove}>
          <Form.Item name={'end_date'} rules={[{ required: true, message: 'Vui lòng chọn ngày hết hạn tin đăng ' }]}>
            <DatePicker
              className={'w-full'}
              format={'DD/MM/YYYY'}
              disabledDate={(current: any) => current && current < moment().startOf('day')}
            />
          </Form.Item>
        </Form>
      </Modal>
    </ModalHoc>
  )
}

export default FormApprove
