import { makeAutoObservable } from 'mobx'
import {
  IPayloadRequestSupport,
  IReqChangeStatus,
  IReqPostRequestSupport,
  IReqRequestSupport,
  IResDataRequestSupport,
} from './RequestSupportInterfaces'
import CONSTANCE from '../../services/Constance'
import {
  getListRequestSupportAPI,
  postRequestSupportAPI,
  putChangeStatusRequestSupportAPI,
  putRequestSupportAPI,
} from './RequestSupportAPI'
import FUNCTION from '../../services/Functions'
import { Notification } from '../../commons/notification/Notification'

class RequestSupportStore {
  loading = {
    getList: false,
    changeStatus: false,
    submit: false,
  }
  payload: IPayloadRequestSupport = {
    page: CONSTANCE.DEFAULT_PAGE,
    limit: CONSTANCE.LIMIT,
  }
  listRequestSupport: IResDataRequestSupport[] = []
  total: number = 0

  constructor() {
    makeAutoObservable(this)
  }

  async getListRequestSupport() {
    try {
      this.loading.getList = true
      const res = await getListRequestSupportAPI({ ...this.payload, search: this.payload.search?.trim() })
      if (res.body.status) {
        this.listRequestSupport = res.body.data
        this.total = res.body.paging.totalItemCount
      }
    } catch (e) {
      console.error(e)
    } finally {
      this.loading.getList = false
    }
  }

  async changeStatus(reqData: IReqChangeStatus): Promise<boolean> {
    try {
      this.loading.changeStatus = true
      const res = await putChangeStatusRequestSupportAPI(reqData)
      if (res.body.status) {
        this.getListRequestSupport()
        return FUNCTION.GetPromise(true)
      } else {
        return FUNCTION.GetPromise(false)
      }
    } catch (e) {
      console.error(e)
      return FUNCTION.GetPromise(false)
    } finally {
      this.loading.changeStatus = false
    }
  }

  async postRequestSupport(reqData: IReqPostRequestSupport): Promise<boolean> {
    try {
      this.loading.submit = false
      const res = await postRequestSupportAPI(reqData)
      if (res.body.status) {
        Notification.PushNotification('SUCCESS', 'Thêm mới thành công yêu cầu từ thiện')
        return FUNCTION.GetPromise(true)
      } else {
        return FUNCTION.GetPromise(false)
      }
    } catch (e) {
      console.error(e)
      return FUNCTION.GetPromise(false)
    } finally {
      this.loading.submit = false
    }
  }

  async putRequestSupport(id: number, reqData: IReqRequestSupport): Promise<boolean> {
    try {
      const res = await putRequestSupportAPI(id, reqData)
      if (res.body.status) {
        Notification.PushNotification('SUCCESS', 'Cập nhật yêu cầu ủng hộ thành công')
        return FUNCTION.GetPromise(true)
      } else {
        return FUNCTION.GetPromise(false)
      }
    } catch (e) {
      console.error(e)
      return FUNCTION.GetPromise(false)
    } finally {
    }
  }
}

const store = new RequestSupportStore()

export default store
