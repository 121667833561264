import React, { useEffect, useRef, useState } from 'react'
import { ContentStyles, HeaderStyles, SiderStyles, StyleContentPopover, UserInfoStyles } from './LayoutStyles'
import { Avatar, Badge, Layout, Popover } from 'antd'
import MenuComponent, { dataMenuAdmin, dataMenuEnterPrise } from './MenuComponent'
import AdminRouter from '../../router/AdminRouter'
import FUNCTION from '../../services/Functions'
import ICON from '../../commons/icon/Icon'
import { logout } from '../login/LoginFN'
import AppStore from '../app-store/AppStore'
import NotificationHeader from './NotificationHeader'
import NotificationHeaderStore, { IResDataNotificationHeader } from './NotificationHeaderStore'
import { io, Socket } from 'socket.io-client'
import { Notification } from '../../commons/notification/Notification'
import { observer } from 'mobx-react'
import { getToken } from '../../services/Basevices'
import styled from 'styled-components'
import CONSTANCE from '../../services/Constance'
import { MenuOutlined } from '@ant-design/icons'
interface IheaderComponent {
  handleToggleMenu: () => void
}
interface ISliderComponent {
  handleResize: (e: boolean) => void
  collapsed: boolean
}

const SliderComponent = React.memo(({ collapsed, handleResize }: ISliderComponent) => {
  const RenderUserInfo = () => {
    const userInfo = FUNCTION.GetUserInfo()
    return (
      <UserInfoStyles>
        <Avatar style={{ border: '1px solid gray' }} size={64} src={'/logo192.png'} />
        <div className={'user-name'}>{userInfo?.name}</div>
      </UserInfoStyles>
    )
  }
  return (
    <SiderStyles
      breakpoint="md"
      collapsedWidth="0px"
      collapsed={collapsed}
      // onCollapse={() => console.log('clolap')}
      onBreakpoint={(e) => {
        handleResize(e)
      }}
      width={220}
    >
      <RenderUserInfo />
      <MenuComponent data={FUNCTION?.GetUserInfo()?.role === 1 ? dataMenuAdmin : dataMenuEnterPrise} />
    </SiderStyles>
  )
})

const HeaderComponent = observer(({ handleToggleMenu }: IheaderComponent) => {
  useEffect(() => {
    if (getToken()) {
      NotificationHeaderStore.getCountNotification()
    }
  }, [])

  const resetNotification = async () => {
    await NotificationHeaderStore.getCountNotification()
    await NotificationHeaderStore.getListNotification()
  }

  return (
    <HeaderStyles className={'flex justify-between'}>
      <div className={'w-48 ml-8 flex items-center mt-3'}>
        {/*<img src={'/assets/img/logo.png'} className={'w-10'} />*/}
        {/* <div className="toggle-menu"> */}
        {/* </div> */}
        <MenuOutlined onClick={handleToggleMenu} className="toggle-menu" />
        <h1 className={'text-rose-700'} style={{ fontSize: 15, fontFamily: 'OpenSans-Semibold' }}>
          NHÂN ĐẠO SỐ
        </h1>
      </div>
      <div className={'h-2.8 flex justify-center items-center'}>
        <Popover
          placement={'bottom'}
          content={
            <StyleContentPopover>
              <NotificationHeader
                resetNotification={resetNotification}
                countNotification={NotificationHeaderStore.count}
              />
            </StyleContentPopover>
          }
          trigger={'click'}
        >
          <div className={'cursor-pointer'} onClick={(event) => NotificationHeaderStore.getListNotification()}>
            <Badge size={'small'} count={NotificationHeaderStore.count} style={{ transform: 'translate(18px, -13px)' }}>
              <div className={'w-4'}>{ICON.HEADER_ICON.NOTIFICATION}</div>
            </Badge>
          </div>
        </Popover>

        <div className={'mr-5'} />

        <Popover
          placement={'bottom'}
          content={
            <StyleContentPopover>
              <div className={'item w-24'} onClick={() => logout()}>
                Đăng xuất
              </div>
            </StyleContentPopover>
          }
          trigger={'click'}
        >
          <div className={'cursor-pointer'}>{ICON.HEADER_ICON.USER}</div>
        </Popover>
      </div>
    </HeaderStyles>
  )
})
const LayoutComponent = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false)
  const handleToggleMenu = () => {
    setCollapsed(!collapsed)
  }
  const handleResize = (e: boolean) => {
    setCollapsed(e)
  }

  const notifiCurrent = useRef(0)
  const getRouter = () => {
    return <AdminRouter />
  }
  useEffect(() => {
    if (getToken()) {
      AppStore.getCount()
    }
  }, [])

  const socket = useRef<Socket>()

  useEffect(() => {
    if (!socket.current) {
      socket.current = io(process.env.REACT_APP_SOCKET as string, {
        auth: {
          token: FUNCTION.GetUserInfo() ? getToken() : '',
        },
      })
    }

    socket.current &&
      socket.current.on('connect', () => {
        socket.current &&
          socket.current.on('event', (data: IResDataNotificationHeader) => {
            if (data.notification_id != notifiCurrent.current) {
              Notification.PushNotification('INFO', data.content, undefined, undefined, undefined, data.title)
              notifiCurrent.current = data.notification_id
            }
          })
      })

    return () => {
      socket.current?.close()
      socket.current?.disconnect()
      socket.current = undefined
    }
  }, [])

  useEffect(() => {
    return () => {
      socket.current?.close()
      socket.current?.disconnect()
      socket.current = undefined
    }
  }, [])
  return (
    <StyleContent>
      <Layout hasSider>
        <SliderComponent handleResize={handleResize} collapsed={collapsed} />
        <Layout>
          <HeaderComponent handleToggleMenu={handleToggleMenu} />
          <ContentStyles>{getRouter()}</ContentStyles>
        </Layout>
      </Layout>
    </StyleContent>
  )
}

const StyleContent = styled.div`
  .ant-layout-content {
    margin-left: 220px;
    transition: margin-left 0.2s ease;
    @media (max-width: 768px) {
      margin-left: 0px;
    }
  }
  .ant-layout-sider-zero-width-trigger {
    display: none;
  }
  .toggle-menu {
    cursor: pointer;
    padding: 3px 10px 10px 10px;
    font-size: 16px;
    display: none;
    @media (max-width: 768px) {
      display: block;
    }
  }
`
export default React.memo(LayoutComponent)
