import React, { useState } from 'react'
import { PageHeader, Tabs } from 'antd'
import Group from './group/Group'
import Category from './category/Category'
import history from '../../services/history'

const Config = () => {
  const searchParams = new URLSearchParams(window.location.search)

  const tabs: string = searchParams.get('tabs') || 'tab1'
  const [key, setKey] = useState<string>(tabs)

  const onChangeTabs = (tabs: string) => {
    setKey(tabs)
    searchParams.set('tabs', tabs)
    history.push({
      pathname: window.location.pathname,
      search: searchParams.toString(),
    })
  }

  return (
    <div>
      <PageHeader title={'Thiết lập'} />

      <div className={'box'}>
        <Tabs size={'large'} activeKey={key} onChange={onChangeTabs} type={'line'}>
          <Tabs.TabPane key={'tab1'} tab={'Phân nhóm'}>
            <Group />
          </Tabs.TabPane>
          <Tabs.TabPane key={'tab2'} tab={'Phân đối tượng'}>
            <Category />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  )
}

export default Config
