import { IPayloadCustomer, IResCustomer, IResDetailCustomer } from './CustomerInterface'
import { Baservices, IApiResponse } from '../../services/Basevices'
import { IResBody } from '../../services/Interfaces'

export const getListCustomerAPI = (payload: IPayloadCustomer): Promise<IApiResponse<IResCustomer>> => {
  return Baservices.getMethod(`/web/customer/listCustomer`, payload)
}

export const getDetailCustomerAPI = (id: number): Promise<IApiResponse<IResDetailCustomer>> => {
  return Baservices.getMethod(`/web/customer/${id}`)
}

export const putChangeStatusAPI = (id: number): Promise<IApiResponse<IResBody>> => {
  return Baservices.putMethod(`/web/customer/${id}/change-statusActive`)
}
