import React, { useEffect } from 'react'
import { Form, FormItemProps, Select } from 'antd'
import storeCharity from '../config/category/CategoryStoreCharity'
import { observer } from 'mobx-react'
import storeSupportNeed from '../config/category/CategoryStoreSupportNeed'

interface IProps extends FormItemProps {
  onChange?: (values?: number | number[]) => any
  placeholder?: string
  disabled?: boolean
  type?: 'CHARITY' | 'SUPPORT_NEEDS'
  groupId?: number
  mode?: 'tags' | 'multiple'
}

const SelectCategoryComponent: React.FC<IProps> = observer(
  ({ onChange, placeholder, mode, disabled, groupId, type, ...rest }) => {
    useEffect(() => {
      if (type === 'CHARITY') {
        storeCharity.getListCategoryFull(groupId as number)
      }
      if (type === 'SUPPORT_NEEDS') {
        storeSupportNeed.getListCategoryFull(groupId as number)
      }
    }, [groupId])

    return (
      <Form.Item {...rest}>
        <Select
          placeholder={placeholder}
          disabled={disabled}
          showSearch
          mode={mode}
          optionFilterProp={'children'}
          loading={type === 'CHARITY' ? storeCharity.loading.getList : storeSupportNeed.loading.getList}
        >
          {type === 'CHARITY' &&
            storeCharity.listCategoryFull.map((value) => {
              return (
                <Select.Option key={value.id} value={value.id}>
                  {value.name}
                </Select.Option>
              )
            })}

          {type === 'SUPPORT_NEEDS' &&
            storeSupportNeed.listCategoryFull.map((value) => {
              return (
                <Select.Option key={value.id} value={value.id}>
                  {value.name}
                </Select.Option>
              )
            })}
        </Select>
      </Form.Item>
    )
  }
)

export default SelectCategoryComponent
