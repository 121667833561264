import { IPayloadGroup, IReqGroup, IResGroup } from './GroupInterface'
import { Baservices, IApiResponse } from '../../../services/Basevices'
import { IResBody } from '../../../services/Interfaces'

export const getListGroupAPI = (payload: IPayloadGroup): Promise<IApiResponse<IResGroup>> => {
  return Baservices.getMethod(`/web/groups/list`, payload)
}

export const postGroupAPI = (reqData: IReqGroup): Promise<IApiResponse<IResBody>> => {
  return Baservices.postMethod(`/web/groups/createGroup`, reqData)
}

export const putGroupAPI = (id: number, reqData: IReqGroup): Promise<IApiResponse<IResBody>> => {
  return Baservices.putMethod(`/web/groups/${id}/update`, reqData)
}

export const deleteGroupAPI = (id: number): Promise<IApiResponse<IResBody>> => {
  return Baservices.deleteMethod(`/web/groups/${id}/delete`)
}
