import { Route, Switch } from 'react-router-dom'
import Account from '../modules/account/Account'
import Customer from '../modules/customer/Customer'
import CustomerDetail from '../modules/customer/page/CustomerDetail'
import Config from '../modules/config/Config'
import Post from '../modules/post/Post'
import PostDetail from '../modules/post/page/PostDetail'
import AddUpdatePost from '../modules/post/page/AddUpdatePost'
import RequestSupport from '../modules/request-support/RequestSupport'
import News from '../modules/news/News'
import AddUpdateNews from '../modules/news/page/AddUpdateNews'
import Dashboard from '../modules/dashboard/Dashboard'

export const ADMIN_ROUTER = {
  // DASHBOARD: {
  //   path: '/dashboard',
  //   component: Dashboard,
  // }
  CUSTOMER: {
    path: '/customer',
    component: Customer,
  },
  CUSTOMER_DETAIL: {
    path: '/customer/detail',
    component: CustomerDetail,
  },
  ACCOUNT: {
    path: '/account',
    component: Account,
  },
  CONFIG: {
    path: '/config',
    component: Config,
  },
  POST: {
    path: '/post',
    component: Post,
  },
  POST_DETAIL: {
    path: '/post/detail',
    component: PostDetail,
  },
  POST_ADD_UPDATE: {
    path: '/post/add-update',
    component: AddUpdatePost,
  },
  REQUEST_SUPPORT: {
    path: '/request-support',
    component: RequestSupport,
  },
  NEWS: {
    path: '/news',
    component: News,
  },
  NEWS_ADD_UPDATE: {
    path: '/news/add-update',
    component: AddUpdateNews,
  },
  DASHBOARD: {
    path: '/dashboard',
    component: Dashboard,
  },
}

const AdminRouter: React.FC = () => {
  let arrAdminRouter = Object.values(ADMIN_ROUTER)

  return (
    <Switch>
      {arrAdminRouter.map((value: any) => (
        <Route key={value.path} exact path={value.path} component={value.component} />
      ))}
    </Switch>
  )
}

export default AdminRouter
