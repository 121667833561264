import React from 'react'
import { Card, Descriptions, Divider, Table } from 'antd'
import ICON from '../../../commons/icon/Icon'
import store from './CustomerDetailStore'
import TableHoc from '../../../commons/HOC/TableHOC'
import { IColumn } from '../../../services/Interfaces'
import { observer } from 'mobx-react'

const columns: IColumn[] = [
  {
    title: 'STT',
    key: 'STT',
    dataIndex: 'STT',
    align: 'center',
    width: 80,
    render: (STT: number, row, index) => <div>{STT}</div>,
  },
  {
    title: 'Họ tên',
    key: '',
    dataIndex: '',
    align: 'center',
    render: (value, row, index) => <div></div>,
  },
  {
    title: 'Điện thoại',
    key: '',
    dataIndex: '',
    align: 'center',
    render: (value, row, index) => <div></div>,
  },
  {
    title: 'Người ủng hộ',
    key: '',
    dataIndex: '',
    align: 'center',
    render: (value, row, index) => <div></div>,
  },
  {
    title: 'Nhu cầu',
    key: '',
    dataIndex: '',
    align: 'center',
    render: (value, row, index) => <div></div>,
  },
  {
    title: 'Trạng thái',
    key: '',
    dataIndex: '',
    align: 'center',
    render: (value, row, index) => <div></div>,
  },
  {
    title: 'Ngày tạo',
    key: '',
    dataIndex: '',
    align: 'center',
    render: (value, row, index) => <div></div>,
  },
]

const InfoCustomer = observer(() => {
  return (
    <div className={'box'}>
      <Card title={'Thông tin người dùng'} bordered={false}>
        <Descriptions column={2}>
          <Descriptions.Item
            label={
              <div>
                <span className={'mr-1.5'}> {ICON.HEADER_ICON.USER}</span>
                <span>Tên người dùng</span>
              </div>
            }
          >
            {store.detailCustomer?.name}
          </Descriptions.Item>
          <Descriptions.Item
            label={
              <div>
                <span className={'mr-1.5'}> {ICON.MAIL}</span>
                <span>Email</span>
              </div>
            }
          >
            {store.detailCustomer?.email}
          </Descriptions.Item>
          <Descriptions.Item
            label={
              <div>
                <span className={'mr-1.5'}> {ICON.PHONE}</span>
                <span>Số điện thoại</span>
              </div>
            }
          >
            {store.detailCustomer?.phone}
          </Descriptions.Item>
          <Descriptions.Item
            label={
              <div>
                <span className={'mr-1.5'}> {ICON.LOATION}</span>
                <span>Địa chỉ</span>
              </div>
            }
          >
            {store.detailCustomer?.address}
          </Descriptions.Item>
        </Descriptions>
      </Card>

      <Divider />

      <Card title={'Thông tin ủng hộ'} bordered={false}>
        <TableHoc>
          <Table columns={columns} />
        </TableHoc>
      </Card>
    </div>
  )
})
export default InfoCustomer
