import { Baservices, IApiResponse } from '../../services/Basevices'
import { IPayloadAccount, IReqAccount, IResAccount } from './AccountInterface'
import { IResBody } from '../../services/Interfaces'

export const getListAccountAPI = (payload: IPayloadAccount): Promise<IApiResponse<IResAccount>> => {
  return Baservices.getMethod(`/web/users/list`, payload)
}

export const deleteAccountAPI = (id: number): Promise<IApiResponse<IResBody>> => {
  return Baservices.deleteMethod(`/web/users/${id}/delete`)
}

export const postAccountAPI = (reqData: IReqAccount): Promise<IApiResponse<IResBody>> => {
  return Baservices.postMethod(`/web/users/createUser`, reqData)
}

export const putAccountAPI = (id: number, reqData: IReqAccount): Promise<IApiResponse<IResBody>> => {
  return Baservices.putMethod(`/web/users/${id}/update`, reqData)
}

export const getAccountInfo = (): Promise<IApiResponse<IResBody>> => {
  return Baservices.getMethod('/web/users/info')
}
