import { useState } from 'react'
import styled from 'styled-components'
import { Button, Form, Input, Row } from 'antd'
import { loginAPI } from './LoginAPI'
import { TOKEN_NAME } from '../../services/Basevices'
import history from '../../services/history'

export interface IFormData {
  account: string
  password: string
}

const Login = () => {
  const [loading, setLoading] = useState<boolean>(false)

  const onLogin = async (values: IFormData) => {
    try {
      setLoading(true)
      const res = await loginAPI({ user_name: values.account, password: values.password })
      if (res.body.status) {
        localStorage.setItem(TOKEN_NAME, res.body.data.token)
        localStorage.setItem('userInfo', JSON.stringify(res.body.data))
        history.push('/dashboard')
      }
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <LoginStyle
      style={{
        backgroundImage: `url('./assets/img/bg-2.png')`,
      }}
    >
      <WrapperFormStyles>
        <WrapperImage>
          <img alt="logo" src={'/assets/img/logo.png'} />
        </WrapperImage>
        <Form size={'middle'} onFinish={onLogin}>
          <Form.Item
            name={'account'}
            rules={[
              {
                required: true,
                whitespace: true,
                message: 'Vui lòng nhập tài khoản',
              },
              { type: 'string', min: 5, message: 'Tài khoản ít nhất 5 ký tự' },
            ]}
          >
            <Input prefix={<i className="far fa-user" />} placeholder={'Nhập tài khoản'} />
          </Form.Item>
          <Form.Item
            name={'password'}
            rules={[
              { required: true, message: 'Vui lòng nhận mật khẩu' },
              {
                type: 'string',
                min: 8,
                message: 'Mật khẩu ít nhất 8 ký tự',
              },
            ]}
          >
            <Input.Password prefix={<i className="far fa-lock" />} placeholder={'Nhập mật khẩu'} />
          </Form.Item>

          <Row justify={'center'}>
            <Form.Item>
              <Button type={'primary'} danger loading={loading} htmlType={'submit'}>
                Đăng nhập
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </WrapperFormStyles>
    </LoginStyle>
  )
}

const LoginStyle = styled.div`
  height: 100vh;
  width: 100vw;

  overflow: hidden;

  background-size: cover;
  background-position: bottom;

  background-repeat: no-repeat;

  display: flex;
  justify-content: center;
  align-items: center;
`

const WrapperFormStyles = styled.div`
  height: 555px;
  width: 420px;
  padding: 50px;

  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 6px;
`

const WrapperImage = styled.div`
  height: 40%;
  margin-bottom: 15px;

  display: flex;
  justify-content: center;

  img {
    width: 180px;
  }
`

export default Login
