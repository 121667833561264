import { useEffect, useState } from 'react'
import ModalHoc from '../../../commons/HOC/ModalHOC'
import { Divider, Form, Input, Modal } from 'antd'
import CONSTANCE from '../../../services/Constance'
import SelectTypeAccount from '../../select/SelectTypeAccount'
import SelectStatusComponent from '../../select/SelectStatusComponent'
import { IReqAccount, IResDataAccount } from '../AccountInterface'
import store from '../AccountStore'
import { useForm } from 'antd/es/form/Form'
import { IValidate } from '../../../services/Interfaces'
import { Notification } from '../../../commons/notification/Notification'
import {
  SelectDistrictComponent,
  SelectProvincesComponent,
  SelectWardComponent,
} from '../../select/select-address/SelectAddressComponent'
import FUNCTION from '../../../services/Functions'

interface IForm extends IReqAccount {
  id: number
  confirm_password?: string
}

const AddUpdateAccount = (props: { modal: (fn: Function) => any; type: 'ADD' | 'UPDATE' }) => {
  const [visible, setVisible] = useState<boolean>(false)
  const [form] = useForm()
  const [typeAccount, setTypeAccount] = useState<number | undefined>(undefined)
  const [provinceId, setProvinceId] = useState<number | undefined>(10)
  const [districtId, setDistrictId] = useState<number | undefined>(undefined)

  const onOpenModal = (row: IResDataAccount) => {
    setVisible(true)
    if (props.type === 'UPDATE') {
      const formData: IForm = {
        id: row.id,
        email: row.email,
        username: row.username,
        name: row.name,
        phone: row.phone,
        profile_picture_url: row.profile_picture_url,
        role: row.role,
        status: row.status,
        address: row.address,
        ward_id: row.DFWard ? row.DFWard.id : undefined,
        district_id: row.DFDistrict ? row.DFDistrict.id : undefined,
        province_id: row.DFProvince ? row.DFProvince.id : undefined,
      }
      console.log('formData123', formData)
      form.setFieldsValue(formData)

      if (row.DFProvince) {
        setProvinceId(row.DFProvince.id)
        setTypeAccount(CONSTANCE.ACCOUNT.OFFICER_PROVINCE)
      }

      if (row.DFDistrict) {
        setDistrictId(row.DFDistrict.id)
        setTypeAccount(CONSTANCE.ACCOUNT.OFFICER_DISTRICT)
      }
      if (row.DFWard) {
        setTypeAccount(CONSTANCE.ACCOUNT.OFFICER_WARD)
      }
    }
    if (props.type === 'ADD') {
      setProvinceId(69)
      const formData: any = {
        province_id: store.user.DFProvince?.id || 69,
        district_id: store.user.DFDistrict?.id,
      }
      if (store.user.DFProvince?.id) {
        setDistrictId(store.user.DFProvince?.id)
      }
      if (store.user.DFDistrict?.id) {
        setDistrictId(store.user.DFDistrict?.id)
      }
      console.log('formData', formData)

      form.setFieldsValue(formData)
    }
  }

  const onCloseModal = () => {
    setVisible(false)
    form.resetFields()
  }

  const onFinish = async (values: IForm) => {
    const userInfo = FUNCTION.GetUserInfo()
    const validate = (): IValidate => {
      if (values.role <= userInfo.role) {
        return {
          msg: 'Bạn không có quyền tạo tải khoản ' + (values.role === 2 ? 'tỉnh' : values.role === 3 ? 'huyện' : 'xã'),
          isValidity: false,
        }
      } else if (values.password === values.confirm_password) {
        return {
          msg: '',
          isValidity: true,
        }
      } else {
        return {
          msg: 'Mật khẩu không khớp nhau',
          isValidity: false,
        }
      }
    }

    const reqData: IReqAccount = {
      email: values.email,
      name: values.name,
      username: values.username,
      phone: values.phone,
      profile_picture_url: '',
      password: values.password,
      role: values.role,
      status: values.status,
      district_id: values.district_id,
      province_id: values.province_id,
      ward_id: values.ward_id,
      address: values.address,
    }

    if (validate().isValidity) {
      if (props.type === 'ADD') {
        delete reqData.status
        const result = await store.createAccount(reqData)
        if (result) {
          onCloseModal()
        }
      } else {
        delete reqData.password

        const result = await store.updateAccount(form.getFieldValue('id'), reqData)
        if (result) {
          onCloseModal()
        }
      }
    } else {
      Notification.PushNotification('ERROR', validate().msg)
    }
  }

  useEffect(() => {
    props.modal(onOpenModal)
  }, [])

  return (
    <ModalHoc>
      <Modal
        onOk={() => form.submit()}
        onCancel={onCloseModal}
        visible={visible}
        title={props.type === 'UPDATE' ? 'Sửa tài khoản' : 'Thêm tài khoản'}
        confirmLoading={store.loading.submit}
      >
        <Form wrapperCol={{ span: 16 }} labelCol={{ span: 8 }} className={'label-left'} form={form} onFinish={onFinish}>
          <Form.Item
            label={'Họ tên'}
            name={'name'}
            rules={[
              { required: false, whitespace: true, message: 'Vui lòng nhập họ tên' },
              {
                type: 'string',
                max: 255,
                message: 'Ho tên không quá 255 ký tư',
              },
              {
                pattern: CONSTANCE.REG.UNICODE_NOT_NUM,
                message: 'Tên không đúng định dạng',
              },
            ]}
          >
            <Input placeholder={'Nhập họ tên'} />
          </Form.Item>
          <Form.Item
            label={'Tên Tài khoản'}
            name={'username'}
            rules={[
              { required: true, whitespace: false, message: 'Vui lòng nhập tên tài khoản' },
              {
                type: 'string',
                max: 255,
                message: 'Ho tên không quá 255 ký tư',
              },
              {
                pattern: CONSTANCE.REG.USERNAME,
                message: 'Tên tài khoản không đúng định dạng',
              },
            ]}
          >
            <Input placeholder={'Nhập họ tên'} />
          </Form.Item>
          <Form.Item
            label={'Số điện thoại'}
            name={'phone'}
            rules={[
              { required: true, whitespace: true, message: 'Vui lòng nhập số điện thoại' },
              {
                pattern: CONSTANCE.REG.PHONE,
                message: 'Số điện thoại không đúng định dạng',
              },
            ]}
          >
            <Input placeholder={'Nhập số điện thoại'} />
          </Form.Item>
          <Form.Item
            label={'Email'}
            name={'email'}
            rules={[
              { required: false, whitespace: true, message: 'Vui lòng nhập email' },
              {
                type: 'email',
                message: 'Email không đúng định dạng',
              },
            ]}
          >
            <Input placeholder={'Nhập email'} />
          </Form.Item>
          <Form.Item
            label={'Địa chỉ'}
            name={'address'}
            rules={[
              { required: true, whitespace: true, message: 'Vui lòng nhập địa chỉ' },
              {
                type: 'string',
                max: 555,
                message: 'Địa chỉ không quá 555 ký tự',
              },
            ]}
          >
            <Input allowClear={true} placeholder={'Nhập địa chỉ'} />
          </Form.Item>
          <Divider />
          {props.type === 'ADD' ? (
            <div>
              <Form.Item
                label={'Mật khẩu'}
                name={'password'}
                rules={[
                  { required: true, message: 'Vui lòng nhập mật khẩu' },
                  {
                    type: 'string',
                    min: 8,
                    max: 20,
                    message: 'Mât khẩu 8 - 20 ký tự',
                  },
                ]}
              >
                <Input.Password placeholder={'Nhập mật khẩu'} />
              </Form.Item>
              <Form.Item
                label={'Nhập lại mật khẩu'}
                name={'confirm_password'}
                rules={[
                  { required: true, message: 'Vui lòng nhập mật khẩu' },
                  {
                    type: 'string',
                    min: 8,
                    max: 20,
                    message: 'Mât khẩu 8 - 20 ký tự',
                  },
                ]}
              >
                <Input.Password placeholder={'Nhập lại mật khẩu'} />
              </Form.Item>
              <Divider />
            </div>
          ) : null}

          <SelectTypeAccount
            label={'Vai trò'}
            name={'role'}
            placeholder={'Chọn vai trò'}
            isRequired={true}
            onChange={(value) => {
              setTypeAccount(value)
            }}
          />

          {typeAccount === CONSTANCE.ACCOUNT.OFFICER_PROVINCE ||
          typeAccount === CONSTANCE.ACCOUNT.OFFICER_DISTRICT ||
          typeAccount === CONSTANCE.ACCOUNT.OFFICER_WARD ? (
            <SelectProvincesComponent
              label={'Tỉnh/ thành phố'}
              placeholder={'Chọn tỉnh thành phố'}
              name={'province_id'}
              rules={[{ required: true, message: 'Vui lòng chọn tỉnh thành phố.' }]}
              onChange={(values) => {
                setProvinceId(values)
                form.setFieldsValue({
                  district_id: undefined,
                  ward_id: undefined,
                })
              }}
              // disabled={
              //   // (FUNCTION.GetUserInfo().role <= CONSTANCE.ACCOUNT.OFFICER_PROVINCE && props.type === 'UPDATE') ||
              //   // props.type === 'ADD' || store.user.DFProvince?.id ? true : false
              //   true
              // }
            />
          ) : null}

          {typeAccount === CONSTANCE.ACCOUNT.OFFICER_DISTRICT || typeAccount === CONSTANCE.ACCOUNT.OFFICER_WARD ? (
            <SelectDistrictComponent
              label={'Quận/ huyện'}
              placeholder={'Nhập quận/ huyện'}
              name={'district_id'}
              rules={[{ required: true, message: 'Vui lòng nhập quận/ huyện' }]}
              onChange={(values) => {
                setDistrictId(values)
                form.setFieldsValue({
                  ward_id: undefined,
                })
              }}
              provinceId={provinceId}
              disabled={store.user.DFDistrict?.id ? true : false}
            />
          ) : null}

          {typeAccount === CONSTANCE.ACCOUNT.OFFICER_WARD ? (
            <SelectWardComponent
              label={'Phường/ xã'}
              name={'ward_id'}
              rules={[{ required: true, message: 'Vui lòng nhập phường xã' }]}
              placeholder={'Chọn phường xã'}
              districtId={districtId}
              // disabled={districtId ? false : true}
            />
          ) : null}

          {props.type === 'UPDATE' ? (
            <SelectStatusComponent label={'Trạng thái'} isRequired={true} name={'status'} onChange={(value) => {}} />
          ) : null}
        </Form>
      </Modal>
    </ModalHoc>
  )
}

export default AddUpdateAccount
///test
