import React, { useEffect, useRef } from 'react'
import { Button, Card, Col, Descriptions, Divider, Image, Modal, PageHeader, Row, Space, Spin, Tabs, Tag } from 'antd'
import store from './PostDetailStore'
import FUNCTION from '../../../services/Functions'
import { observer } from 'mobx-react'
import { RenderGender } from '../../select/SelectGender'
import history from '../../../services/history'
import { ADMIN_ROUTER } from '../../../router/AdminRouter'
import { Moment } from '../../../services/Moment'
import CONSTANCE from '../../../services/Constance'
import RenderVideoComponent from '../../../commons/render-video/RenderVideoComponent'
import { ButtonStatus, RenderStatus } from '../../select/SelectStatusComponent'
import FormReject from '../component/FormReject'
import { RenderPostStatus } from '../../select/SelectPost'
import ShowMoreText from 'react-show-more-text'
import ICON from '../../../commons/icon/Icon'
import ListDonator from './ListDonator'
import AppStore from '../../app-store/AppStore'
import FormApprove from '../component/FormApprove'

const PostDetail = observer(() => {
  useEffect(() => {
    store.getDetailPost(FUNCTION.GetId())
  }, [])

  const openModalRejectRef = useRef<Function>()
  const modalApproveRef = useRef<Function>()

  const tabs = FUNCTION.GetTabsUrl() || 'tab1'
  const role = FUNCTION.GetUserInfo().role

  const RenderOption = () => {
    const userInfo = FUNCTION.GetUserInfo()

    const RenderBtnDeny = () => {
      if (
        (userInfo.role === CONSTANCE.ACCOUNT.OFFICER_PROVINCE || userInfo.role === CONSTANCE.ACCOUNT.ADMIN) &&
        store.detailPost?.status !== CONSTANCE.POST.POST_STATUS.DONE &&
        store.detailPost?.status !== CONSTANCE.POST.POST_STATUS.DENY
      ) {
        return (
          <Button
            icon={<span className={'mr-2'}>{ICON.BUTTON.CANCEL}</span>}
            type={'primary'}
            danger
            onClick={() => openModalRejectRef.current && openModalRejectRef.current()}
          >
            Từ chối
          </Button>
        )
      }

      if (
        userInfo.role === CONSTANCE.ACCOUNT.OFFICER_DISTRICT &&
        store.detailPost?.status !== CONSTANCE.POST.POST_STATUS.DONE &&
        store.detailPost?.status !== CONSTANCE.POST.POST_STATUS.DENY &&
        store.detailPost?.status !== CONSTANCE.POST.POST_STATUS.DISTRICT_CONFIRM
      ) {
        return (
          <Button
            icon={<span className={'mr-2'}>{ICON.BUTTON.CANCEL}</span>}
            type={'primary'}
            danger
            onClick={() => openModalRejectRef.current && openModalRejectRef.current()}
          >
            Từ chối
          </Button>
        )
      }
    }

    const RenderBtnEdit = () => {
      // if (role === CONSTANCE.ACCOUNT.OFFICER_WARD) {
      //   if (
      //     store.detailPost?.status === CONSTANCE.POST.POST_STATUS.USER_POST ||
      //     store.detailPost?.is_update === CONSTANCE.POST.REQUEST_EDIT.DISTRICT_REQUEST_UPDATE
      //   ) {
      //     return (
      //       <Button
      //         type={'primary'}
      //         onClick={(event) => {
      //           history.push(ADMIN_ROUTER.POST_ADD_UPDATE.path + `?index=${FUNCTION.GetId()}`)
      //         }}
      //         icon={<span className={'mr-2'}>{ICON.BUTTON.EDIT}</span>}
      //       >
      //         Chỉnh sửa
      //       </Button>
      //     )
      //   }
      // }

      if (
        (role === CONSTANCE.ACCOUNT.OFFICER_PROVINCE || role === CONSTANCE.ACCOUNT.ADMIN) &&
        store.detailPost?.status !== CONSTANCE.POST.POST_STATUS.DENY
      ) {
        return (
          <Button
            type={'primary'}
            onClick={(event) => {
              history.push(ADMIN_ROUTER.POST_ADD_UPDATE.path + `?index=${FUNCTION.GetId()}`)
            }}
            icon={<span className={'mr-2'}>{ICON.BUTTON.EDIT}</span>}
          >
            Chỉnh sửa
          </Button>
        )
      }

      if (role === CONSTANCE.ACCOUNT.OFFICER_DISTRICT) {
        if (
          (store.detailPost?.status === CONSTANCE.POST.POST_STATUS.USER_POST ||
            (store.detailPost?.status === CONSTANCE.POST.POST_STATUS.DISTRICT_CONFIRM &&
              store.detailPost?.is_update === CONSTANCE.POST.REQUEST_EDIT.PROVINCE_REQUEST_UPDATE) ||
            store.detailPost?.is_update === CONSTANCE.POST.REQUEST_EDIT.PROVINCE_REQUEST_UPDATE) &&
          store.detailPost?.is_update !== CONSTANCE.POST.REQUEST_EDIT.DISTRICT_REQUEST_UPDATE
        ) {
          return (
            <Button
              type={'primary'}
              onClick={(event) => {
                history.push(ADMIN_ROUTER.POST_ADD_UPDATE.path + `?index=${FUNCTION.GetId()}`)
              }}
              icon={<span className={'mr-2'}>{ICON.BUTTON.EDIT}</span>}
            >
              Chỉnh sửa
            </Button>
          )
        }
      }
    }

    const RenderBtnSendRequest = () => {
      if (
        userInfo.role === CONSTANCE.ACCOUNT.OFFICER_DISTRICT &&
        store.detailPost?.status === CONSTANCE.POST.POST_STATUS.USER_POST &&
        store.detailPost.is_update !== CONSTANCE.POST.REQUEST_EDIT.DISTRICT_REQUEST_UPDATE
      )
        return (
          <Button
            type={'primary'}
            onClick={(event) => {
              Modal.confirm({
                title: 'Bạn có muốn gửi yêu cầu phê duyệt thái tin đăng này không?',
                okText: 'Xác nhận',
                onOk: () => {
                  store.putChangeStatus({
                    status: CONSTANCE.POST.POST_STATUS.DISTRICT_CONFIRM,
                    post_ids: [FUNCTION.GetId()],
                    reason: '',
                  })
                },
              })
            }}
            icon={<span className={'mr-2'}>{ICON.MENU_ICON.APPROVE}</span>}
          >
            Gửi yêu cầu phê duyệt
          </Button>
        )
    }

    const RenderBtnChangeStatusActive = () => {
      if (
        (userInfo.role === CONSTANCE.ACCOUNT.ADMIN || userInfo.role === CONSTANCE.ACCOUNT.OFFICER_PROVINCE) &&
        store.detailPost?.status === CONSTANCE.POST.POST_STATUS.DONE
      ) {
        return (
          <div
            onClick={(event) => {
              Modal.confirm({
                title: 'Bạn có muốn ngưng hoạt động tin đăng này không',
                okText: 'Xác nhận',
                onOk: () => {
                  store.changeStatusActivePost(FUNCTION.GetId())
                },
              })
            }}
          >
            <ButtonStatus status={store.detailPost?.is_status as number} />
          </div>
        )
      }
    }

    const RenderBtnApprove = () => {
      if (
        (userInfo.role === CONSTANCE.ACCOUNT.ADMIN || userInfo.role === CONSTANCE.ACCOUNT.OFFICER_PROVINCE) &&
        store.detailPost?.status !== CONSTANCE.POST.POST_STATUS.DENY &&
        store.detailPost?.status !== CONSTANCE.POST.POST_STATUS.DONE &&
        store.detailPost?.is_update === CONSTANCE.POST.REQUEST_EDIT.NOT_REQUEST
      ) {
        return (
          <Button
            type={'primary'}
            icon={<span className={'mr-2'}>{ICON.MENU_ICON.APPROVE}</span>}
            onClick={(event) => {
              if (store.detailPost?.end_date) {
                Modal.confirm({
                  title: 'Bạn có muốn phê duyệt tin đăng này không?',
                  okText: 'Xác nhận',
                  onOk: () => {
                    store.putChangeStatus({
                      status: CONSTANCE.POST.POST_STATUS.DONE,
                      post_ids: [FUNCTION.GetId()],
                      reason: '',
                    })
                    AppStore.getCount()
                  },
                })
              } else {
                modalApproveRef.current && modalApproveRef.current()
              }
            }}
          >
            Phê duyệt
          </Button>
        )
      }
    }

    const RenderBtnDelete = () => {
      if (userInfo.role === CONSTANCE.ACCOUNT.ADMIN || userInfo.role === CONSTANCE.ACCOUNT.OFFICER_PROVINCE) {
        return (
          <Button
            type={'primary'}
            danger
            icon={<span className={'mr-2'}>{ICON.BUTTON.DELETE}</span>}
            onClick={() => {
              Modal.confirm({
                title: 'Bạn có chắc muốn xóa bài đăng này',
                okText: 'Xác nhận',
                onOk: () => store.deletePost(FUNCTION.GetId()),
              })
            }}
          >
            Xóa
          </Button>
        )
      }
    }

    const RenderBtnSendRequestEdit = () => {
      if (
        userInfo.role === CONSTANCE.ACCOUNT.OFFICER_DISTRICT &&
        store.detailPost?.status !== CONSTANCE.POST.POST_STATUS.DONE &&
        store.detailPost?.status !== CONSTANCE.POST.POST_STATUS.DENY &&
        store.detailPost?.status !== CONSTANCE.POST.POST_STATUS.DISTRICT_CONFIRM &&
        store.detailPost?.is_update !== CONSTANCE.POST.REQUEST_EDIT.DISTRICT_REQUEST_UPDATE
      )
        return (
          <Button
            type={'primary'}
            onClick={(event) => {
              Modal.confirm({
                title: 'Bạn có muốn gửi yêu cầu chỉnh sửa tin đăng này không?',
                okText: 'Xác nhận',
                onOk: () => {
                  store.sendRequestEdit(store.detailPost?.id as number)
                },
              })
            }}
          >
            Gửi yêu cầu chỉnh sửa
          </Button>
        )
      if (
        (userInfo.role === CONSTANCE.ACCOUNT.OFFICER_PROVINCE || userInfo.role === CONSTANCE.ACCOUNT.ADMIN) &&
        store.detailPost?.status !== CONSTANCE.POST.POST_STATUS.DONE &&
        store.detailPost?.status !== CONSTANCE.POST.POST_STATUS.DENY &&
        store.detailPost?.is_update === CONSTANCE.POST.REQUEST_EDIT.NOT_REQUEST
      ) {
        return (
          <Button
            type={'primary'}
            onClick={(event) => {
              Modal.confirm({
                title: 'Bạn có muốn gửi yêu chỉnh sửa tin đăng này không?',
                okText: 'Xác nhận',
                onOk: () => {
                  store.sendRequestEdit(store.detailPost?.id as number)
                },
              })
            }}
          >
            Gửi yêu cầu chỉnh sửa
          </Button>
        )
      }
    }

    return (
      <Space>
        {RenderBtnDeny()}
        {RenderBtnDelete()}
        {RenderBtnChangeStatusActive()}
        {RenderBtnEdit()}
        {RenderBtnSendRequestEdit()}
        {RenderBtnSendRequest()}
        {RenderBtnApprove()}
      </Space>
    )
  }

  const RenderTextRequestEdit = () => {
    if (store.detailPost?.is_update === CONSTANCE.POST.REQUEST_EDIT.PROVINCE_REQUEST_UPDATE) {
      return <span className={'text-rose-700'}>Tỉnh yêu cầu chỉnh sửa</span>
    }

    if (store.detailPost?.is_update === CONSTANCE.POST.REQUEST_EDIT.DISTRICT_REQUEST_UPDATE) {
      return <span className={'text-rose-700'}>Huyện yêu cầu chỉnh sửa</span>
    }
  }

  return (
    <div>
      <PageHeader
        title={'Chi tiết tin đăng'}
        onBack={(e) => history.push(ADMIN_ROUTER.POST.path)}
        extra={RenderOption()}
      />

      <div className={'box'}>
        <Tabs defaultActiveKey={tabs} onChange={(tabs) => FUNCTION.SetSearchPrams('tabs', tabs)}>
          <Tabs.TabPane tab={'Thông tin tin đăng'} key={'tab1'}>
            <Spin spinning={store.loading.getDetail}>
              <Card title={'Thông tin địa chỉ nhận đạo'} bordered={false}>
                <Descriptions column={2}>
                  <Descriptions.Item label={'Họ tên'}>{store.detailPost?.name}</Descriptions.Item>
                  <Descriptions.Item label={'Số điện thoại'}>{store.detailPost?.phone}</Descriptions.Item>
                  <Descriptions.Item label={'Địa chỉ'}>
                    {store.detailPost?.DFProvince && store.detailPost?.DFProvince.name}
                    {store.detailPost?.DFDistrict && ' - ' + store.detailPost?.DFDistrict.name}
                    {store.detailPost?.DFWard && ' - ' + store.detailPost?.DFWard.name}
                  </Descriptions.Item>
                  <Descriptions.Item label={'Địa chỉ chi tiết'}>
                    {store.detailPost?.address ? store.detailPost.address : '___'}
                  </Descriptions.Item>
                  <Descriptions.Item label={'Giới tính'}>
                    {RenderGender(store.detailPost?.gender as number)}
                  </Descriptions.Item>
                  <Descriptions.Item label={'Năm sinh'}>
                    {store.detailPost?.year_of_birth ? Moment.getDate(store.detailPost.year_of_birth, 'YYYY') : '___'}
                  </Descriptions.Item>
                  <Descriptions.Item label={'Trạng thái bài đăng'}>
                    {RenderPostStatus(store.detailPost?.status as number)}
                  </Descriptions.Item>

                  {store.detailPost?.district_post && (
                    <Descriptions.Item label={''}>
                      {store.detailPost?.district_post ? <span className={'text-rose-700'}>Huyện đã duyệt</span> : null}
                      {store.detailPost?.district_post === 1 &&
                        store.detailPost?.DonateDequestHistory.filter((e: any, i: number) =>
                          e?.User?.role === 3 ? true : false
                        ).map((e: any, i: number) => {
                          if (!i)
                            return (
                              <div className="ml-[20px]">{'(' + e?.User?.name + ': ' + e?.User?.user_name + ')'}</div>
                            )
                        })}
                    </Descriptions.Item>
                  )}

                  <Descriptions.Item label={'Trạng thái hoạt động'}>
                    {RenderStatus(store.detailPost?.is_status as number)}
                  </Descriptions.Item>

                  <Descriptions.Item label={'Phân nhóm'}>{store.detailPost?.Group?.name || '---'}</Descriptions.Item>

                  <Descriptions.Item label={'Đối tượng'}>
                    <div className="w-full mb-1">
                      {store.detailPost?.DonateCategoryDetails.map((value) => {
                        if (value.type === 1) {
                          return (
                            <Tag style={{ marginBottom: '5px' }} color={'purple'}>
                              {value.category_name}
                            </Tag>
                          )
                        }
                      })}
                    </div>
                  </Descriptions.Item>

                  <Descriptions.Item label={'Nhu cầu'}>
                    <div className="w-full mb-1">
                      {store.detailPost?.DonateCategoryDetails.map((value) => {
                        if (value.type === 2) {
                          return (
                            <Tag style={{ marginBottom: '5px' }} color={'pink'}>
                              {value.category_name}
                            </Tag>
                          )
                        }
                      })}
                    </div>
                  </Descriptions.Item>

                  <Descriptions.Item label={'Ngày hết hạn tin đăng'}>
                    {store.detailPost?.end_date ? Moment.getDate(store.detailPost.end_date) : '_ _ _'}
                  </Descriptions.Item>

                  {store.detailPost?.is_update ? (
                    <Descriptions.Item>{RenderTextRequestEdit()}</Descriptions.Item>
                  ) : null}
                </Descriptions>
                <Divider />
                <Row gutter={[16, 4]} justify="end">
                  <Col md={12} lg={12} sm={24}>
                    <Descriptions column={1}>
                      <Descriptions.Item label={'Tiêu đề'}>{store.detailPost?.title}</Descriptions.Item>
                      <Descriptions.Item label={'Nội dung'}>
                        <ShowMoreText lines={5} more={'Xem thêm'} less={'Ẩn bớt'} width={400}>
                          {store.detailPost?.content}
                        </ShowMoreText>
                      </Descriptions.Item>
                      {store.getDataMedia(store.detailPost ? store.detailPost.DonateRequestMedia : []).image.length >
                        0 && (
                        <Descriptions.Item label={'Ảnh'}>
                          <Space>
                            {store
                              .getDataMedia(store.detailPost ? store.detailPost.DonateRequestMedia : [])

                              .image.map((value) => {
                                return <Image style={{ height: 150, width: 200, objectFit: 'cover' }} src={value} />
                              })}
                          </Space>
                        </Descriptions.Item>
                      )}

                      {store.getDataMedia(store.detailPost ? store.detailPost.DonateRequestMedia : []).video.length >
                        0 && (
                        <Descriptions.Item label={'Video'}>
                          <Space>
                            {store
                              .getDataMedia(store.detailPost ? store.detailPost.DonateRequestMedia : [])

                              .video.map((value) => (
                                <div>{<RenderVideoComponent src={value} />}</div>
                              ))}
                          </Space>
                        </Descriptions.Item>
                      )}
                    </Descriptions>
                  </Col>
                  <Col md={12} lg={12} sm={24}>
                    <Descriptions column={1}>
                      <Descriptions.Item label={'Ngân hàng'}>
                        {store.detailPost && store.detailPost?.BankInfos.length > 0
                          ? store.detailPost.BankInfos[0].DFBank.name
                          : '___'}
                      </Descriptions.Item>
                      <Descriptions.Item label={'Chi nhánh'}>
                        {store.detailPost && store.detailPost?.BankInfos.length > 0
                          ? store.detailPost.BankInfos[0].branch_name
                          : '___'}
                      </Descriptions.Item>
                      <Descriptions.Item label={'Tên tài khoản'}>
                        {store.detailPost && store.detailPost?.BankInfos.length > 0
                          ? store.detailPost.BankInfos[0].account_name
                          : '___'}
                      </Descriptions.Item>
                      <Descriptions.Item label={'Số tài khoản'}>
                        {store.detailPost && store.detailPost?.BankInfos.length > 0
                          ? store.detailPost.BankInfos[0].account_number
                          : '___'}
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>

                  {store.detailPost && store.detailPost?.BankInfos.length > 1 && (
                    <Col md={12} lg={12} sm={24}>
                      <Descriptions column={1}>
                        <Descriptions.Item label={'Ngân hàng'}>
                          {store.detailPost && store.detailPost?.BankInfos.length > 1
                            ? store.detailPost.BankInfos[1].DFBank.name
                            : '___'}
                        </Descriptions.Item>
                        <Descriptions.Item label={'Chi nhánh'}>
                          {store.detailPost && store.detailPost?.BankInfos.length > 1
                            ? store.detailPost.BankInfos[1].branch_name
                            : '___'}
                        </Descriptions.Item>
                        <Descriptions.Item label={'Tên tài khoản'}>
                          {store.detailPost && store.detailPost?.BankInfos.length > 1
                            ? store.detailPost.BankInfos[1].account_name
                            : '___'}
                        </Descriptions.Item>
                        <Descriptions.Item label={'Số tài khoản'}>
                          {store.detailPost && store.detailPost?.BankInfos.length > 1
                            ? store.detailPost.BankInfos[1].account_number
                            : '___'}
                        </Descriptions.Item>
                      </Descriptions>
                    </Col>
                  )}
                </Row>
              </Card>
            </Spin>
          </Tabs.TabPane>
          <Tabs.TabPane tab={'Thông tin người từ thiện'} key={'tab2'}>
            <ListDonator />
          </Tabs.TabPane>
        </Tabs>
      </div>
      <FormReject modalReject={(fn) => (openModalRejectRef.current = fn)} />
      <FormApprove modalApprove={(fn) => (modalApproveRef.current = fn)} />
    </div>
  )
})

export default PostDetail
