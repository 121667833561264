import React, { useState } from 'react'
import { Checkbox, Pagination, Spin } from 'antd'
import ICON from '../../commons/icon/Icon'
import { observer } from 'mobx-react'
import NotificationHeaderStore from './NotificationHeaderStore'
import notificationHeaderStore from './NotificationHeaderStore'
import CONSTANCE from '../../services/Constance'
import { turnOffAllNotification } from './NotificationApi'
export const WATCHED_TYPE = {
  NOT_WATCHED: 1, // chưa đọc
  WATCHED: 2, // đã đọc
}
interface ChildProps {
  resetNotification: () => void
  countNotification: number
}
const NotificationHeader: React.FC<ChildProps> = observer((props) => {
  const [checkAllNotifi, setCheckAllNotifi] = useState<boolean>(false)
  const onChange = () => {
    if (props.countNotification > 0) {
      setCheckAllNotifi(!checkAllNotifi)
    }
  }

  const handleCheckAllNotificaion = async () => {
    if (checkAllNotifi) {
      await turnOffAllNotification()
      props.resetNotification()
      setCheckAllNotifi(false)
    }
  }
  return (
    <div className={'h-128 w-96'}>
      <div className="flex justify-between">
        <div className={'text-rose-700 text-base cursor-default  border-b border-stone-200 p-2'}>Thông báo</div>
        <div className={'text-rose-700 text-base border-b border-stone-200 p-2 flex'}>
          <Checkbox
            style={{ marginRight: '4px' }}
            checked={checkAllNotifi}
            value={checkAllNotifi}
            onChange={onChange}
          />
          <p className="mb-0 hover:text-blue-600" onClick={handleCheckAllNotificaion}>
            Đọc tất cả
          </p>
        </div>
      </div>

      <div className={'overflow-y-scroll overflow-x-hidden'} style={{ height: 'calc(100% - 83px)' }}>
        <Spin spinning={NotificationHeaderStore.loading}>
          {NotificationHeaderStore.listNotification.map((value) => {
            return (
              <div
                onClick={(event) => NotificationHeaderStore.readNotification(value.id, value)}
                className={'flex items-center p-3 hover:bg-stone-100 rounded-md border-b border-stone-200'}
              >
                {value.NotificationPushes.length === 0 ? (
                  <div className={'mr-3'}>
                    <i className="fas fa-circle text-rose-600"></i>
                  </div>
                ) : null}

                <div className={'mr-3 text-2xl'}>{ICON.HEADER_ICON.NOTIFICATION}</div>
                <div>
                  <div
                    style={{
                      display: '-webkit-box',
                      WebkitLineClamp: 1,
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                    }}
                    className={
                      'font-bold text-base whitespace-nowrap  text-ellipsis  line-clamp-3 overflow-hidden text-stone-500 truncate'
                    }
                  >
                    {value.title}
                  </div>
                  <div
                    style={{
                      display: '-webkit-box',
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                    }}
                    // className={'text-red-600'}
                  >
                    {value.content}
                  </div>
                </div>
              </div>
            )
          })}
          {/*<div className={'w-full flex justify-center items-center'} style={{ height: '100%' }}>*/}
          {/*  <Empty description={'Không có thông báo mới nào.'} />*/}
          {/*</div>*/}
        </Spin>
      </div>

      <div className={'border-t border-stone-200 p-2 flex items-center justify-center'}>
        <Pagination
          size={'small'}
          pageSize={CONSTANCE.LIMIT}
          showSizeChanger={false}
          total={NotificationHeaderStore.total}
          current={notificationHeaderStore.page}
          onChange={(page) => {
            NotificationHeaderStore.page = page
            NotificationHeaderStore.getListNotification()
          }}
        />
      </div>
    </div>
  )
})

export default NotificationHeader
