import React, { useEffect } from 'react'
import { Affix, Button, Col, DatePicker, Input, PageHeader, Row, Space, Table, Tag } from 'antd'
import TableHoc from '../../commons/HOC/TableHOC'
import { IColumn } from '../../services/Interfaces'
import { RenderStatus } from '../select/SelectStatusComponent'
import { Moment } from '../../services/Moment'
import RangePickerHoc from '../../commons/HOC/RangePickerHOC'
import ICON from '../../commons/icon/Icon'
import CONSTANCE from '../../services/Constance'
import store from './PostStore'
import { observer } from 'mobx-react'
import FUNCTION from '../../services/Functions'
import { IResDataPost } from './PostInterface'
import { RenderDistrictApproveTag, RenderPostStatus, SelectPostStatus } from '../select/SelectPost'
import history from '../../services/history'
import { ADMIN_ROUTER } from '../../router/AdminRouter'

const columns: IColumn[] = [
  {
    title: 'STT',
    key: 'STT',
    dataIndex: 'STT',
    width: 80,
    align: 'center',
    render: (STT: number, row, index) => <div>{STT}</div>,
  },
  {
    title: 'Tiêu đề',
    key: 'title',
    dataIndex: 'title',
    render: (title: string, row, index) => (
      <div
        className={'cursor-pointer hover:text-blue-700'}
        onClick={(event) => history.push(ADMIN_ROUTER.POST_DETAIL.path + `?index=${row.id}`)}
      >
        {title}
      </div>
    ),
  },
  {
    title: 'Trạng thái phê duyệt',
    key: 'status',
    dataIndex: 'status',
    render: (status, row, index) => (
      <div>
        {/* && FUNCTION.GetUserInfo().role === CONSTANCE.ACCOUNT.OFFICER_DISTRICT */}
        {row.is_update === 1 ? (
          <Tag color={'orange'}>Yêu cầu chỉnh sửa lại</Tag>
        ) : (
          <div className={'inline-block'}>{RenderPostStatus(status)}</div>
        )}

        {row.district_post &&
        (status === CONSTANCE.POST.POST_STATUS.USER_POST || status === CONSTANCE.POST.POST_STATUS.DISTRICT_CONFIRM) ? (
          <div className={'inline-block'}>{RenderDistrictApproveTag(row.district_post)}</div>
        ) : null}
        {row?.district_post === 1 &&
          row?.DonateDequestHistory.filter((e: any, i: number) => (e?.User?.role === 3 ? true : false)).map(
            (e: any, i: number) => {
              if (!i)
                return (
                  <div className="text-[12px] text-red-500  mt-[3px]">
                    {'(' + e?.User?.name + ': ' + e?.User?.user_name + ')'}
                  </div>
                )
            }
          )}
      </div>
    ),
  },
  {
    title: 'Người đăng',
    key: 'name',
    dataIndex: 'name',
    render: (name: string, row, index) => <div>{(row.User && row.User.name) || '_ _ _'}</div>,
  },
  {
    title: 'Trạng thái hoạt động',
    key: 'is_status',
    dataIndex: 'is_status',
    render: (is_status: number, row, index) => <div>{RenderStatus(is_status)}</div>,
  },
  {
    title: 'Ngày tạo',
    key: 'create_at',
    dataIndex: 'create_at',
    render: (create_at, row, index) => <div>{Moment.getDate(create_at)}</div>,
  },

  {
    title: 'Thao tác',
    key: 'id',
    dataIndex: 'id',
    align: 'center',
    width: 150,
    render: (id: number, row: IResDataPost, index) => (
      <div>
        <Space>
          <span
            className={'icon-detail'}
            onClick={(event) => history.push(ADMIN_ROUTER.POST_DETAIL.path + `?index=${row.id}`)}
          >
            {ICON.DETAIL}
          </span>
        </Space>
      </div>
    ),
  },
]

const Post = observer(() => {
  useEffect(() => {
    store.loading.getList = true
    const idTimeout = setTimeout(() => {
      store.getListPost()
    }, 500)
    return () => clearTimeout(idTimeout)
  }, [store.payload.page, store.payload.search, store.payload.status, store.payload.startDate, store.payload.endDate])

  useEffect(() => {
    return () => {
      store.payload = {
        page: CONSTANCE.DEFAULT_PAGE,
        limit: CONSTANCE.LIMIT,
      }
    }
  }, [])

  return (
    <div>
      <PageHeader
        title={'Quản lý tin đăng'}
        extra={
          <div>
            <Space>
              <Button
                type={'primary'}
                icon={<span className={'mr-2'}>{ICON.BUTTON.ADD}</span>}
                onClick={(event) => history.push(ADMIN_ROUTER.POST_ADD_UPDATE.path)}
              >
                Thêm mới
              </Button>
            </Space>
          </div>
        }
      />

      <Affix offsetTop={CONSTANCE.OFFSET_TOP}>
        <div className={'box'}>
          <Row gutter={[16, 4]}>
            <Col lg={6} md={6} sm={24}>
              <Input
                placeholder={'Nhập tên người đăng hoặc tiêu đề'}
                allowClear={true}
                onChange={(event) => {
                  store.payload.search = event.target.value
                  store.payload.page = CONSTANCE.DEFAULT_PAGE
                }}
              />
            </Col>
            <Col lg={6} md={6} sm={24}>
              <SelectPostStatus
                onChange={(values) => {
                  store.payload.status = values
                  store.payload.page = CONSTANCE.DEFAULT_PAGE
                }}
                placeholder={'Trạng thái'}
              />
            </Col>
            <Col lg={6} md={6} sm={24}>
              <RangePickerHoc
                onChange={(stringDate) => {
                  store.payload.startDate = stringDate[0]
                  store.payload.endDate = stringDate[1]
                  store.payload.page = CONSTANCE.DEFAULT_PAGE
                }}
              >
                <DatePicker.RangePicker />
              </RangePickerHoc>
            </Col>
            <Col lg={6} md={6} sm={24}>
              <div className={'mt-1 flex justify-end'}>Kết quả lọc: {store.total}</div>
            </Col>
          </Row>
        </div>
      </Affix>

      <div className={'box'}>
        <TableHoc>
          <Table
            columns={columns}
            loading={store.loading.getList}
            dataSource={store.listPost.map((value, index) => {
              return { ...value, STT: FUNCTION.GetIndexTable(store.payload.page, index), key: value.id }
            })}
            pagination={{
              onChange: (page) => (store.payload.page = page),
              total: store.total,
              current: store.payload.page,
            }}
            onRow={(record) => {
              return {
                onClick: () => history.push(ADMIN_ROUTER.POST_DETAIL.path + `?index=${record.id}`),
              }
            }}
          />
        </TableHoc>
      </div>
    </div>
  )
})

export default Post
