import { makeAutoObservable } from 'mobx'
import { IPayloadCustomer, IResDataCustomer } from './CustomerInterface'
import CONSTANCE from '../../services/Constance'
import { getListCustomerAPI, putChangeStatusAPI } from './CustomerApi'
import { Notification } from '../../commons/notification/Notification'

class CustomerStore {
  loading = {
    getList: false,
  }
  listCustomer: IResDataCustomer[] = []
  payload: IPayloadCustomer = {
    page: CONSTANCE.DEFAULT_PAGE,
    limit: CONSTANCE.LIMIT,
  }
  total: number = 0

  constructor() {
    makeAutoObservable(this)
  }

  async getListCustomer() {
    try {
      this.loading.getList = true
      const res = await getListCustomerAPI({
        ...this.payload,
        search: this.payload.search?.trim(),
        address: this.payload.address?.trim(),
      })
      if (res.body.status) {
        this.listCustomer = res.body.data
        this.total = res.body.paging.totalItemCount
      }
    } catch (e) {
      console.error(e)
    } finally {
      this.loading.getList = false
    }
  }

  async changeStatusActive(id: number) {
    try {
      const res = await putChangeStatusAPI(id)
      if (res.body.status) {
        Notification.PushNotification('SUCCESS', 'Thay đổi trạng thái hoạt động thành công')
        this.getListCustomer()
      }
    } catch (e) {
      console.error(e)
    }
  }
}

const store = new CustomerStore()

export default store
