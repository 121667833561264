import { Baservices, IApiResponse } from '../../services/Basevices'
import { IPayloadNew, IReqNew, IResDetailNews, IResNews } from './NewsInterfaces'
import { IResBody } from '../../services/Interfaces'

export const getListNewsAPI = (payload: IPayloadNew): Promise<IApiResponse<IResNews>> => {
  return Baservices.getMethod(`/web/banner/list`, payload)
}

export const postNewsAPI = (reqData: IReqNew): Promise<IApiResponse<IResBody>> => {
  return Baservices.postMethod(`/web/banner/create`, reqData)
}

export const deleteNewsAPI = (id: number): Promise<IApiResponse<IResBody>> => {
  return Baservices.deleteMethod(`/web/banner/${id}/delete`)
}

export const getDetailNewAPI = (id: number): Promise<IApiResponse<IResDetailNews>> => {
  return Baservices.getMethod(`/web/banner/${id}`)
}

export const putNewAPI = (id: number, reqData: IReqNew) => {
  return Baservices.putMethod(`/web/banner/${id}/update`, reqData)
}
