import React, { useEffect, useState } from 'react'
import ModalHoc from '../HOC/ModalHOC'
import { Form, Input, Modal } from 'antd'
import { useForm } from 'antd/es/form/Form'

interface IForm {
  title?: string
  fn?: Function
  fnOpenModal?: (fn: Function) => any
}

const FormReject: React.FC<IForm> = ({ title, fn, fnOpenModal }) => {
  const [visible, setVisible] = useState<boolean>(false)
  const [form] = useForm()

  const onOpen = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  const onFinish = async (values: { reason: string }) => {
    if (fn) {
      await fn(values)
      onClose()
    }
  }

  useEffect(() => {
    fnOpenModal && fnOpenModal(onOpen)
  }, [])

  return (
    <ModalHoc>
      <Modal visible={visible} title={title} onCancel={onClose} onOk={(e) => form.submit()}>
        <Form layout={'vertical'} onFinish={onFinish} form={form}>
          <Form.Item label={'Lý do'} name={'reason'} rules={[{ required: true, message: 'Vui lòng nhập lý do' }]}>
            <Input placeholder={'Nhập lý do'} />
          </Form.Item>
        </Form>
      </Modal>
    </ModalHoc>
  )
}

export default FormReject
