import { makeAutoObservable } from 'mobx'
import { IPayloadNew, IReqNew, IResDataNews } from './NewsInterfaces'
import CONSTANCE from '../../services/Constance'
import { deleteNewsAPI, getDetailNewAPI, getListNewsAPI, postNewsAPI, putNewAPI } from './NewsAPI'
import { Notification } from '../../commons/notification/Notification'
import history from '../../services/history'
import { ADMIN_ROUTER } from '../../router/AdminRouter'

class NewStore {
  loading = {
    getList: false,
    submitForm: false,
    getDetail: false,
  }
  payload: IPayloadNew = {
    page: CONSTANCE.DEFAULT_PAGE,
    limit: CONSTANCE.LIMIT,
  }
  listNews: IResDataNews[] = []
  total = 0

  detailNew: IResDataNews | undefined = undefined

  constructor() {
    makeAutoObservable(this)
  }

  async getListNews() {
    try {
      this.loading.getList = true
      const res = await getListNewsAPI({ ...this.payload, search: this.payload.search?.trim() })
      if (res.body.status) {
        this.listNews = res.body.data
        this.total = res.body.paging.totalItemCount
      }
    } catch (e) {
      console.error(e)
    } finally {
      this.loading.getList = false
    }
  }

  async deleteNews(id: number) {
    try {
      const res = await deleteNewsAPI(id)
      if (res.body.status) {
        Notification.PushNotification('SUCCESS', 'Xóa tin tức thành công')
        this.getListNews()
      }
    } catch (e) {
      console.error(e)
    }
  }

  async postNews(reqData: IReqNew) {
    try {
      const res = await postNewsAPI(reqData)
      if (res.body.status) {
        Notification.SweetAlert('SUCCESS', 'Thêm mới tin tức thành công')
        history.push(ADMIN_ROUTER.NEWS.path)
      }
    } catch (e) {
      console.error(e)
    }
  }

  async getDetailNews(id: number) {
    try {
      this.loading.getDetail = true
      const res = await getDetailNewAPI(id)
      if (res.body.status) {
        this.detailNew = res.body.data
      }
    } catch (e) {
      console.error(e)
    } finally {
      this.loading.getDetail = false
    }
  }

  async putNews(id: number, reqData: IReqNew) {
    try {
      this.loading.submitForm = true
      const res = await putNewAPI(id, reqData)
      if (res.body.status) {
        Notification.PushNotification('SUCCESS', 'Cập nhật tin đăng thành công')
        history.push(ADMIN_ROUTER.NEWS.path)
      }
    } catch (e) {
      console.error(e)
    } finally {
      this.loading.submitForm = false
    }
  }
}

const store = new NewStore()

export default store
