import styled from 'styled-components'
import { Collapse } from 'antd'
import { COLOR } from '../modules/layout/VariablesStyle'

export const CollapseStyles = styled(Collapse)`
  .ant-collapse-item {
    background-color: ${COLOR.BLUE};

    .ant-collapse-header {
      color: ${COLOR.WHITE};
      font-family: OpenSans-Semibold, sans-serif;
      font-size: 15px;
    }
  }
`
