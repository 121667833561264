import moment from 'moment'
import CONSTANCE from './Constance'
import { IResLoginData } from '../modules/login/Interfaces'
import history from '../services/history'

export interface IInputSplitArray {
  newArray: { [key: string]: any }[]
  mergeArray: { [key: string]: any }[]
  deleteArray: { [key: string]: any }[]
}

class FUNCTION {
  public static SplitMedia(data: any[]): {
    image: any[]
    video: any[]
  } {
    let image: any[] = []
    let video: any[] = []

    data &&
      data.map((value) => {
        if (value.type === CONSTANCE.MEDIA.IMAGES) {
          image.push(value)
        } else {
          video.push(value)
        }
      })

    return {
      image: image,
      video: video,
    }
  }

  public static SplitArray = (
    inputArray: { [key: string]: any }[],
    defaultArray: { [key: string]: any }[],
    key: string,
    getValueMerge: 'inputArray' | 'defaultArray' = 'inputArray'
  ): IInputSplitArray => {
    const result: IInputSplitArray = {
      newArray: [],
      mergeArray: [],
      deleteArray: [],
    }

    const newArray = inputArray.filter((itemInputArr) => {
      return !defaultArray.find((itemDefaultArr) => itemDefaultArr[key] === itemInputArr[key])
    })

    const mergeArrayInput = inputArray.filter((itemInputArr) => {
      return defaultArray.find((itemDefaultArr) => itemDefaultArr[key] === itemInputArr[key])
    })

    const mergeArrayDefault = defaultArray.filter((itemDefaultArr) => {
      return inputArray.find((itemInputArr) => itemDefaultArr[key] === itemInputArr[key])
    })

    const deleteArray = defaultArray.filter((itemDefaultArr) => {
      return !mergeArrayInput.find((mergeArray) => itemDefaultArr[key] === mergeArray[key])
    })

    result.newArray = newArray
    result.mergeArray = getValueMerge === 'inputArray' ? mergeArrayInput : mergeArrayDefault
    result.deleteArray = deleteArray
    return result
  }

  public static IsFuture = (time?: string): boolean => {
    if (moment() < moment(time)) {
      return true
    } else {
      return false
    }
  }

  public static GetIndexTable(_page: number, _index: number, _limit?: number) {
    let limit: number = _limit ? _limit : CONSTANCE.LIMIT
    return _page * limit - limit + (_index + 1)
  }

  public static ReplaceWhiteSpace(_value: string): string {
    return _value.replace(/\s/g, '')
  }

  public static SetTokenLocalStorage = (token: string) => {
    localStorage.setItem(process.env.REACT_APP_TOKEN_NAME as string, token)
  }

  public static GetUserInfo = (): IResLoginData => {
    return JSON.parse(localStorage.getItem('userInfo') as string) as IResLoginData
  }

  public static GetId = (): number => {
    console.log('param')

    const searchParams = new URLSearchParams(window.location.search)

    const id: number = Number(searchParams.get('index'))
    return id
  }

  public static GetTabsUrl = (): string => {
    const searchParams = new URLSearchParams(window.location.search)
    const tabs: string = searchParams.get('tabs') as string
    return tabs
  }

  public static SetSearchPrams = (keys: string, values: string): string => {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set(keys, values)
    history.push({
      pathname: window.location.pathname,
      search: searchParams.toString(),
    })
    return searchParams.toString()
  }

  public static GetPromise = (value: boolean): Promise<boolean> => {
    return new Promise((resolve) => resolve(value))
  }
}

export default FUNCTION
