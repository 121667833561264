import {
  IPayloadListDonator,
  IPayloadPost,
  IReqChangeStatusPost,
  IReqPost,
  IResDetailPostAPI,
  IResListDonator,
  IResPost,
} from './PostInterface'
import { Baservices, IApiResponse } from '../../services/Basevices'
import { IResBody } from '../../services/Interfaces'

export const getListPostAPI = (payload: IPayloadPost): Promise<IApiResponse<IResPost>> => {
  return Baservices.getMethod(`/app/post/list`, payload)
}

export const getDetailPostAPI = (id: number): Promise<IApiResponse<IResDetailPostAPI>> => {
  return Baservices.getMethod(`/app/post/request/${id}`)
}

export const postPostAPI = (reqData: IReqPost): Promise<IApiResponse<IResBody>> => {
  return Baservices.postMethod(`/web/post/create`, reqData)
}

export const putPostAPI = (id: number, reqData: IReqPost): Promise<IApiResponse<IResBody>> => {
  return Baservices.putMethod(`/web/post/${id}/update`, reqData)
}

export const deletePostAPI = (id: number): Promise<IApiResponse<IResBody>> => {
  return Baservices.deleteMethod(`/web/post/${id}/delete`)
}

export const putChangeStatusPostAPI = (reqData: IReqChangeStatusPost): Promise<IApiResponse<IResBody>> => {
  return Baservices.putMethod(`/web/post/${reqData.post_ids[0]}/change-status`, reqData)
}

export const putChangeStatusActive = (id: number): Promise<IApiResponse<IResBody>> => {
  return Baservices.putMethod(`/web/post/${id}/change-statusActive`)
}

export const putSendRequestEdit = (id: number): Promise<IApiResponse<IResBody>> => {
  return Baservices.putMethod(`/web/post/{id}/change-request`, { post_ids: [id], status: 1 })
}

export const getListDonatorInPostAPI = (
  postId: number,
  payload: IPayloadListDonator
): Promise<IApiResponse<IResListDonator>> => {
  return Baservices.getMethod(`/web/post/${postId}/listDonator`, payload)
}
