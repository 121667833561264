import React, { useEffect, useState } from 'react'
import ModalHoc from '../../../commons/HOC/ModalHOC'
import { Form, Input, Modal } from 'antd'
import { IReqGroup, IResDataGroup } from './GroupInterface'
import { useForm } from 'antd/es/form/Form'
import store from './GroupStore'
import CONSTANCE from '../../../services/Constance'

interface IForm extends IReqGroup {
  id: number
}

const AddUpdateGroup = (props: { type: 'ADD' | 'UPDATE'; openModal: (fn: Function) => any }) => {
  const [visible, setVisible] = useState<boolean>(false)
  const [form] = useForm()
  const onOpenModal = (record?: IResDataGroup) => {
    setVisible(true)
    if (record) {
      const dataForm: IForm = {
        id: record?.id,
        name: record?.name,
        description: record?.description,
      }
      form.setFieldsValue(dataForm)
    }
  }

  const onCloseModal = () => {
    setVisible(false)
    form.resetFields()
  }

  const onFinish = async (values: IForm) => {
    const reqData: IReqGroup = {
      name: values.name,
      description: values?.description ? values.description : '',
    }
    if (props.type === 'ADD') {
      const result = store.postGroup(reqData)
      if (result) {
        onCloseModal()
      }
    } else {
      const result = store.putGroup(form.getFieldValue('id'), reqData)
      if (result) {
        onCloseModal()
      }
    }
  }

  useEffect(() => {
    props.openModal(onOpenModal)
  }, [])

  return (
    <div>
      <ModalHoc>
        <Modal
          title={'Thêm mới phân nhóm'}
          visible={visible}
          onCancel={onCloseModal}
          confirmLoading={store.loading.submit}
          onOk={() => form.submit()}
        >
          <Form layout={'vertical'} form={form} onFinish={onFinish}>
            <Form.Item
              label={'Tên phân nhóm'}
              name={'name'}
              rules={[
                { required: true, whitespace: true, message: 'Vui lòng nhập tên phân nhóm' },
                { pattern: CONSTANCE.REG.NAME, message: 'Tên phân nhóm không đúng định dạng' },
                {
                  type: 'string',
                  max: 255,
                  message: 'Tên phân nhóm không quá 255 ký tự',
                },
              ]}
            >
              <Input placeholder={'Nhập tên phân nhóm'} />
            </Form.Item>
            <Form.Item
              label={'Mô tả'}
              name={'description'}
              // rules={[
              //   { required: true, whitespace: true, message: 'Vui lòng nhập mô tả' },
              //   {
              //     type: 'string',
              //     max: 255,
              //     message: 'Mô tả không quá 255 ký tự',
              //   },
              // ]}
            >
              <Input.TextArea rows={5} placeholder={'Nhập mô tả'} />
            </Form.Item>
          </Form>
        </Modal>
      </ModalHoc>
    </div>
  )
}

export default AddUpdateGroup
