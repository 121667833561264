import { message, notification } from 'antd'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

export class Notification {
  public static PushNotification(
    _type: 'INFO' | 'WARRING' | 'ERROR' | 'SUCCESS',
    _mes: string,
    _duration: number = 3.5,
    _pathNavigate?: string,
    _onClick?: () => any,
    _titleNotification?: string
  ) {
    if (_type === 'INFO') {
      notification.info({
        message: `${_titleNotification ? _titleNotification : ''}`,
        description: (
          <div
            style={{ whiteSpace: 'nowrap', width: 300, overflow: 'hidden', textOverflow: 'ellipsis' }}
            dangerouslySetInnerHTML={{ __html: _mes }}
          />
        ),
        placement: 'topRight',
        duration: _duration,
        onClick: () => {
          _onClick && _onClick()
        },
      })
    } else if (_type === 'SUCCESS') {
      notification.success({
        message: `Thông báo thành công`,
        description: _mes,
        placement: 'topRight',
        duration: _duration,
        onClick: () => {
          _onClick && _onClick()
        },
      })
    } else if (_type === 'ERROR') {
      notification.error({
        message: `Thông báo lỗi`,
        description: _mes,
        placement: 'topRight',
        duration: _duration,
        onClick: () => {
          _onClick && _onClick()
        },
      })
    } else {
      notification.warning({
        message: `Cảnh báo`,
        description: _mes,
        placement: 'topRight',
        duration: _duration,
        onClick: () => {
          _onClick && _onClick()
        },
      })
    }
  }

  public static PushMassage(
    _type: 'INFO' | 'WARRING' | 'ERROR' | 'SUCCESS',
    _msg: string,
    _duration: number = 1.5,
    _onClose?: () => any
  ) {
    if (_type === 'ERROR') message.error(_msg, _duration, _onClose)
    if (_type === 'SUCCESS') message.success(_msg, _duration, _onClose)
    if (_type === 'INFO') message.info(_msg, _duration, _onClose)
    if (_type === 'WARRING') message.warning(_msg, _duration, _onClose)
  }

  public static SweetAlert(_type: 'INFO' | 'WARRING' | 'ERROR' | 'SUCCESS', _msg: string) {
    const getIcon = () => {
      if (_type === 'SUCCESS') return 'success'
      if (_type === 'INFO') return 'info'
      if (_type === 'WARRING') return 'warning'
      if (_type === 'ERROR') return 'error'
    }

    const getTitle = () => {
      if (_type === 'SUCCESS') return 'Thành công'
      if (_type === 'INFO') return 'Thông báo'
      if (_type === 'WARRING') return 'Cảnh báo'
      if (_type === 'ERROR') return 'Đã  có lỗi sảy ra'
    }

    MySwal.fire({
      icon: getIcon(),
      title: getTitle(),
      text: _msg,
      showConfirmButton: false,
      timerProgressBar: true,
      timer: 3000,
    })
  }
}
