import React, { useEffect, useState } from 'react'
import ICON from '../icon/Icon'

const RenderVideoComponent = (props: { src?: string; onClose?: () => any; height?: number; width?: number }) => {
  const [visible, setVisible] = useState<boolean>(true)

  useEffect(() => {
    setVisible(true)
  }, [props.src])

  return (
    <div>
      {visible && (
        <div style={{ position: 'relative', height: props.height || 200, width: props.width || 300 }}>
          <video
            src={props.src || 'http://dev.ndsapi.winds.vn/uploads/video/video_f0f30db66a43458cae1483af70cd6423.mp4'}
            width={props.width || 300}
            height={props.height || 200}
            style={{ maxWidth: props.width || 300, maxHeight: props.height || 200 }}
            controls
          />
          <span
            style={{
              color: 'red',
              fontSize: 18,
              position: 'absolute',
              top: 0,
              right: 5,
              cursor: 'pointer',
            }}
            onClick={(event) => {
              setVisible(false)
              props.onClose && props.onClose()
            }}
          >
            {ICON.BUTTON.CANCEL}
          </span>
        </div>
      )}
    </div>
  )
}

export default RenderVideoComponent
