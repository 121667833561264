import { Baservices, IApiResponse } from '../../../services/Basevices'
import { IPayloadCategory, IReqCategory, IResCategory } from './CategoryInterfaces'
import { IResBody } from '../../../services/Interfaces'

export const getListCategoryAPI = (payload: IPayloadCategory): Promise<IApiResponse<IResCategory>> => {
  return Baservices.getMethod(`/web/categories/list`, payload)
}

export const postCategoryAPI = (reqData: IReqCategory): Promise<IApiResponse<IResBody>> => {
  console.log('reqData3', reqData)

  return Baservices.postMethod(`/web/categories/createCategory`, reqData)
}

export const putCategoryAPI = (id: number, reqData: IReqCategory): Promise<IApiResponse<IResBody>> => {
  return Baservices.putMethod(`/web/categories/${id}/update`, reqData)
}

export const deleteCategoryAPI = (id: number): Promise<IApiResponse<IResBody>> => {
  return Baservices.deleteMethod(`/web/categories/${id}/delete`)
}
