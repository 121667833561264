import React from 'react'
import { Form, Select, Tag } from 'antd'
import CONSTANCE from '../../services/Constance'

interface IProps {
  onChange: (value: number | undefined) => any
  name?: string
  placeholder?: string
  label?: string
  isRequired?: boolean
  disabled?: boolean
}

const SelectTypeAccount: React.FC<IProps> = ({ onChange, name, placeholder, label, disabled }) => {
  return (
    <Form.Item label={label} name={name} rules={[{ required: true, message: 'Vui lòng chọn loại tài khoản' }]}>
      <Select
        placeholder={placeholder || 'Loại tài khoản'}
        style={{ width: '100%' }}
        onChange={(value) => onChange(value)}
        allowClear={true}
        disabled={disabled}
      >
        {/*<Select.Option value={CONSTANCE.ACCOUNT.ADMIN} key={CONSTANCE.ACCOUNT.ADMIN}>*/}
        {/*  Admin*/}
        {/*</Select.Option>*/}
        <Select.Option value={CONSTANCE.ACCOUNT.OFFICER_PROVINCE} key={CONSTANCE.ACCOUNT.OFFICER_PROVINCE}>
          Cấp tỉnh/ thành phố
        </Select.Option>
        <Select.Option value={CONSTANCE.ACCOUNT.OFFICER_DISTRICT} key={CONSTANCE.ACCOUNT.OFFICER_PROVINCE}>
          Cấp quận/ huyện
        </Select.Option>
        <Select.Option value={CONSTANCE.ACCOUNT.OFFICER_WARD} key={CONSTANCE.ACCOUNT.OFFICER_WARD}>
          cấp phường/ xã
        </Select.Option>
      </Select>
    </Form.Item>
  )
}

export const RenderTypeAccount = (role: number) => {
  if (role === CONSTANCE.ACCOUNT.ADMIN) return <Tag color={'blue'}>Admin</Tag>
  if (role === CONSTANCE.ACCOUNT.OFFICER_PROVINCE) return <Tag color={'green'}> Cấp tỉnh/ thành phố</Tag>
  if (role === CONSTANCE.ACCOUNT.OFFICER_DISTRICT) return <Tag color={'orange'}> Cấp quận/ huyện</Tag>
  if (role === CONSTANCE.ACCOUNT.OFFICER_WARD) return <Tag color={'purple'}> Cấp phường/ xã</Tag>
  if (role === CONSTANCE.ACCOUNT.CUSTOMER) return <Tag color={'pink'}>Khách hàng</Tag>
}

export default SelectTypeAccount
