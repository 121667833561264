import { makeAutoObservable } from 'mobx'
import { getCountMenu } from './AppAPI'

class AppStore {
  countPost: number = 0
  countRequestSupport: number = 0

  constructor() {
    makeAutoObservable(this)
  }

  async getCount() {
    try {
      const resPost = await getCountMenu(1)
      const resRequest = await getCountMenu(2)

      if (resPost.body.status) {
        this.countPost = resPost.body.data
      }
      if (resRequest.body.status) {
        this.countRequestSupport = resRequest.body.data
      }
    } catch (e) {
      console.error(e)
    }
  }
}

const store = new AppStore()

export default store
