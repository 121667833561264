import React, { useEffect, useRef } from 'react'
import { Button, DatePicker, Image, Input, Table, Tag } from 'antd'
import RangePickerHoc from '../../../commons/HOC/RangePickerHOC'
import ICON from '../../../commons/icon/Icon'
import TableHoc from '../../../commons/HOC/TableHOC'
import { IColumn } from '../../../services/Interfaces'
import {
  RenderStatusApproveRequestSupport,
  RenderStatusRequestSupport,
  SelectStatusApproveRequestSupport,
  SelectStatusRequestSupport,
} from '../../select/SelectStatusRequestSupport'
import { Moment } from '../../../services/Moment'
import { observer } from 'mobx-react'
import store from './ListDonatorStore'
import FUNCTION from '../../../services/Functions'
import CONSTANCE from '../../../services/Constance'
import AddRequestSupport from '../../request-support/component/AddRequestSupport'
import PostDetailStore from './PostDetailStore'

const columns: IColumn[] = [
  {
    title: 'STT',
    key: 'STT',
    dataIndex: 'STT',
    width: 80,
    align: 'center',
    render: (STT: number, row, index) => <div>{STT}</div>,
  },
  {
    title: 'Họ tên',
    key: 'name',
    dataIndex: 'name',
    render: (name: string, row, index) => <div>{name}</div>,
  },
  {
    title: 'Số điện thoại',
    key: 'phone',
    dataIndex: 'phone',
    render: (phone: string, row, index) => <div>{phone}</div>,
  },
  {
    title: 'Hình ảnh',
    key: 'DonateImages',
    dataIndex: 'DonateImages',
    render: (DonateImages, row, index) => (
      <div>{DonateImages.length > 0 && <Image src={DonateImages[0].url} height={50} />}</div>
    ),
  },
  {
    title: 'Hình thức ủng hộ',
    key: 'form_support',
    dataIndex: 'form_support',
    render: (form_support: { name: string }[], row, index) => (
      <div>{form_support && form_support.map((value) => <Tag color={'green'}>{value.name}</Tag>)}</div>
    ),
  },
  // {
  //   title: 'TT thực hiện',
  //   key: 'implement_status',
  //   dataIndex: 'implement_status',
  //   render: (implement_status: number, row, index) => <div>{RenderStatusRequestSupport(implement_status)}</div>
  // },
  {
    title: 'TT phê duyệt',
    key: 'status',
    dataIndex: 'status',
    render: (status: number, row, index) => <div>{RenderStatusApproveRequestSupport(status)}</div>,
  },
  {
    title: 'Ngày thay đổi cuối',
    key: 'update_at',
    dataIndex: 'update_at',
    render: (update_at: string, row, index) => <div>{Moment.getDate(update_at)}</div>,
  },
  {
    title: 'Ngày tạo',
    key: 'create_at',
    dataIndex: 'create_at',
    render: (create_at: string, row, index) => <div>{Moment.getDate(create_at)}</div>,
  },
]
const ListDonator = observer(() => {
  const addRequestSupportRef = useRef<Function>()

  useEffect(() => {
    const idTimeout = setTimeout(() => {
      store.getListDonator(FUNCTION.GetId())
    }, 500)
    return () => clearTimeout(idTimeout)
  }, [
    store.payload.page,
    store.payload.status,
    store.payload.endDate,
    store.payload.startDate,
    store.payload.search,
    store.payload.implement_status,
  ])

  return (
    <div className={'container'}>
      <div className={'lg:columns-5 md:columns-2 md:row-span-2 gap-x-2 gap-y-2 mb-5'}>
        <Input
          placeholder={'Nhập tên , số điện thoại người ủng hộ'}
          onChange={(event) => {
            store.payload.search = event.target.value
            store.payload.page = CONSTANCE.DEFAULT_PAGE
          }}
        />

        {/* <SelectStatusRequestSupport
          placeholder={'Trạng thái thực hiện'}
          onChange={(values) => {
            store.payload.implement_status = values as number
            store.payload.page = CONSTANCE.DEFAULT_PAGE
          }}
        /> */}
        <SelectStatusApproveRequestSupport
          placeholder={'Trạng thái phê duyệt'}
          onChange={(values) => {
            store.payload.status = values as number
            store.payload.page = CONSTANCE.DEFAULT_PAGE
          }}
        />
        <RangePickerHoc
          onChange={(stringDate) => {
            store.payload.startDate = stringDate[0]
            store.payload.endDate = stringDate[1]
            store.payload.page = CONSTANCE.DEFAULT_PAGE
          }}
        >
          <DatePicker.RangePicker style={{ width: '100%' }} />
        </RangePickerHoc>
        <div className={'flex justify-end'}>
          <Button
            onClick={(event) => {
              addRequestSupportRef.current &&
                addRequestSupportRef.current(PostDetailStore.detailPost?.id, PostDetailStore.detailPost?.name)
            }}
            disabled={PostDetailStore.detailPost?.status === CONSTANCE.POST.POST_STATUS.DONE ? false : true}
            type={'primary'}
            icon={<span className={'mr-2'}>{ICON.BUTTON.ADD}</span>}
          >
            Thêm yêu cầu ủng hộ
          </Button>
        </div>
      </div>

      <TableHoc>
        <Table
          columns={columns}
          loading={store.loading}
          dataSource={store.listDonator.map((value, index) => {
            return { ...value, STT: FUNCTION.GetIndexTable(store.payload.page, index) }
          })}
          pagination={{
            onChange: (page) => (store.payload.page = page),
            total: store.total,
            current: store.payload.page,
          }}
        />
      </TableHoc>

      <AddRequestSupport openModal={(fn) => (addRequestSupportRef.current = fn)} />
    </div>
  )
})

export default ListDonator
//add
