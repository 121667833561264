import { makeAutoObservable } from 'mobx'
import { Baservices, IApiResponse } from '../../../services/Basevices'
import { IResBody, IResPaging } from '../../../services/Interfaces'
import CONSTANCE from '../../../services/Constance'
import FUNCTION from '../../../services/Functions'

class ListPostStore {
  loading: boolean = false
  listPost: IResDataPostCustomer[] = []
  total: number = 0

  payload: IPayload = {
    page: CONSTANCE.DEFAULT_PAGE,
    limit: CONSTANCE.LIMIT,
    customer_id: FUNCTION.GetId(),
  }

  constructor() {
    makeAutoObservable(this)
  }
  async setParams() {
    this.payload = {
      page: CONSTANCE.DEFAULT_PAGE,
      limit: CONSTANCE.LIMIT,
      customer_id: FUNCTION.GetId(),
    }
  }

  async getList() {
    console.log('payload', this.payload)
    try {
      this.loading = true
      const response = await getListPostAPI(this.payload)

      if (response.body.status) {
        this.listPost = response.body.data
        this.total = response.body.paging.totalItemCount
      }
    } catch (e) {
      console.error(e)
    } finally {
      this.loading = false
    }
  }
}

const store = new ListPostStore()

export default store

// CALL API

interface IPayload {
  page: number
  limit: number
  status?: number
  startDate?: string
  endDate?: string
  district_post?: number
  is_active?: number
  customer_id?: number
}

interface IResDataPostCustomer {
  id: number
  title: string
  content: string
  name: string
  gender: number
  address: string
  year_of_birth: string
  address_google: string
  phone: string
  district_post: number
  is_update: number
  group_id: number
  is_active: number
  is_status: number
  status: number
  create_at: string
  countDonate: number
  countPending: number
}

interface IResPostCustomer extends IResBody {
  data: IResDataPostCustomer[]
  paging: IResPaging
}

const getListPostAPI = (payload: IPayload): Promise<IApiResponse<IResPostCustomer>> => {
  return Baservices.getMethod(`/web/users/listPostById`, payload)
}
