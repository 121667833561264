import { makeAutoObservable } from 'mobx'
import { IResDataRequestSupport } from '../RequestSupportInterfaces'
import { getDetailRequestSupportAPI, putSendRequestEditAPI } from '../RequestSupportAPI'
import FUNCTION from '../../../services/Functions'
import { Notification } from '../../../commons/notification/Notification'

class DetailSupportStore {
  loading: boolean = false
  detailRequestSupport: IResDataRequestSupport | undefined = undefined

  constructor() {
    makeAutoObservable(this)
  }

  async getDetailPostRequest() {
    try {
      this.loading = true
      const res = await getDetailRequestSupportAPI(FUNCTION.GetId())
      if (res.body.status) {
        this.detailRequestSupport = res.body.data
      }
    } catch (e) {
      console.error(e)
    } finally {
      this.loading = false
    }
  }

  async sendRequestEdit(id: number) {
    try {
      const res = await putSendRequestEditAPI(id)
      if (res.body.status) {
        Notification.PushNotification('SUCCESS', 'Gủi yêu cầu chỉnh sửa thành công')
        this.getDetailPostRequest()
      }
    } catch (e) {
      console.error(e)
    }
  }
}

const store = new DetailSupportStore()

export default store
