import { Baservices, IApiResponse } from '../../../services/Basevices'
import { IResAddress, IResBank } from './SelectAddressInterfaces'

export const getListProvincesAPI = (): Promise<IApiResponse<IResAddress>> => {
  return Baservices.getMethod(`/default/list-province`)
}

export const getListDistrictAPI = (provinceId: number): Promise<IApiResponse<IResAddress>> => {
  return Baservices.getMethod(`/default/list-district?province_id=${provinceId}`)
}

export const getListWardAPI = (districtId: number): Promise<IApiResponse<IResAddress>> => {
  return Baservices.getMethod(`/default/list-ward?district_id=${districtId}`)
}

export const getListBankAPI = (): Promise<IApiResponse<IResBank>> => {
  return Baservices.getMethod(`/default/list-bank`)
}
